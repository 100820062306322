import { SvgIcon } from '@material-ui/core';
import React from 'react';

export default function FileIcon(props) {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 22 22"
      classes={{
        root: 'small',
      }}
    >
      <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M13.5498 11H18.6498"
          stroke="#0D0D0D"
          stroke-width="1.7"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M13.5498 5.90234H18.6498"
          stroke="#0D0D0D"
          stroke-width="1.7"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M3.34985 11.8508L6.3699 5.28535C6.40524 5.21485 6.45949 5.15557 6.52659 5.11415C6.59369 5.07272 6.67099 5.05078 6.74985 5.05078C6.82871 5.05078 6.90602 5.07272 6.97312 5.11415C7.04022 5.15557 7.09447 5.21485 7.1298 5.28535L10.1499 11.8508"
          stroke="#0D0D0D"
          stroke-width="1.7"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M3.34985 16.1016H18.6499"
          stroke="#0D0D0D"
          stroke-width="1.7"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M4.19995 10.1523H9.29995"
          stroke="#0D0D0D"
          stroke-width="1.7"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </SvgIcon>
  );
}
