import firebase from "fitbud/firebase";
import _ from "lodash";

export const updateGroupToUserProfiles = (userList, groupId, mute = false) => {
  const batch = firebase.firestore().batch();
  userList.forEach((doc) => {
   const docRef = firebase.firestore().doc(`user_profiles/${doc._id}/misc/group_part_of`);
   batch.set(docRef, { [groupId] : {
    read: true,
    write: true,
    from: firebase.firestore.FieldValue.serverTimestamp(),
    till: null,
    mute,
   } 
  }, { merge: true });
  });
  return batch.commit();
};

export const removeUserFromGroup = (uid, groupId, userDoc) => {
  const chng = {
    read: false, write: false,
    till: firebase.firestore.FieldValue.serverTimestamp(),
  };
  return firebase.firestore().doc(`user_profiles/${uid}/misc/group_part_of`).set({ [groupId]: chng  }, { merge: true });
};

export const updateGroupClients = (groupId, uids) => {
  return firebase.firestore().doc(`groupChats/${groupId}`)
    .update({ 
      total_clients: uids.length, 
      uids, 
      _uat: firebase.firestore.FieldValue.serverTimestamp()
    });
};

const defaultGroups = [
  { id: "active", name: "Active Clients", criteria: { multi_list_filters : ["active"] }},
];

export const DEFAULT_GC_GROUPS = (cid) => defaultGroups.map(({id, name, criteria }) => {
  const data = {
    cid,
    ref_name: name, 
    name: name,
    _cat: firebase.firestore.FieldValue.serverTimestamp(),
    type: "auto",
    last_message_timestamp: new firebase.firestore.Timestamp(2, 0),
    deleted: true,
    archive: false,
  };
  if(!!criteria) data.criteria = criteria;
  return { _id: `${cid}:${id}`, data: { ...data }};
});
