import { CircularProgress, Divider, LinearProgress, Typography, Avatar, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import NoHistory from "fitbud/images/no_history.svg";
import { CalendarColors } from "fitbud/utils/constants";
import { findBookingStatus, STATUS_CANCEL, structureAppointment } from "fitbud/utils/scheduling";
import linkifyHtml from "linkifyjs/html";
import _, { debounce, update } from "lodash";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import peopleSvg from 'fitbud/images/group_appointment.svg';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#242424',
    textAlign: 'center',
    position: 'relative',
    width: "60px !important",
    height: "60px !important",
    borderRadius: 40
  },
  stepDivider: {
    border: `2px solid #808080`,
    opacity: 0.2,
    flex: 1,
    marginBottom: "-35px"
  },
  marginBottom: {
    '& p': {
      margin: "0px !important",
    },
    fontSize: '13px',
    fontWeight: '500',
    
  },
  status_container: {
    borderRadius: 3,
    color: "white",
    padding: "5px 10px"
  },
}));

const NotFound = () => {
  return (
    <div className="d-flex justify-content-center align-items-center h-100 flex-column">
      <img src={NoHistory} alt="No history" />
      <Typography variant="h5" className="fmt-30">
        Call History not found
      </Typography>
    </div>
  )
}

const calculateCallDuration = (step) => {
  const _trainerStartCallTs = _.get(step, "trainerTs");
  const _clientStartCallTs = _.get(step, "clientTs");
  const _trainerEndCallTs = _.get(step, "trainerCallEndTs");
  const _clientEndCallTs = _.get(step, "clientCallEndTs");
  
  if(!_trainerStartCallTs || !_clientStartCallTs || !_trainerEndCallTs || !_clientEndCallTs) return step.duration;

  const _maxStartTs = _.max([moment(_trainerStartCallTs.toDate()).unix(),moment(_clientStartCallTs.toDate()).unix()]);
  const _minEndTs = _.min([moment(_trainerEndCallTs.toDate()).unix(), moment(_clientEndCallTs.toDate()).unix()]);
  return Math.round((_minEndTs - _maxStartTs)/60)
}

export const getStatusAndColor = (status, isClass) =>{
  let status_text = status;
  let status_color = CalendarColors[status];
  if(isClass){
    if(status === "completed"){
      status_text ="Attended";
    }
  }
  return {status_text,  status_color}
};

const CallHistory = (props) => {
  const { sessions, isNoteLoading, loadMore } = props;
  const classes = useStyles();
  const [isLoading, updateLoader] = useState(false);

  let scrollRef = useRef();
  const handleScroll = debounce(() => {
    if (isLoading) return;
    if(!scrollRef || !scrollRef.current) return;
    let height = scrollRef.current.scrollHeight - scrollRef.current.clientHeight;
    const scrollTop = scrollRef.current.scrollTop;
    if (height <= scrollTop) {
      loadMore(true, sessions[sessions.length-1], updateLoader);
    } else console.log(height, scrollTop);
  }, 200);

  if(isNoteLoading) {
    return (
      <div className={`d-flex h-100 position-relative align-items-center justify-content-center`}>
        <CircularProgress />
      </div>
    )
  };

  return (
    <div 
      ref={scrollRef} onScroll={handleScroll} 
      className='fpt-20 fpx-20 overflow-auto position-relative h-100 bg-light-grey d-flex flex-column align-items-center'>
      {!!sessions && sessions.length > 0 ? (
        sessions.map((item, index) => {
          const step = item.data();
          console.log(">>>step",step)
          const callDuration = calculateCallDuration(step);
          const status = step.status === STATUS_CANCEL ? step.status :findBookingStatus(structureAppointment(step, index, {withTimestamp: true}))
          const slot = `${moment(step.time.toDate()).format('hh:mm a')} to ${moment(step.time.toDate()).add(step.duration , 'minutes').format('hh:mm a')}`
          const date = moment(step.time.toDate()).format("DD");
          const month = moment(step.time.toDate()).format("MMM");
          const isClass = !_.isEmpty(step?.class);
          const { status_text, status_color} = getStatusAndColor(status, isClass)
          return (
            <div 
              className="bg-white border fp-20 fmb-20 w-100"
              key={item.id + index}
              id={`notes_doc_${index}`}
              style={{ borderRadius: '5px' }}>
              <div className={`d-flex text-break`} >
                <div className="flex-shrink-0">
                  <Typography className="font_15_600">
                    {date} {month}
                  </Typography>
                  <Typography className="font_13_500 fmt-8">
                    {callDuration} mins
                  </Typography>
                </div>
                <div style={{width: '4px', backgroundColor: status_color , borderRadius: '12px', }} className='fml-15 flex-shrink-0 ' />
                <div className="fml-15 flex-grow-1 overflow-hidden">
                  <div className="d-flex">
                    <Typography className="font_15_600 flex-grow-1 text-truncate">
                      {slot}
                    </Typography>
                    <Typography className="font_13_600 text-capitalize" style={{color: status_color}}>
                      {status_text}
                    </Typography>
                  </div>
                  <div className="d-flex mt-8">
                    <Tooltip title={step?.title}>
                    <Typography className="font_13_500 text-dark-grey flex-grow-1 text-truncate cursor-pointer mr-8">{isClass ? <span>{step?.title}</span>:<span><span>{step?.title}</span></span>}</Typography>
                    </Tooltip>
                    {isClass && <img src={peopleSvg} />}
                  </div>
                </div>
              </div>
              {step.note && <Divider className="fmt-20" />}
              {step.note && <div
                dangerouslySetInnerHTML={{ __html: linkifyHtml(step.note) }} 
                className={classes.marginBottom + ' text-break'}
              />}
            </div>
          )
        })) :(
          <NotFound />
        )}
      {!!isLoading && <CircularProgress />}
    </div>
  )
}

export default CallHistory