import { useState, useEffect, useMemo, useCallback } from 'react';

const num = (x)  => {
  if (!x && x !== 0) return null;
  let out = Number(x);
  if (isNaN(out)) return null;
  return out;
};

// Types:
// <text|email|password|...> [*] [min:a] [max:b]
// number [*] [.xxx] [min:a] [max:b]
// percent [*]
const parseType = x => {
  let out = { type: 'text', autoComplete: 'off' };
  let isNum = false;
  if (!x) return out;
  const parts = x.split(' ');
  switch (parts[0]) {
    case 'number':
      out = { type: 'number', step: 1 };
      isNum = true;
      break;
    case 'email':
      out = { type: 'email', autoComplete: 'email' };
      break;
    case 'name':
      out.autoComplete = 'name';
      break;
    case 'percent':
      out = { type: 'number', step: 1, min: 0, max: 100 };
      break;
    default:
      break;
  }
  for (let i = 1; i < parts.length; i++) {
    if (parts[i] === '*')
      out.required = true;
    else if (parts[i].endsWith(':min'))
      out[isNum ? 'min' : 'minLength'] = num(parts[i].replace(':min', ''));
    else if (parts[i].endsWith(':max'))
      out[isNum ? 'max' : 'maxLength'] = num(parts[i].replace(':max', ''));
    else if (isNum && parts[i] === 'int')
      out.decimalLimit = 0;
    else if (isNum && parts[i].startsWith('.')) {
      out.step = num(parts[i]);
      out.decimalLimit = parts[i].length - 1;
    }
  }
  return out;
}

const defaultParser = (value, { type } = {}) => {
  if (type === 'number') return num(value);
  return value || null;
};

const defaultValidator = (value, { type, required, min, max } = {}) => {
  if (required) {
    if (type === 'number') {
      if (!Number.isFinite(value)) return 'Required';
    } else if (!value) return 'Required';
  }
  if (!value && value !== 0) return '';
  if (type === 'number' && Number.isFinite(min) && value < min)
    return value === 0 ? 'Required' : `Must be more than ${min}`;
  if (type === 'number' && Number.isFinite(max) && value > max)
    return `Must be less than ${max}`;
  return ''; // FIXME implement based on type and other inputProps
};

const _fnval = (x) => (typeof x === 'function' ? x() : x);
const useInput = (initialValue, typeStr = undefined, {
  parser,     // custom parsing logic. Takes the typed value as input and outputs a parsed value for the purpose of DB persistance
  validator,  // custom validation logic. Takes `parsed` value as input and must output an error message string on error or '' on success
  autoValidate = false,
  displayParsed = false,
  autoTrim = false,
  lowerize = false,
  onChangeCb
} = {}) => {

  const inputProps = useMemo(() => parseType(typeStr), [typeStr]);

  // the internal value parser
  const _parser = useCallback((typedValue) => {
    if (parser) return parser(typedValue);
    return defaultParser(typedValue, inputProps);
  }, [parser, inputProps]);

  // the internal parsed value validator
  const _validator = useCallback((parsedValue) => {
    if (validator) return validator(parsedValue);
    return defaultValidator(parsedValue, inputProps);
  }, [validator, inputProps]);

  // internal states => value is for displaying purpose; parsed is for DB saving purpose; error is for the error helperText
  const [value,  setValue]  = useState(_fnval(initialValue) || '');
  const [parsed, setParsed] = useState(_parser(_fnval(initialValue)));
  const [error,  setError]  = useState('');

  // exposed onChange handler
  const onChange = useCallback((e) => {
    let x = (e && e.target && e.target.value) || '';
    if (autoTrim) x = x.trim();
    if (lowerize) x = x.toLowerCase();
    if (x && inputProps.type === 'number' && inputProps.decimalLimit !== undefined) { //incase of zero  check was failing so added undefined check
      const parts = x.split('.');
      if (parts.length > 1 && parts[1].length > inputProps.decimalLimit) return;
    }
    setValue(x);
    if (autoValidate) {
      const parsedVal = _parser(x);
      const e = _validator(parsedVal);
      setParsed(e ? _parser('') : parsedVal);
      setError(e);
    } else {
      setParsed(_parser(x));
      setError('');
    }
    onChangeCb && onChangeCb(); // in case of we need side effects run on onChange.
  }, [_parser, _validator, autoValidate, lowerize, autoTrim, inputProps]);
  const reset = useCallback(() => {
    setValue(_fnval(initialValue) || '');
    setParsed(_parser(_fnval(initialValue)));
    setError('');
  }, [initialValue, _parser]);
  const set = useCallback(value => {
    onChange({target: {value}});
  }, [onChange]);

  useEffect(() => {
    if (!displayParsed) return;
    setValue(parsed);
  }, [parsed, displayParsed]);

  // exposed validator
  const isValid = useCallback((x) => {
    const e = _validator(x || parsed);
    setError(e);
    return !e;
  }, [_validator, parsed]);

  return {
    value, parsed, error,
    onChange, isValid,
    reset, set, setError,
    inputProps, props: {
      value, error: !!error, helperText: error,
      onChange, inputProps},
  };
}

const useValidators = (...hookValidators) => {
  const isValid = useCallback(() => {
    let out = true;
    hookValidators.forEach(fn => {
      if (!fn) return;
      out = fn() && out;
    });
    return out;
  }, hookValidators); // eslint-disable-line react-hooks/exhaustive-deps
  return isValid;
};

const useToggle = (initialValue, changeCb) => {
  const [value, setValue] = useState(initialValue || false);
  const toggle = useCallback(() => {
    setValue(x => {
      if (changeCb) changeCb(!x);
      return !x;
    });
  }, [changeCb]);
  const setter = useCallback((e) => {
    if (e && e.target)
      setValue(!!e.target.value);
    else
      setValue(e)
  }, []);
  return [value, toggle, setter];
}

const usePicker = (initialValue, changeCb, allowNone = false) => {
  const [value, setValue] = useState( typeof  initialValue === "function" ? initialValue() :  initialValue  || '');
  const setter = useCallback((e, initialSetter = false) => {
    let out = null;
    if (e && e.target && (allowNone || e.target.value !== "none"))
      out = e.target.value;
    else if (typeof e === 'string' || typeof e === 'number')
      out = e;
    setValue(out);
    //if not initialSetter then fireChange Callback. initialSetter : true, pass this value for setup state in effect for first time   .changeCb basically used to run effect for mark dirty in state.
    if (initialSetter !== true)
      changeCb && changeCb(out);
  }, [changeCb]);
  return [value, setter];
}

const useEventData = (initialValue, key) => {
  const [data, setData] = useState(initialValue || (key ? '' : {}));
  const readClick = useCallback((e) => {
    if (!e || !e.currentTarget) return setData(initialValue || {});
    const data = e.currentTarget.dataset;
    if (key) setData(data[key]);
    else setData({...data});
  }, [initialValue, key]);
  return [data, readClick, setData];
};

const useAnchor = () => {
  const [el, setEl] = useState(null);
  const onClick = (e) => setEl(e.currentTarget);
  const onClose = () => setEl(null);
  return [el, onClick, onClose, setEl];
};

export {
  defaultParser,
  defaultValidator,
  useInput,
  useValidators,
  useToggle,
  usePicker,
  useEventData,
  useAnchor,
};
