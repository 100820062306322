import React, { useState, useRef, useEffect, useContext, useMemo } from "react";
import clsx from "clsx";
import _ from "lodash";
import moment from "moment-timezone";
import { Hidden, Typography, makeStyles, IconButton, Avatar, Chip } from "@material-ui/core";
import { oprtnlDateFormat } from "fitbud/utils/constants";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import { getUiAvatar, getEmailFirstLetter } from "fitbud/utils/helpers";
import { EmailIcon } from "fitbud/icons/emailIcon";
import { TagsList } from "fitbud/components/tags";
import usrRepo from "fitbud/repo/users";
import Tooltip from '@material-ui/core/Tooltip';
import PersonIcon from '@material-ui/icons/Person';
import {DEFAULT_ERROR } from "fitbud/utils/constants";
import { getUserHeaderDetails, getUserPaymentMode } from "./helpers";
import firebase from "fitbud/firebase";
import { UserStateIndicator } from "./list";
import { Conversion } from "fitbud/providers/conversion";
import useTrainers from "fitbud/hooks/useTrainers";
import { ACL_FEATURES_KEY, useAcl } from "fitbud/providers/acl-provider";

const DEFAULT_UNIT_SYSTEM = "metric";
const storageRef = firebase.storage().ref();

const styles = makeStyles({
  fmb_4: {
    marginBottom: "4px"
  },
  pending: {
    backgroundColor: "#e02f2f"
  },
  status_container: {
    borderRadius: 3,
    color: "white",
    paddingLeft: 6,
    paddingRight: 6,
    lineHeight: '22px'
  },
  payment_mode: {
    padding: "3px 6px"
  },
  fp_75: {
    padding: 6,
    margin: '-3px 0',
    "&:hover": {
      color: "#757575"
    }
  },
  buttonText: {
    padding: "7.5px 8px"
  },
  l2: {
    color: "#37404D",
    marginTop: 8,
    marginBottom: 10
  },
  avatarBadge:{
    right: '15px',
    top:'60px',
    boxShadow:'0px 0px 0px 2px #fff',
    height: '10px !important',
    borderRadius: '100%',
    width:'10px !important',
  },
});
export const HeaderRight = ({
  userDoc,
  planDoc,
  status,
  loading,
}) => {
  const classes = styles();
  const { L1, L2 } = getUserHeaderDetails(userDoc, planDoc) || {}
  const payment_mode = getUserPaymentMode(userDoc);
  return (
    <div className={clsx("d-flex flex-column align-items-end flex-shrink-0", loading && "vis-collapse")}>
      <Typography variant="h2" className="text-black w-100" align="right" noWrap>
        {L1}
      </Typography>
      <Typography variant='body2' className={clsx(classes.l2)}>
        {L2}
      </Typography>
      <StatusCard status={status} payment_mode={payment_mode}/>
    </div>)
};

const StatusCard = ({ status, payment_mode }) => {
  if (!status) return null;
  let { state, text = '', color } = status
  const classes = styles();
  const bgColor = (color) => {
    switch (color) {
      case "green":
        return "bg-success";
      case "red":
        return "bg-danger";
      case "yellow":
        return "bg-warning";
      case "neutral":
      default:
        return "bg-neutral";
    }
  };
  return (
    <div className="d-flex flex-row align-items-center">
      <span className="font_13_500 fmr-15 text-muted">{text} </span>
      {/* {payment_mode && <Chip style={{ backgroundColor: "#F2F4F7" }}
        classes={{ root: clsx("rounded-pill fmr-15", classes.payment_mode), labelSmall: "font_13_500 text-dark-grey" }}
        size="small"
        label={payment_mode} />
      } */}
      <span
        className={clsx("text-capitalize text-center font_11_600", bgColor(color), classes.status_container)}
      >
        {state}
      </span>
    </div>
  );
};

export const AvatarImage = ({
  src,
  base64 = false,
  size = null,
  className,
  name,
  isProgress,
  styles = {},
  externalUrl=false,
  ...rest
}) => {
  const errorCount = useRef(0);
  const [image_src, updateImageSrc] = useState(src);

  useEffect(() => {
    errorCount.current = 0;
    if(!base64 && src && !externalUrl){
      storageRef
        .child(src.replace("original", "240"))
        .getDownloadURL().then((url) => {
          updateImageSrc(url);
        }).catch((err) => {
          console.log(err)
        })
    } else {
      updateImageSrc(src);
    }
  }, [base64, src, externalUrl]);

  const [err, updateErr] = useState(false);
  const handleSrcError = () => {
    if (base64) {
      updateErr(true);
    } else {
      if (!errorCount.current) {
        updateImageSrc(src => src.replace("240", "original"));
        errorCount.current += 1;
      } else {
        updateErr(true);
      }
    }
  }
  const commonStyles={
    border:"1px solid #D3D9DB",
    background:'#F2F4F7',
    color:"#65768C",
    ...styles
  }
  if (!!image_src && !err) {
    return (
      <Avatar
        {...rest}
        onError={handleSrcError}
        src={base64 ? `data:image/jpeg;base64, ${image_src}` : image_src}
        className={clsx(
          'avatar-image',
          className,
          rest.onclick && "cursor-pointer",
        )}
        classes={{
          root: size
        }}
        onClick={onclick}
        style={{...commonStyles}}
      />
    );
  } else
    return (
      <Avatar
        {...rest}
        className={clsx(
          'avatar-image',
          className,
          rest.onclick && "cursor-pointer",
          "text-uppercase"
        )}
        classes={{
          root: size
        }}
        style={{
          ...commonStyles
        }}
      >
        {getUiAvatar(name)}
      </Avatar>
    );
};
export const HeaderLeft = ({
  profile = {},
  unitSystem,
  unitDetails,
  measurableTags,
  measurableUnits,
  loading = false,
  isProgress,
  id,
  cid,
  tags,
  setTags,
  module,
  showTags = false,
  userDoc,
}) => {
  const { convertor } = useContext(Conversion);
  const {getTrainer} = useTrainers();
  const {status, checkAccessFeature} = useAcl();
  let name = profile.name;
  let email = profile.email;
  let image_data = profile.image_data;
  let image = profile.image;
  let age =
    profile && profile.dob
      ? moment()
        .startOf("day")
        .diff(moment(profile.dob, oprtnlDateFormat), "year")
      : undefined;
  let [heightValue, hu] = convertor.getDisplayWithUnit('height', !!profile && profile.height, { fancy: true });
  let weightValue = convertor.getDisplayValue('weight', !!profile && profile.weight);
  let wu = convertor.getDisplayUnit('weight');
  const classes = styles();
  const trainer_display_name = useMemo(()=>{
    const isTrainerEnabled = checkAccessFeature(status, ACL_FEATURES_KEY.TRAINERS);
    if(!isTrainerEnabled) return "";
    const {trainer_name, trainer_id} = userDoc || {};
    if(!!trainer_name) return trainer_name;
    if(!trainer_id) return "";
    const t = getTrainer(trainer_id);
    return t?.data?.name || ""
  },[userDoc, getTrainer, status, checkAccessFeature])
  
  return (
    <div
      className={clsx("d-flex flex-grow-1", loading && "vis-collapse")}
    >
      <Hidden smDown>
        <UserStateIndicator userDoc={userDoc} classes={{badge: classes.avatarBadge}}>
          <AvatarImage
            src={image ? `user_profiles/${id}/profile/original/${image}` : image_data}
            name={name || getEmailFirstLetter(email)}
            base64={!image}
            alt={name || " "}
            size={module === 'user' ? 'vlarge' : 'midlarge'}
            isProgress={isProgress}
            styles={{ height: 80, width: 80, fontSize: 20, fontWeight: 500 }}/>
        </UserStateIndicator>
      </Hidden>
      <div className="d-flex flex-column w-100 pr-5 ml-md-20" style={{ maxWidth: 370 }}>
        <div className={clsx("d-flex align-items-start")}>
          <Typography variant="h2" className="text-truncate text-black fmr-10 fmb-8">
            {name || getEmailFirstLetter(email)}
          </Typography>
          <Tooltip title={email || ''} aria-label="email">
            <IconButton className="fmx-5" component='a' target='_blank' href={`mailto:${email}`} classes={{ root: classes.fp_75}}>
             <EmailIcon classes={{root: 'small'}}/>
            </IconButton>
          </Tooltip>
        </div> 
        {profile && Object.keys(profile).length > 0 && (
          <div className={clsx("d-flex align-items-center")}>
            <Typography
              variant="subtitle2"
              className={"fmr-20 fmb-10 text-dark-grey"}
            >
              <span> {age || '- yrs'}</span>
              {age && profile.gender && (
                <span className="font_14_400">
                  {' '} {profile.gender.slice(0, 1)}
                </span>
              )}
            </Typography>
            <Typography
              variant="body2"
              className={clsx(
                "font_14_600 fmr-20 fmb-10 text-dark-grey"
              )}
            >
              {heightValue || '-' } <span className="font_14_400">{hu}</span>
            </Typography>
            <Typography
              variant="body2"
              className="font_14_600 fmr-20 fmb-10 text-dark-grey"
            >
              {weightValue || '-'} <span className="font_14_400">{wu}</span>
            </Typography>
          </div>
        )}
        {showTags && !loading && <TagsList cid={cid} tags={tags} setTags={setTags} editable className={'m-1'}>
          {trainer_display_name && <Chip style={{backgroundColor: "#70829B"}}
            icon={<PersonIcon style={{ color: "white"}}/>}
            classes={{root: "rounded-pill fp-5 mr-2", labelSmall: "font_12_600 text-white"}}
            size="small"
            label={trainer_display_name}/>}
        </TagsList>}
      </div>
    </div>
  );
};

const Header = ({
  name = null,
  image = null,
  profile = {},
  status,
  startDate = null,
  endDate = null,
  duration,
  durationType,
  userId,
  staff_docs,
  measurableTags,
  measurableUnits,
  userPage,
  loading: userLoading,
  staffLoading,
  toggleEditUser,
  planExist,
  isProgress,
  deleteItem,
  onboarding_complete,
  cid,
  uid,
  currPurchase,
  subscription,
  toggleSwitchPlan,
  plan_assign,
  planDoc,
  aplan,
  setPlan,
  setUser,
  userDoc,
  showTags,
  module,
  ...restProps
}) => {
  let loading = staffLoading || userLoading;

  const setTags = (tags) => {
    usrRepo
      .update(userId, { tags })
      .then(doc => {
        setUser({ ...userDoc, tags })
      })
      .catch(err => {
        console.log(err, "Error")
        restProps.enqueueSnackbar(DEFAULT_ERROR, { variant: "error" });
        // Sentry.captureException(err);
      });
  }
  return (
    <div
      className={` d-flex justify-content-between fpx-25 fpt-25 fpb-15 bg-white`}
    >
      <HeaderLeft
        loading={loading}
        profile={profile}
        image={image}
        name={name}
        id={userId}
        userPage={userPage}
        unitSystem={_.get(staff_docs, "unit_system", DEFAULT_UNIT_SYSTEM)}
        unitDetails={_.get(staff_docs, "unit_details", {})}
        measurableTags={(measurableTags && measurableTags[0]) || {}}
        measurableUnits={(measurableUnits && measurableUnits[0]) || {}}
        endDate={endDate}
        isProgress={isProgress}
        onboarding_complete={onboarding_complete}
        currPurchase={currPurchase}
        subscription={subscription}
        cid={cid}
        uid={uid}
        tags={(userDoc && userDoc.tags) || []}
        setTags={setTags}
        module={module}
        showTags={showTags}
        userDoc={userDoc}
      />
      <Hidden smDown>
        <HeaderRight 
          toggleSwitchPlan={toggleSwitchPlan}
          planDoc={planDoc}
          status={status}
          userDoc={userDoc}
          module={module}
          loading={loading}/>
      </Hidden>
    </div>
  );
};

const mapStateToProps = (s) => {
  return {
    staff_docs: s.staff.doc,
    measurableTags: s.tags.docs,
    measurableUnits: s.config.docs,
    staffLoading: s.staff.loading,
    companyData: s.app,
    userDoc: s.selectedSchedule.user
  };
};

export default withSnackbar(connect(mapStateToProps, null)(Header));
