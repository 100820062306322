import React from 'react';
import { withStyles } from "@material-ui/core";
import clsx from "clsx";

//----CATALOG LIST STYLE----
export const ListStyling=withStyles({
  list:{
       '& .MuiListItem-root':{
           height:80,
           padding:'15px 20px',
           '& .MuiListItemText-root':{
             flex:1
           },
           '& .MuiListItemText-primary':{
             fontSize:"15px",
             fontWeight:600,
           },
           '& .MuiListItemText-secondary':{
             fontSize:"13px",
             fontWeight:500,
             marginTop:6,
             color:'#6F8099',
             display:"flex",
             alignItems:"center",
             '& .dot':{
               margin: '0px 4px'
             }
           },
           '& .truncate':{
             overflow: 'hidden',
             textOverflow: 'ellipsis',
             whiteSpace: "nowrap"
           },
           '& .MuiListItemAvatar-root':{
              marginRight:15,
              position:"relative",
              minWidth:"unset",
              '& .MuiAvatar-root':{
                border:"1px solid #D3D9DB",
                background:'#F2F4F7',
                color:"#6F8099",
                fontWeight: 600,
                fontSize: "13px"
              },
              '& .MuiAvatar-circular':{
                height:50,
                width:50,
             },
             '& .MuiAvatar-rounded':{
               width:70,
               height:50,
               borderRadius:10
             },
             '& .MuiAvatar-rounded.square':{
               width:50,
               height:50,
               borderRadius:6
             },
             '& .icon':{
               position:"absolute",
               bottom:0,
               right:5,
               height:14,
               width:14
             }
           },
       },
      '& .Mui-selected':{
        backgroundColor:"#E0ECFE",
        '&:hover':{
          backgroundColor:"#E0ECFE!important"
        }
      },
      '& .MuiListItem-button':{
        '&:hover':{
          backgroundColor:"#E0ECFE4D!important"
        }
      },
  }
  
})(({children,className,classes})=>(
  <div className={clsx(classes.list, className )}>
    {children}
  </div>
))
