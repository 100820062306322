import React,{useState,useContext,useMemo} from 'react';
import {Menu,MenuItem,IconButton,TextField} from "@material-ui/core";
import OptionsIcon from "@material-ui/icons/MoreVert";
import Confirmation from "fitbud/components/confirmationDialog";
import { ClrdButton } from "fitbud/components/form-fields";
import { EditorContext } from "fitbud/views/workouts/exerciseEditor";
import KeyDown from "@material-ui/icons/ExpandMore";
import {get} from 'lodash';
import {GrpHeaderForm} from "./grpHeader";
import MagicIcon from 'fitbud/icons/MagicIcon';

export const RightControls=({addExercise,typeValue,handleTypeChange,totalEx,totalGrps,openReorderDialog,className,errors,...props})=>{
  const {handleDelete,handleDuplicate,handleGrpHeader,doc}=useContext(EditorContext);
  const grpIndex=useMemo(()=>parseInt(props["data-group"]),[props["data-group"]]);
  const grpHeaderInitialValues=useMemo(()=>{
    const {title="",description=""}=get(doc,['groups',grpIndex],{});
    return {title,description};
  },[get(doc,['groups',grpIndex])]);
  return (
    <>
      <IconButton variant="text">
        <MagicIcon width="24px" height="24px"/>
      </IconButton>
      <ClrdButton
        variant="text"
        color="primary"
        className="fmr-25 text-nowrap"
        onClick={addExercise}
      >
        Add Exercises
      </ClrdButton>
      <TextField
        name="type"
        select
        value={typeValue}
        variant="outlined"
        required
        SelectProps={{
          IconComponent: KeyDown,
          inputProps: {
            classes: {
              icon: "fpl-5",
            },
          },
        }}
        InputProps={{
          classes: {
            root: "small",
            input: "size_15_500 small select-small",
          },
        }}
        onChange={handleTypeChange}
        classes={{ root: className }}
        error={get(errors,`groups.${props['data-group']}.type`,false)}
      >
        <MenuItem key="regular" value="default" dense>
          Regular
        </MenuItem>
        {props["data-group"] === 0 && (
          <MenuItem key="warmup" value="warmup" dense>
            Warmup
          </MenuItem>
        )}
        <MenuItem key="superset" value="superset" dense>
          Superset
        </MenuItem>
        <MenuItem key="triset" value="triset" dense>
          Triset
        </MenuItem>
        <MenuItem key="circuit" value="circuit" dense>
          Circuit
        </MenuItem>
        {totalEx === 1 && (
          <MenuItem key="dropset" value="dropset" dense>
            Dropset
          </MenuItem>
        )}
        <MenuItem key="amrap" value="amrap" dense>
          AMRAP
        </MenuItem>
        <MenuItem key="tabata" value="tabata" dense>
          TABATA
        </MenuItem>
        <MenuItem key="emom" value="emom" dense>
          EMOM
        </MenuItem>
        {!!props["data-group"] && props["data-group"] === totalGrps - 1 && (
          <MenuItem key="cooldown" value="cooldown" dense>
            Cooldown
          </MenuItem>
        )}
      </TextField>
      <SegmentActions
        onCopy={() => {
          handleDuplicate(grpIndex);
        }}
        onReorder={openReorderDialog}
        onDelete={() => {
          handleDelete({
            grpIndex: grpIndex,
            exrcsIndex: props.index,
            type: "group",
          });
        }}
        grpIndex={grpIndex}
        onAddHeader={(values)=>handleGrpHeader(grpIndex,values)}
        title="segment"
        className="fml-15"
        grpHeaderInitialValues={{...grpHeaderInitialValues}}
      />
    </>
  );
}

export const SegmentActions=({onCopy,onReorder,onDelete,className,onAddHeader,grpHeaderInitialValues={},...props})=>{
    const [anchorEl, setAnchorEl] = useState(null);
    const [isConfrmPopOpen,toggleConfirmation]=useState(false);
    const [isHeaderFormOpen,toggleHeaderForm]=useState(false);
    const open = Boolean(anchorEl);
    function openMenu(e){
        e.stopPropagation();
        setAnchorEl(e.currentTarget);
    }
    function closeMenu(){
        setAnchorEl(null);
    }
    function handleClose(e){
        closeMenu();
        toggleConfirmation(false);
    }
    function confirmationSubmit(){
        onDelete();
        handleClose();
    }
    function closeHeaderForm(){
      toggleHeaderForm(false);
    }
    function headerFormSubmit(values){
      onAddHeader(values);
      closeHeaderForm();
    }
    function handleItemClick(key){
        switch(key){
            case "copy":{
                onCopy();
                break;
            }
            case "reorder":{
                onReorder();
                break;
            }
            case "delete":{
                toggleConfirmation(true);
                break;
            }
            case "header":{
              toggleHeaderForm("add");
              break;
            }
            default:{
            }
        }
        closeMenu()
    }
    return (
      <>
        <IconButton onClick={openMenu} className={className}>
          <OptionsIcon />
        </IconButton>
        <Menu anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
          <MenuItem onClick={() => handleItemClick("header")} dense>
            {!!grpHeaderInitialValues.title?"Edit":"Add"} Segment Header
          </MenuItem>
          <MenuItem onClick={() => handleItemClick("copy")} dense>
            Copy
          </MenuItem>
          <MenuItem onClick={() => handleItemClick("reorder")} dense>
            Reorder
          </MenuItem>
          <MenuItem onClick={() => handleItemClick("delete")} dense>
            Delete
          </MenuItem>
        </Menu>
        {!!isHeaderFormOpen && <GrpHeaderForm onClose={closeHeaderForm} handleSubmit={headerFormSubmit} action={isHeaderFormOpen} initialValues={{...grpHeaderInitialValues}}/>}
        {isConfrmPopOpen && (
          <Confirmation
            open={true}
            handleChange={confirmationSubmit}
            handleCancel={handleClose}
            title={`Delete ${props.title}`}
            msg={`Are you sure you want to delete the ${props.title}?`}
            confirmOption="Yes, Delete"
          />
        )}
      </>
    );
}

