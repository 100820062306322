import React from "react";
import _, { isEmpty, xorWith, isEqual, cloneDeep, size } from "lodash";
import {
  PROGRAMS_WORKOUT,
  MEAL_PLANS_NUTRITION,
  ALL_COLLECTION,
  RESOURCE_COLLECTION,
  NUTRITION_COLLECTION,
  GROUP_ENUM,
} from "./constants";
import { durationToHMString } from "fitbud/views/workouts/helperfn";

export const isArrayEqual = (x, y) => isEmpty(xorWith(x, y, isEqual));

export const getEnableLabel = (keyName, exploreType) => {
  const condi = exploreType === 'workout';
  const condi2 = exploreType === 'resources';
  switch (keyName) {
    case 'programs':
      return `If you enable explore in ${
        condi ? 'Schedules' : 'Meal Plans'
      }, your active clients will be able to browse and switch to these ${
        condi ? 'workout schedules' : 'plans'
      }. Do you want to proceed?`;
    case 'on_demand':
      return (
        <span>
          Enabling <b className="text-black">All {`${condi ? 'Workouts' : condi2 ? 'Resources' : 'Recipes'}`}</b> will give access to all the{' '}
          <span>{`${condi ? 'workouts' : condi2 ? 'resources' : 'recipes'}`}</span> to your active clients. Do you want
          to proceed?
        </span>
      );
    case 'meal_plans':
      return 'If you enable explore in Meal Plans, your active clients will be able to browse and switch to these plans. Do you want to proceed?';
    default:
      return `Enabling this collection will allow your active clients to browse and use the listed ${
        condi ? 'workouts' : condi2 ? 'resources' : 'recipes'
      }. Do you want to proceed?`;
  }
};

export const getDisableLabel = (keyName, exploreType) => {
  const condi = exploreType === 'workout';
  const condi2 = exploreType === 'resources';

  switch (keyName) {
    case 'programs':
      return `If you disable explore in ${
        condi ? 'Schedules' : 'Meal Plans'
      }, your clients won’t be able to browse or use these ${
        condi ? 'workout schedules' : 'plans'
      }. Do you want to proceed?`;
    case 'on_demand':
      return <span>
        If you disable <b className="text-black">All {` ${
        condi ? 'Workouts' : condi2 ? 'Resources' : 'Recipes'
      }`}</b> <span>your clients won’t be able to use them directly unless and until they are part of a collection. Do you want to proceed?</span>
      </span>
      return `If you disable All ${
        condi ? 'Workouts' : condi2 ? 'Resources' : 'Recipes'
      }, your clients won’t be able to browse or use these ${
        condi ? 'workouts' : condi2 ? 'resources' : 'recipes'
      }. Do you want to proceed?`;
    case 'meal_plans':
      return `If you disable explore in Meal Plans, your clients won’t be able to browse or use these plans. Do you want to proceed?`;
    default:
      return `If you disable this collection, your clients won’t be able to browse or use the listed  ${
        condi ? 'workouts' : condi2 ? 'resources' : 'recipes'
      }. Do you want to proceed?`;
  }
};

export const getEnable = (exploreType, exploreId) => {
  const TXT_ENABLE = getEnableLabel(exploreId, exploreType);
  return TXT_ENABLE;
};

export const getDisable = (exploreType, exploreId) => {
  const TXT_DISABLE = getDisableLabel(exploreId, exploreType);
  return TXT_DISABLE;
};

export const isCollection = (exploreId) => !['programs', 'meal_plans', 'on_demand'].includes(exploreId);

export const isEditCollectionName = (exploreId) => ['programs', 'meal_plans'].includes(exploreId); //only edit default collection name condition

export const getKeyName = (exploreType, exploreId) => {
  if (exploreType === 'workout') {
    if (exploreId === 'programs') return 'schedules';
    else return 'workouts';
  }
  if (exploreType === 'nutrition') {
    if (exploreId === 'meal_plans') return 'meals';
    else return 'foodRecipes';
  }
  if (exploreType === 'resources') return 'resources';
};
export const getHeader = (exploreType, selectedTab, selectedItemId = '') => {
  if (exploreType === 'workout') {
    if (selectedTab === 'schedules') return 'Program';
    else return selectedItemId.type === 'cardio' ? 'Cardio' : 'Workout';
  }
  if (exploreType === 'nutrition') {
    if (selectedTab === 'schedules') return 'Meal Plan';
    else return 'Recipe';
  }
  if (exploreType === 'resources') return 'Resource';
};

export const mergeAccentColor = (obj, color_theme) => {
  const accent_color = getDefinedAccentColors(color_theme);
  if (!!accent_color) return { ...cloneDeep(obj), accent_color };
  else return cloneDeep(obj);
};

export function getInitialValue(exploreType, collection, color_theme, group=false) {
  if(!!group) return mergeAccentColor(GROUP_ENUM, color_theme);
  if (exploreType === 'workout') {
    switch (collection) {
      case 'schedules':
        return mergeAccentColor(PROGRAMS_WORKOUT, color_theme);
      case 'collections':
        return mergeAccentColor(ALL_COLLECTION, color_theme);
      default:
        return mergeAccentColor(ALL_COLLECTION, color_theme);
    }
  } else if (exploreType === 'nutrition') {
    switch (collection) {
      case 'schedules':
        return mergeAccentColor(MEAL_PLANS_NUTRITION, color_theme);
      case 'collections':
        return mergeAccentColor(NUTRITION_COLLECTION, color_theme);
      default:
        return mergeAccentColor(NUTRITION_COLLECTION, color_theme);
    }
  } else if (exploreType === 'resources') {
    switch (collection) {
      case 'collections':
        return mergeAccentColor(RESOURCE_COLLECTION, color_theme);
      default:
        return mergeAccentColor(RESOURCE_COLLECTION, color_theme);
    }
  }
}

export const getWoLabel = (keyName, free = false, hidepaid = false) => {
  switch (keyName) {
    case 'programs':
      return {
        heading: 'Clients on any active plan will be able to explore & switch to these schedules.',
        subHeading: 'Manage which schedules you want to show to your active clients.',
      };
    case 'on_demand':
      return {
        heading: 'Allow your active clients to access all workouts from your workouts library.',
        subHeading: 'Your active clients will be able to use these workouts by simply searching for them.',
      };
    default:
      const prefix = free ? (hidepaid ? 'Leads' : 'All clients') : 'Clients on any active plan';
      return {
        heading: prefix + ' will be able to explore & use workouts listed here.',
        subHeading: 'Manage which workouts you want to add/remove from this collection.',
      };
  }
};

export const getNutritionLabel = (keyName, free = false, hidepaid = false) => {
  switch (keyName) {
    case 'meal_plans':
      return {
        heading: 'Clients on any active plan will be able to explore & use these meal plans.',
        subHeading: 'Manage which meal plans you want to show to your active clients.',
      };
    case 'on_demand':
      return {
        heading: 'Allow your active clients to access all recipes from your recipe library.',
        subHeading: 'Your active clients will be able to use these recipes by simply searching for them.',
      };
    default:
      const prefix = free ? (hidepaid ? 'Leads' : 'All clients') : 'Clients on any active plan';
      return {
        heading: prefix + ' will be able to explore & use recipes from this collection.',
        subHeading: 'Manage which recipes you want to add/remove from this collection.',
      };
  }
};

export const getResourcesLabel = (keyName, free = false, hidepaid = false) => {
   if(keyName !== "on_demand")return {
    heading: 'Clients on any active plan will be able to explore & use resources from this collection.',
    subHeading: 'Manage which resources you want to add/remove from this collection.',
   }
   else return {
    heading: 'Give access to all your resources listed below',
    subHeading: 'Your active clients will be able to use these resources from the client apps.',
  };
};

export const ADD_MEAL_OPTIONS = [
  { label: 'Meal Plan', key: 'meals' },
  { label: 'Meal Schedule', key: 'mlSchedules' },
];

export const getDefinedAccentColors = (color_theme) =>{
    let accent_color = _.get(color_theme, "colors.pp_home_tint",'') ;
    if(!!accent_color){
        accent_color = '#' + accent_color.replace(/^#/, '')
    }
    return accent_color;
}

export const getMeta = (item, exploreType, { targetAreas }) => {
  if (exploreType === 'workout') {
    const [ta] = item.target_area || [];
    // eslint-disable-next-line no-unused-vars
    const [_ign, { value }] = (ta && (targetAreas || []).find((_ta) => _ta[0] === ta)) || [null, {}];
    const durStr = durationToHMString(item.duration)
    return {
      title: item.title,
      subtitle: `${durationToHMString(item.duration)} ${value ? `\u2004•\u2004${value}` : ""}`,
      subtitle: (
        <span>
          <b>{durStr.split(" ")[0]}</b> <span>{durStr.split(" ")[1]} </span>
          <span>{value ? `\u2004•\u2004${value}` : ""}</span>
        </span>
      ),
      // subtitle: `${Math.round(item.duration / 60) ? Math.round(item.duration / 60) + ' mins' : ''} ${
      //   value ? `\u2004•\u2004${value}` : ''
      // }`,
    };
  }
  if (exploreType === 'nutrition') {
    return {
      title: item.title,
      subtitle : <span><b>{Math.round(_.get(item, 'macros.calories', 0)).toLocaleString()}</b> cals</span>
    };
  }
  if (exploreType === 'resources') {
    return {
      title: item.title,
      subtitle: item.dtype,
    };
  }
};

export const TEXT_BEFLOW_IMAGE = "text_below_image";
export const TEXT_OVEVER_IMAGE = "text_over_image";
export const IMAGE_SHAPE = {
  ROUNDED: "rounded",
  CIRCLE:"circle",
};

export const TEXT_TYPE_OPTIONS = [{label:"Text Below Image",value:TEXT_BEFLOW_IMAGE},{label:"Text Over Image",value:TEXT_OVEVER_IMAGE}];
export const IMAGE_SHAPE_OPTIONS = [{label:"Rounded Corner",value:IMAGE_SHAPE.ROUNDED},{label:"Circle",value:IMAGE_SHAPE.CIRCLE}];

export const SQUARE_ASPECT = 1;
export const PORTRAIT_ASPECT = 0.6;
export const PORTRAIT_RESOURCE_ASPECT = 0.667;
export const LANDSCAPE_ASPECT = 1.5;
export const CIRCLE_ASPECT = 1;

export const SQUARE_LARGE = 1.25;
export const SQUARE_MEDIDUM = 1.6;
export const SQUARE_SMALL = 2.2;

export const PORTRAIT_LARGE = 1.6;
export const PORTRAIT_MEDIUM = 1.9;
export const PORTRAIT_SMALL = 2.2;

export const LANDSCAPE_LARGE = 1.08;
export const LANDSCAPE_MEDIUM = 1.35;
export const LANDSCAPE_SMALL = 1.8;

export const CIRCLE_LAEGE = 2.2;
export const CIRCLE_MEDIUM = 2.8;
export const CIRCLE_SMALL = 3.7;

export const ASPECT_CONSTANT = {
  SQUARE: "square",
  LANDSCAPE: "landscape",
  PORTRAIT: "protrait",
  CIRCLE: "circle",
};

export const TILES_CONSTANT = {
  SMALL: "small",
  MEDIUM: "medium",
  LARGE: "large",
};
export const TILES_OPTIOS = [
  { label: "Large", value: TILES_CONSTANT.LARGE },
  { label: "Medium", value: TILES_CONSTANT.MEDIUM },
  { label: "Small", value: TILES_CONSTANT.SMALL },
];

const getNearestAspect = (aspect) => {
  const aspects = [SQUARE_ASPECT, LANDSCAPE_ASPECT, PORTRAIT_ASPECT];
  let min_diff = Math.abs(aspect - aspects[0]);
  let nearest = aspects[0];
  for (let i = 0; i < aspects.length; i++) {
    let diff = Math.abs(aspect - aspects[i]);
    if (diff < min_diff) {
      min_diff = diff;
      nearest = aspects[i];
    }
  }
  return nearest;
};

const getFontSize = (aspect, size) => {
  let font_size = "md";
  if (aspect <= 0.75) {
    if (size <= 1.75) font_size = "lg";
    else if (size > 1.75 && size <= 2) font_size = "md";
    else if (size > 2 && size <= 2.75) font_size = "sm";
    else if (size > 2.75) font_size = "xs";
  } else if (aspect > 0.75 && aspect < 1.25) {
    if (size <= 1.5) font_size = "lg";
    else if (size > 1.5 && size <= 2) font_size = "md";
    else if (size > 2 && size <= 2.75) font_size = "sm";
    else if (size > 2.75) font_size = "xs";
  } else if (aspect >= 1.25) {
    if (size <= 1.25) font_size = "lg";
    else if (size > 1.25 && size <= 1.75) font_size = "md";
    else if (size > 1.75 && size <= 2.25) font_size = "sm";
    else if (size > 2.5) font_size = "xs";
  }
  return font_size;
};

const getDefaultConfig = (parentType, type) => {
  const isSchedules = type === "schedules";
  const out = {
    aspect: LANDSCAPE_ASPECT,
    size: LANDSCAPE_MEDIUM,
    text_type: TEXT_BEFLOW_IMAGE,
    alignment: "left",
    image_shape: IMAGE_SHAPE.ROUNDED,
  };
  if (parentType === "resources") {
    out.aspect = PORTRAIT_RESOURCE_ASPECT;
    out.size = PORTRAIT_SMALL;
    out.text_type = TEXT_BEFLOW_IMAGE;
  } else {
    //for othres workout and nutritions
    if (isSchedules) {
      out.aspect = LANDSCAPE_ASPECT;
      out.size = LANDSCAPE_LARGE;
      out.text_type = TEXT_OVEVER_IMAGE;
    } else {
      //in case of collections
      out.aspect = SQUARE_ASPECT;
      out.size = SQUARE_SMALL;
      out.text_type = TEXT_BEFLOW_IMAGE;
    }
  }
  return out;
};

export const getIniaialSizeAndAspect = (data, parentType, type) => {
  const default_config = getDefaultConfig(parentType, type);
  //define config
  const custom_layout = data?.common_item_config?.custom_layout || false;
  const custom_width = data?.common_item_config?.custom_width || 3;
  const custom_height = data?.common_item_config?.custom_height || 2;
  const aspect = data?.common_item_config?.image_aspect?.sm || default_config?.aspect;
  const image_shape = data?.common_item_config?.image_shape || default_config?.image_shape;
  const size = data?.columns?.sm || default_config?.size;
  let text_type = data?.common_item_config?.type || default_config?.text_type;
  let alignment = data?.common_item_config?.alignment || default_config?.alignment;
  const font_size = getFontSize(aspect, size);
  //ui constansts
  let image_aspect = ASPECT_CONSTANT.LANDSCAPE;
  let tiles_size = TILES_CONSTANT.LARGE;

  //preapare ui constants from config.
  const nearest_aspect = getNearestAspect(aspect); // get
  if (nearest_aspect === SQUARE_ASPECT) {
    image_aspect = ASPECT_CONSTANT.SQUARE;
    if (size >= SQUARE_SMALL) tiles_size = TILES_CONSTANT.SMALL;
    else if (size >= SQUARE_MEDIDUM) tiles_size = TILES_CONSTANT.MEDIUM;
    else tiles_size = TILES_CONSTANT.LARGE;
  } else if (nearest_aspect === PORTRAIT_ASPECT) {
    image_aspect = ASPECT_CONSTANT.PORTRAIT;
    if (size >= PORTRAIT_SMALL) tiles_size = TILES_CONSTANT.SMALL;
    else if (size >= PORTRAIT_MEDIUM) tiles_size = TILES_CONSTANT.MEDIUM;
    else tiles_size = TILES_CONSTANT.LARGE;
  } else if (nearest_aspect === LANDSCAPE_ASPECT) {
    image_aspect = ASPECT_CONSTANT.LANDSCAPE;
    if (size >= LANDSCAPE_SMALL) tiles_size = TILES_CONSTANT.SMALL;
    else if (size >= LANDSCAPE_MEDIUM) tiles_size = TILES_CONSTANT.MEDIUM;
    else tiles_size = TILES_CONSTANT.LARGE;
  }
  //in case of image shape is circle , values wiill be diffrenet :
  if (image_shape === IMAGE_SHAPE.CIRCLE) {
    image_aspect = ASPECT_CONSTANT.CIRCLE;
    if (size >= CIRCLE_SMALL) tiles_size = TILES_CONSTANT.SMALL;
    else if (size >= CIRCLE_MEDIUM) tiles_size = TILES_CONSTANT.MEDIUM;
    else tiles_size = TILES_CONSTANT.LARGE;
  }
  return {
    image_aspect, //these are ui constants.
    tiles_size,
    text_type,
    image_shape,
    custom_layout,
    custom_width,
    custom_height,
    custom_size: size,
    alignment,
    config: {
      aspect,
      size,
      text_type,
      alignment,
      image_shape,
      custom_layout,
      custom_width,
      custom_height,
      custom_size: size,
      font_size,
    }, //these are actual values
  };
};

export const getSizeAndAspectConfig = (arg, parent_type, type) => {
  const {
    image_aspect,
    tiles_size,
    text_type,
    image_shape,
    custom_layout,
    custom_width,
    custom_height,
    custom_size,
    alignment,
  } = arg;
  let config = { text_type, image_shape, custom_layout, custom_width, custom_height, custom_size, alignment};
  //aspect and size 
  if (custom_layout) {
    config.aspect = Math.round((custom_width / custom_height) * 100) / 100;
    config.size = custom_size;
  } else {
    //if not custom layout
    if (image_aspect === ASPECT_CONSTANT.SQUARE) {
      config.aspect = SQUARE_ASPECT;
      if (tiles_size === TILES_CONSTANT.LARGE) config.size = SQUARE_LARGE;
      else if (tiles_size === TILES_CONSTANT.MEDIUM) config.size = SQUARE_MEDIDUM;
      else if (tiles_size === TILES_CONSTANT.SMALL) config.size = SQUARE_SMALL;
    } else if (image_aspect === ASPECT_CONSTANT.PORTRAIT) {
      config.aspect = parent_type === "resources" ? PORTRAIT_RESOURCE_ASPECT : PORTRAIT_ASPECT;
      if (tiles_size === TILES_CONSTANT.LARGE) config.size = PORTRAIT_LARGE;
      else if (tiles_size === TILES_CONSTANT.MEDIUM) config.size = PORTRAIT_MEDIUM;
      else if (tiles_size === TILES_CONSTANT.SMALL) config.size = PORTRAIT_SMALL;
    } else if (image_aspect === ASPECT_CONSTANT.LANDSCAPE) {
      config.aspect = LANDSCAPE_ASPECT;
      if (tiles_size === TILES_CONSTANT.LARGE) config.size = LANDSCAPE_LARGE;
      else if (tiles_size === TILES_CONSTANT.MEDIUM) config.size = LANDSCAPE_MEDIUM;
      else if (tiles_size === TILES_CONSTANT.SMALL) config.size = LANDSCAPE_SMALL;
    } else if (image_aspect === ASPECT_CONSTANT.CIRCLE) {
      config.aspect = CIRCLE_ASPECT;
      if (tiles_size === TILES_CONSTANT.LARGE) config.size = CIRCLE_LAEGE;
      else if (tiles_size === TILES_CONSTANT.MEDIUM) config.size = CIRCLE_MEDIUM;
      else if (tiles_size === TILES_CONSTANT.SMALL) config.size = CIRCLE_SMALL;
    } else {
      config.aspect = LANDSCAPE_ASPECT;
      config.size = LANDSCAPE_LARGE;
    }
  }
  //alignment :
  let _alignment = alignment;
  if (text_type === TEXT_OVEVER_IMAGE) _alignment = "center";
  else {
    if (image_aspect === ASPECT_CONSTANT.CIRCLE) _alignment = "center";
    else _alignment = "left";
  }
  config.alignment = _alignment;
  //font size
  const font_size = getFontSize(config.aspect, config.size);
  config.font_size = font_size;
  return config;
};

export const convertChildOfToArr=(child_of)=>{
  if(!child_of) return [];
  else{
    if(typeof(child_of)==='string'){
      return [child_of];
    }
    else if(typeof(child_of)==='object'){
      return [...child_of];
    }
  }
}
