import moment from "moment";
import _ from "lodash";
import React, { useContext, useState, useEffect, useCallback } from "react";
import uuid from "uuid/v4";
import InfiniteScroll from "react-infinite-scroll-component";
import firebase from "fitbud/firebase";
import { FirebaseAuthContext } from 'fitbud/providers/firebase-auth';
import { handleError, snapshotDocsArrayToDataArray, getChipLabelPrefixSuffix, pluralize } from "fitbud/utils/helpers";
import { connect, useDispatch, useSelector } from "react-redux";
import { withSnackbar } from "notistack";
import {
    ListItem,
    ListItemText,
    IconButton,
    Typography,
    LinearProgress,
    Link,
    Divider
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import FilterView from "fitbud/components/FilterView";
import PlanFilter from 'fitbud/views/users/PlanFilter';
import TagFilter from 'fitbud/views/users/TagFilter';
import AllClientsQuickFilterCheckbox from 'fitbud/views/users/AllClientsQuickFilter';
import broadcastsRdxFns from "fitbud/redux/broadcastList";
import appRdxFns from "fitbud/redux/app";
import { ListStyling } from "fitbud/hooks/useListItemStyle";
import { NavIcon } from "fitbud/partials/appBar";
import { DateRangeFilter } from "fitbud/components/filterComps";
import { parsePacks } from "../plan/helper";
import { planRdxFns } from "fitbud/redux/exports";
import BroadCastNameDialog from "./broadcastsNameDialog";


const FILTER_OPTIONS = {
    "multi_list_filters": { text: "Client Category", component: AllClientsQuickFilterCheckbox },
    // "attribute_filters": { text: "Attributes", component:AttributeFilter},
    "plan": { text: "Plan", component: PlanFilter },
    "tags": { text: "Tags", component: TagFilter },
    "startDate": { text: "Start Date", component: DateRangeFilter }

}
const PER_PAGE = 15;

export const getFilterString = (criteria={}, planDocs = []) => {
    const fil = []
    if (criteria.multi_list_filters) {
        criteria.multi_list_filters.forEach(val => {
            let str = ''
            if(val.includes('.')) {
                str = _.startCase(val.split('.')[0]) + ' - '
            }
            fil.push(str + getChipLabelPrefixSuffix('multi_list_filters', val))
        })
    }
    if (criteria.plan) {
        criteria.plan.forEach((planId) => {
            if(planId === 'custom'){
                fil.push('Custom');
            } else {
                const planDoc = _.find(planDocs, (i) => i._id === planId, null);
                if(planDoc) fil.push(planDoc.data.ref_name);
            }
        });
    }
    if (criteria.gender) {
        fil.push(...criteria.gender)
    }
    if (criteria.tags) {
        fil.push(...criteria.tags)
    }
    if(criteria.startDate) {
        const { from, to } = criteria.startDate;
        fil.push(`Started ${(from !== to) ? 'between': ''} ${moment(from).format('DD MMM')}${(from !== to) ? ` & ${moment(to).format('DD MMM')}` : ''}`)
    }
    return fil.length ? fil.join('\u2004\u2004●\u2004\u2004') : "";
}

export const DEFAULT_BROADCAST_GROUPS = (cid) => ([{
    _id: `${cid}:all`,
    data: { ref_name: 'All Clients', name: 'All Clients', cid, _cat: new firebase.firestore.Timestamp(3, 0), last_message_timestamp: new firebase.firestore.Timestamp(3, 0) }
},
{
    _id: `${cid}:leads`,
    data: { ref_name: 'Leads', name: 'Leads', cid, _cat: new firebase.firestore.Timestamp(2, 0), last_message_timestamp: new firebase.firestore.Timestamp(2, 0), criteria: { multi_list_filters: ['leads'] } }
},
{
    _id: `${cid}:active`,
    data: { ref_name: 'Active Clients', name: 'Active Clients', cid, _cat: new firebase.firestore.Timestamp(1, 0), last_message_timestamp: new firebase.firestore.Timestamp(1, 0), criteria: { multi_list_filters: ['active'] } }
},
{
    _id: `${cid}:completingsoon`,
    data: { ref_name: 'Completing Soon', name: 'Completing Soon', cid, _cat: new firebase.firestore.Timestamp(0, 0), last_message_timestamp: new firebase.firestore.Timestamp(0, 0), criteria: { multi_list_filters: ['active.completing_soon'] } }
},
// {
//     _id: `${cid}:newleadstomessage`,
//     data: { ref_name: 'Uncontacted Leads', name: 'Uncontacted Leads', cid, _cat: new firebase.firestore.Timestamp(0, 0), last_message_timestamp: new firebase.firestore.Timestamp(0, 0), criteria: { multi_list_filters: ['leads.not_contacted'] } }
// }
])

const BroadcastList = props => {
    const { cid, comp  } = useContext(FirebaseAuthContext);
    const { onSelect, selected, set, insert, delete: deleteFn,  enqueueSnackbar, toggleLoader, loading, docs = [], hideLoader, showLoader } = props;    
    const [groupName, setGroupName] = useState('');
    const [errors, setErrors] = useState(null);
    const [filter, setFilter] = useState({});
    const [isFilterOpen, toggleFilter] = useState(false);
    const [showNameDiag, setShowNameDiag] = useState(false);
    const [lastBroadcast, setLastBroadcast] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    const toggleFilterView = () => toggleFilter(c => !c)
    const company = comp ? comp.data() : {};
    const isGroupClass = Boolean(_.get(company, "features.group_class.enabled", false));
  

    const d = useDispatch();
    const planOps = planRdxFns(d);
    const planRedux = useSelector(s => s.plans);
    const broadcastListCondition = firebase.firestore().collection('groups').where('cid', '==', cid).where('type', '==', 'auto').where('deleted','==', false).orderBy('last_message_timestamp', 'desc')

    const fetchPlanData = useCallback(async() => {
        const data = await firebase.firestore().collection(`companies/${cid}/packs`).get();
        planOps.set(parsePacks(data, isGroupClass), 0, true);
    }, [cid, planOps]);

    const fetchBroadcasts = async (next = false) => {
        if(next && !lastBroadcast) return;
        toggleLoader();
        try {
            const snapshot = next ? (await broadcastListCondition.limit(PER_PAGE).startAfter(lastBroadcast).get()) : await broadcastListCondition.limit(PER_PAGE).get();
            if (!snapshot.empty) {
                setLastBroadcast(snapshot.docs[snapshot.docs.length - 1]);
                const data = snapshotDocsArrayToDataArray(snapshot.docs);
                const plansToFetch = data.some((doc) => _.get(doc, 'data.criteria.plan', false));
                if(plansToFetch && !planRedux.docs) await fetchPlanData();
                set(data);
                setHasMore(snapshot.docs.length === PER_PAGE);
            } else {
                setHasMore(false);
                toggleLoader();
            }
        } catch (error) {
            console.log(error)
            handleError(error, enqueueSnackbar)
            toggleLoader();
        }
    }
    const fetchNextBroadcasts = () => fetchBroadcasts(true);

    useEffect(() => {
        if(!docs.length)
            fetchBroadcasts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const unsubscribe = firebase
            .firestore()
            .collection('groups')
            .where('cid', '==', cid)
            .where('type', '==', 'auto')
            .where('deleted','==', false)
            .where('last_message_timestamp', '>', firebase.firestore.Timestamp.fromMillis(Date.now()))
            .onSnapshot((snapshot) => {
                snapshot.docChanges().forEach((change) => {
                    const index = docs.findIndex(i => change.doc.id === i._id);
                    if (change.type === "added") {
                        console.log("New group: ", change.doc.data());
                        if(index >= 0) {
                            deleteFn(change.doc.id);
                        }
                        insert({
                            _id: change.doc.id,
                            data: {
                                ...change.doc.data()
                            }
                        });
                    }
                    if (change.type === "modified") {
                        console.log("Modified group: ", change.doc.data());
                        // update({
                        //     _id: change.doc.id,
                        //     data: {
                        //         ...change.doc.data()
                        //     }
                        // });

                    }
                    if (change.type === "removed") {
                        console.log("Removed group: ", change.doc.data());
                    }
                });
            });
        return () => unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [docs.length])


    const createCustomGroup = async () => {//onSave
        if(!groupName) {
            setGroupName(null);
            setErrors({name:"Please enter a valid name."})
            return;
        }
        showLoader();
        const _filter=_.omit({...filter,...(filter['attribute_filters']||{})},['attribute_filters']);
        let grp = _.find(docs.concat(DEFAULT_BROADCAST_GROUPS(cid)), (d) => _.isEqual(d.data.criteria, _filter))
        if (!grp) {
            const snap = await firebase
                .firestore()
                .collection('groups')
                .where('cid', '==', cid)
                .where('criteria', '==', _filter)
                .get();
            if (!snap.empty) {
                grp = snapshotDocsArrayToDataArray(snap.docs)[0];
            }
        }
        if (grp) {
            hideLoader();
            setShowNameDiag(false);
            if(grp.data.deleted) {
                await firebase.firestore().doc(`groups/${grp._id}`).update({ deleted: false })
            }
            onSelect(grp._id)
            clear();
            return;
        }
        const group = {
            cid, ref_name: groupName, name: groupName, criteria: _filter,
            deleted: false, type: 'auto', _cat: firebase.firestore.FieldValue.serverTimestamp(), deletable: true,
            last_message_timestamp: firebase.firestore.FieldValue.serverTimestamp()
        }
        const groupId = `${cid}:${uuid()}`;
        try {
            await firebase.firestore().doc(`groups/${groupId}`).set(group);
            onSelect(groupId)
        } catch (error) {
            console.log(error)
            handleError(error, enqueueSnackbar)
        }
        setShowNameDiag(false);
        hideLoader();
        clear();
    }
    let onClick = null;
    if (!!onSelect)
        onClick = e => {
            onSelect(e.currentTarget.id);
        };
    
    const onClose = () => {
        toggleFilterView();
        setShowNameDiag(false)
    }
    const onChange = (e) => {
        setGroupName(e.target.value);
        setErrors({});
    }
    const onSetFilter = (filter) => {
        setFilter(filter);
        setShowNameDiag(true);
    } 
    const clear = () => {
        setGroupName('');
        setFilter({});
        setErrors({});
    }
    const nonCreatedDefaultGrps = DEFAULT_BROADCAST_GROUPS(cid).filter(grp => _.findIndex(docs, (d) => d._id === grp._id) === -1 )
    let grpDocs = docs.concat(nonCreatedDefaultGrps);
    
    return (
        <div className="d-flex flex-column vh-100">
            <div className="position-relative bg-body"
            >
                <div className="d-flex align-items-center fpx-20 fpy-8 bg-body">
                    <NavIcon/>
                    <Typography variant="h3"  >
                        Broadcast Message
                    </Typography>
                    <div className='flex-grow-1'/>
                    <IconButton
                        className="fmr-n10"
                        onClick={toggleFilterView}
                    >
                        <AddIcon />

                    </IconButton>
                </div>
                {!!loading && <LinearProgress className={"position-absolute w-100 height-2"} style={{ left: 0, bottom: -2 }} />}
            </div>
            <Divider className="dense"/>
            <div id="broadcastList" className="d-flex flex-column bg-white overflow-auto">
                <InfiniteScroll
                    dataLength={grpDocs.length}
                    next={fetchNextBroadcasts}
                    hasMore={hasMore}
                    scrollableTarget="broadcastList"
                >
                    <ListStyling>
                    {grpDocs.map((doc, index) => {
                        if (!doc) return null;
                        else {
                            let { ref_name, last_message_timestamp: timeStamp, total_messages, criteria } = doc.data;
                            if (!!timeStamp) {
                                timeStamp = moment(new firebase.firestore.Timestamp(timeStamp.seconds, timeStamp.nanoseconds).toMillis()).local()
                                if(timeStamp.format('YYYY') !== '1970') {
                                    if (timeStamp.isBefore(moment(), "day"))
                                    timeStamp = timeStamp.format("DD/MM");
                                    else timeStamp = timeStamp.format("LT");
                                }else {
                                    timeStamp = null;
                                }
                            }
                            return (
                                <ListItem
                                    key={doc._id}
                                    id={doc._id}
                                    selected={selected === doc._id}
                                    divider
                                    button
                                    onClick={onClick}
                                >

                                    <ListItemText
                                        primary={(
                                            <span className="d-flex align-items-center justify-content-between">
                                                <span className="text-truncate">{ref_name}</span>
                                                {timeStamp && <Typography color="textSecondary" className="font_12_500">{timeStamp}</Typography>}
                                            </span>
                                        )}
                                        secondary={
                                            <>
                                                {!!getFilterString(criteria, planRedux.docs) && <span className="d-block fmb-10 font_11_500 text-truncate">{getFilterString(criteria, planRedux.docs)}</span>}
                                                <span className="text-primary font_13_500">{total_messages ? `${total_messages} Message${pluralize(total_messages)}` : 'No Messages Sent'}</span>
                                            </>
                                        }
                                        classes={{
                                            root: "text-truncate",
                                            multiline: "fm-0",
                                            primary: "font_15_600 text-truncate",
                                            secondary: "text-truncate fmt-10"
                                        }}
                                    />
                                </ListItem>
                            );
                        }
                    })}
                    <Link
                        component="button"
                        variant="caption"
                        underline="none"
                        className="w-100 mx-auto fpy-20 font_15_600 bg-body"
                        onClick={toggleFilterView}
                    >
                        Send to Custom Group
                    </Link>
                    </ListStyling>
                </InfiniteScroll>
            </div>
            
            {isFilterOpen && <FilterView title="Send Broadcast to" keyName="browse" open filter_opts={FILTER_OPTIONS} handleClose={toggleFilterView} filter={filter}  setFilter={onSetFilter} actionText="Next" />}
            {showNameDiag && (<BroadCastNameDialog actionText="Done" onClose={onClose} onSave={createCustomGroup}  name={groupName}  onChange={onChange}  loading={loading}  infoText={getFilterString(filter, planRedux.docs)} errors={errors}/>)}
        </div>
    );
};

const mapStateToProps = (s, op) => {
    return s.broadcastList;
};

const mapDispatchToProps = d => {
    return {...broadcastsRdxFns(d), ...appRdxFns(d) }
};


export default withSnackbar(
    connect(mapStateToProps, mapDispatchToProps)(BroadcastList)
);

