import DateFnsUtils from "@date-io/moment";
import { Slide } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import Dialog from "fitbud/components/Dialog";
import { makeBooking, rescheduleBooking } from "fitbud/utils/scheduling";
import moment from "moment";
import { withSnackbar } from "notistack";
import React, { useContext, useState } from "react";
import { useLiveSessions } from "fitbud/providers/liveSessionsProvider";
import BookingInfo from './bookingInfo';
import UserSearch from "./userSearch";
import { FirebaseAuthContext } from "fitbud/providers/firebase-auth";
import { RoleContext } from "fitbud/providers/roleProvider";
import { useEffect } from "react";

function BookingDialog(props) {
  const { showBackdrop, hideBackDrop, cid } = useLiveSessions();
  const { enqueueSnackbar, rescheduleMode, data, directBooking, defaultTrainer, ...restProps } = props;
  const {authUser : { uid: ownerId } = {}, comp, primary_owner_id } = useContext(FirebaseAuthContext);
  const { tEnabled, isOwner } = useContext(RoleContext)
  const [step, setStep] = useState(rescheduleMode || directBooking ? 2 :1);
  const [user, setUser] = useState(directBooking ? {...data} : {});
  const [payload, setPayload] = useState(undefined);
  const [errors, setErrors] = useState({});
  const [userBookingsCount, setUserBookCount] = useState(undefined);

  useEffect(()=>{
    if(data || directBooking){
      setUser(data);
      setStep(2);
    }
  },[directBooking, data])

  function handleTimeChange(data){
    setPayload({...data}) // Set payload from slot selection component
  }
  function goBackToStep1(){
    setStep(1);
    setUser({});
  }
  function validate() {
    let out = true;
    let error = {};
    if (!payload || !payload.time){
      error.time = "Please select a slot";
      setErrors(error);
      out = false;
      return out;
    }
    // if past time is selected
    if (moment(payload.time).isBefore(moment())) {
      error.time = "Slot in past time cannot be booked";
      setErrors(error);
      out = false;
      return out;
    }
    return out;
  }
  function checkTrainer(){
    if (!tEnabled) return true;
    if (!user || !user.trainer_id){
      enqueueSnackbar('Please assign a trainer for this customer before adding a booking for them', {variant: 'warning'});
      return false;
    }
    return true;
  }
  const selectUser = (id, userDoc) => {
    if(!userDoc) return setUser({}); // Unselect user if selected
    const {_id, data} = userDoc;
    setUser({
      _id,
      ...data,
    });
    setStep(2);
  }
  async function onSubmit(e) {
    e.preventDefault();
    // Check for all conditions before api call
    // if (!rescheduleMode && !isOwner) return enqueueSnackbar('This user is not on a video calling plan', {variant: 'warning'});
    if((step === 1) && !user._id) return enqueueSnackbar('Please select a user', {variant: 'warning'});
    if(step === 1) return setStep(2);
    if(!validate()) return;
    // if (!isOwner && userBookingsCount <= 0) return enqueueSnackbar('This client has exhausted the call quota', {variant: 'warning'});
    // if(!isOwner && !rescheduleMode && !checkTrainer()) return; //skip this check for owner..
    const {time, duration, isTrainerBooking, selectedTrainer, service,location} = payload;
    if(rescheduleMode && moment(data.startDate).isSame(moment(time)))
      return enqueueSnackbar('Please select a different date or time to reschedule', {variant: 'warning'});
    showBackdrop();
    let res = null;
    const {email, name, image_data, trainer_id, profile } = user || {};
    if (rescheduleMode) {
      res = await rescheduleBooking(cid, {
        book_id: data.book_id,
        newSlot: time,
        duration,
        serviceId: service || null,
        locationId: location || null,
      });
    } else {
      res = await makeBooking(cid, {
        uid: user.uid,
        time : time,
        duration,
        isTrainerBooking: isTrainerBooking,
        userProfile : { name, email, image_data, time_zone: (!!profile && profile.time_zone) || 'UTC' },
        trainer_id : selectedTrainer ||  trainer_id || ownerId || primary_owner_id || null, // selected trainer -> users assign trainer -> (not found case : trainer feature not found and user not have assigned user) -> logged in trainer -> primary owner
        serviceId: service || null,
        locationId:location || null,
      });
    }
    if (res) {
      if (!res.success) {
        enqueueSnackbar(res.message, { variant: "error" });
        return hideBackDrop();
      }
      let _message = rescheduleMode? "Booking rescheduled." : "Booking successful.";
      if(res?.warning) _message = _message + ` although ` + (res?.warning).toLowerCase();
      enqueueSnackbar(_message, { variant: "success" })
      if(user && user.trainer_id && user.trainer_id !== ownerId){
      // When it is booking for other trainer don't refresh data
        props.onClose();
        return hideBackDrop();
      }
      props.onClose();
      return hideBackDrop();
    } else {
      enqueueSnackbar("Something went wrong", { variant: "error" });
      return hideBackDrop();
    }
  }

  // First show user search on mount
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Dialog
        {...restProps}
        titleFont="h3"
        buttonColor="primary"
        paperClass={step === 2 ? "width-600" : "width-600 height-70"}
        dialogContentClassName="d-flex flex-column"
        toolbarClass="height-60"
        onSave={step === 2 && onSubmit}
        title={`${rescheduleMode ? "Reschedule Session" : "Book 1 on 1 Session"}`}
        actionText={'Save'}
        hideHeaderDivider
      >
        <div className='position-relative d-flex flex-column h-100'>
          {/* User Search */}
          {step === 1 ? (
            <Slide direction="right" in={step === 1} timeout={{ enter: 100, exit: 100}} mountOnEnter unmountOnExit>
              <div className="h-100"> 
                <UserSearch cid={cid} comp={comp} selectUser={selectUser} ownerId={ownerId} />
              </div>
            </Slide>
          ) : null }

          {/* Date and time selection */}
          {step === 2 ? (
            <Slide direction="left" in={step === 2} timeout={{ enter: 100, exit: 100}} mountOnEnter unmountOnExit>
              <div className='px-20 pt-16 pb-20'>
                <BookingInfo
                  goBack={goBackToStep1} 
                  user={user}
                  data={data}
                  rescheduleMode={rescheduleMode}
                  onChange={handleTimeChange} 
                  setErrors={setErrors}
                  errors={errors}
                  directBooking={directBooking}
                  userBookingsCount={userBookingsCount}
                  setUserBookCount={setUserBookCount}
                  defaultTrainer={defaultTrainer}
                  />
              </div>
            </Slide>
          ) : null}
        </div>
      </Dialog>
    </MuiPickersUtilsProvider>
  );
}

export default withSnackbar(BookingDialog);
