import { Checkbox, Typography, FormControlLabel, makeStyles } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import { RoleContext } from 'fitbud/providers/roleProvider';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { isInactiveUser, isSelfSignupUser } from 'fitbud/views/users/helpers';
import { getServiceQuota } from 'fitbud/api';
import _ from 'lodash';
import { DEFAULT_ERROR } from 'fitbud/utils/constants';
import clsx from 'clsx';
import useTrainers from 'fitbud/hooks/useTrainers';
import PersonIcon from '@material-ui/icons/Person';

const useStyles = makeStyles(()=>({
  disable_text:{
    color:"#A2AEBF !important"
  }
}))


const UserNameBox = ({ user, onClick, showTrainerName, disableEdit }) => {
  let { name, email, trainer_id } = user;
  const {getTrainer} = useTrainers();
  const trainer_name = useMemo(()=>{
    if(!trainer_id) return null;
    return getTrainer(trainer_id)?.data?.name || null;
  },[getTrainer, trainer_id])
  if (!name || !email) {
    // Get name and email from profile if user structure is different than elastic one
    let { name: _name, email: _email } = user.profile;
    name = _name;
    email = _email;
  }
  return (
    <div
      className={`w-100 d-flex justify-content-between 
        align-items-start px-15 py-20 border rounded shadow-sm ${!disableEdit ? 'cursor-pointer' : ''}`}
      onClick={onClick}>
      <div className="d-flex flex-column" style={{ width: '90%' }}>
        <Typography variant="h3" className='text-capitalize' noWrap>
          {name || ''}
        </Typography>
        <Typography className="font_15_500 mt-8 text-grey" noWrap >
          {email || ''}
        </Typography>
        {!!showTrainerName && !!trainer_name ? (
          <div className="d-flex fmt-15 align-items-center">
            <PersonIcon fontSize="small" style={{color:"#6F8099"}} />
            <Typography className=" font_15_600 ml-8">
              {trainer_name}
            </Typography>
          </div>
        ) : null}
      </div>
      {!disableEdit && (
        <div>
          <CreateIcon fontSize="small" />
        </div>
      )}
    </div>
  );
};
const UserData = ({
  user,
  rescheduleMode,
  onNameBoxClick,
  toggleQuota,
  isTrainerBooking,
  disableEdit,
  date,
  service,
}) => {
  const classes = useStyles();
  const isLead = isSelfSignupUser(user);
  const inInactive = isInactiveUser(user);
  const showQuotaCount = !isLead && !inInactive;
  const { isOwner, tEnabled } = useContext(RoleContext);
  const [loading, setLoading] = useState(true);
  const [quota, setQuota] = useState({});
  const uid = user?.uid || '';
  const cid = user?.cid;
  

  const fetchQuota = async (payload) => {
    setLoading(true);
    try {
      const response = await getServiceQuota(payload);
      const { success, message, purchases } = response?.data;
      const isQuotaAvailable = (purchases || []).some((p) => !!p.remaining);
      if (!isTrainerBooking && !isQuotaAvailable) toggleQuota();
      setQuota({ success, message, purchases });
    } catch (err) {
      setQuota({ success: false, message: '', purchases: [], error: true });
    }
    setLoading(false);
  };

  useEffect(() => {
    if ((!!uid && !!service && !!cid && !!date) || showQuotaCount) {
      fetchQuota({ pid: cid + ':' + uid, serviceId: service, time: date });
      //make count call...
    }
  }, [uid, cid, service, date, showQuotaCount]);

  const checkQuotaDisable = () => {
    if (loading) return true;
    if (_.isEmpty(quota) || _.isEmpty(quota?.purchases)) return true;
    return (quota?.purchase || []).some((p) => !!p.remaining);
  };

  return (
    <div>
      <div>
        <UserNameBox
          user={user}
          showTrainerName={!rescheduleMode && !!tEnabled && isOwner}
          onClick={disableEdit ? null : onNameBoxClick}
          disableEdit={disableEdit}
        />
      </div>
      {showQuotaCount ? (
        <div className="mt-15">
          <FormControlLabel
            classes={{ root: 'mb-0', label: 'font_16_600', disabled:classes.disable_text }}
            disabled={checkQuotaDisable()}
            checked={!isTrainerBooking}
            onChange={toggleQuota}
            control={<Checkbox color="primary" />}
            label="Count this booking in client's quota"
          />
          <ClientQuota quota={quota} loading={loading} user={user} />
        </div>
      ) : null}
    </div>
  );
};

const ClientQuota = (props) => {
  const { user, quota, loading } = props;
  const user_purchases = user?.purchases || {};
  const mapUserPurchaseByProductId = useMemo(() => {
    let current_purchases = _.isArray(user_purchases) ? user_purchases : _.values(user_purchases);
    if (_.isArray(user_purchases))
      current_purchases = _.map(current_purchases, (cp) => {
        return { ...cp, productId: cp?.packId, pack: { ...cp, title: cp?.packTitle } };
      });
    return (current_purchases || []).reduce((acc, curr) => {
      acc[curr?.productId || curr?.packId] = { pack: curr?.pack, accessType: curr?.accessType };
      return acc;
    }, {});
  }, [user_purchases]);

  const display_values = useMemo(() => {
    const { purchases } = quota || {};
    if (!purchases && !mapUserPurchaseByProductId) return [];
    const out = [];
    _.forEach(purchases, (purchase) => {
      const info = mapUserPurchaseByProductId[purchase?.productId];
      if (_.isEmpty(info)) return;
      out.push({ type: info?.accessType, name: info?.pack?.title || info?.pack?.ref_name, ...purchase });
    });
    return _.sortBy(out, ['priority']);
  }, [mapUserPurchaseByProductId, quota]);

  if (loading)
    return (
      <div className='p-15 border border-rounded mt-5 mb-10'>
        <Item>
          <Typography className='font_15_500 text-grey'>
            Loading&nbsp;<span class="dots"></span>{' '}
          </Typography>
        </Item>
      </div>
    );

  if (!quota.success)
    return (
      <div className='p-15 border border-rounded mt-5 mb-10'>
        <Item text={quota?.message || DEFAULT_ERROR} textClassName="text-center text-grey text-capitalize" />
      </div>
    );

  if (!display_values || !display_values.length)
    return (
      <div className='p-15 border border-rounded mt-5 mb-10'>
        <Item text='Client does not have Session Access at the moment' textClassName="text-center text-grey text-capitalize" />
      </div>
    );

  return (
    <div className='px-15 py-10 border border-rounded mt-5 mb-10'>
      {display_values.map((d) => {
        return (
          <Typography className='my-5 d-flex'>
            <span className="font_15_600 text-capitalize">{d.name}&nbsp; &nbsp;</span>
            <div className='flex-1'/>
            <span className="font_13_500 text-grey">
              <span className="font_13_600">{d?.remaining}</span>&nbsp;/&nbsp;<span>{d?.allowedCount} Sessions Left</span>
            </span>
          </Typography>
        );
      })}
    </div>
  );
};

const Item = (props) => {
  const { text, textClassName="" } = props;
  return (!!text ? <Typography className={clsx("font_15_500 mb",textClassName )}>{text}</Typography> : props.children);
};

export default UserData;
