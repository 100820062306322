import { SvgIcon } from '@material-ui/core';
import React from 'react';

export default function PinIcon(props) {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 12 18"
      classes={{
        root: 'small',
      }}
    >
      <svg width="12" height="18" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5.99988 12.6836V16.3669"
          stroke="#0D0D0D"
          stroke-width="1.7"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M3.78993 8.08601C3.78978 8.36011 3.71318 8.62874 3.56872 8.86169C3.42427 9.09464 3.2177 9.28267 2.97223 9.40465L1.66096 10.0676C1.4155 10.1896 1.20892 10.3777 1.06447 10.6106C0.920014 10.8436 0.843407 11.1122 0.843262 11.3863V11.9461C0.843262 12.1415 0.920875 12.3289 1.05903 12.467C1.19718 12.6052 1.38455 12.6828 1.57993 12.6828H10.4199C10.6153 12.6828 10.8027 12.6052 10.9408 12.467C11.079 12.3289 11.1566 12.1415 11.1566 11.9461V11.3863C11.1564 11.1122 11.0798 10.8436 10.9354 10.6106C10.7909 10.3777 10.5844 10.1896 10.3389 10.0676L9.02763 9.40465C8.78216 9.28267 8.57559 9.09464 8.43113 8.86169C8.28668 8.62874 8.21007 8.36011 8.20993 8.08601V5.31615C8.20993 5.12077 8.28754 4.9334 8.42569 4.79524C8.56385 4.65709 8.75122 4.57948 8.9466 4.57948C9.33735 4.57948 9.7121 4.42425 9.9884 4.14795C10.2647 3.87165 10.4199 3.4969 10.4199 3.10615C10.4199 2.71539 10.2647 2.34065 9.9884 2.06434C9.7121 1.78804 9.33735 1.63281 8.9466 1.63281H3.05326C2.66251 1.63281 2.28776 1.78804 2.01146 2.06434C1.73515 2.34065 1.57993 2.71539 1.57993 3.10615C1.57993 3.4969 1.73515 3.87165 2.01146 4.14795C2.28776 4.42425 2.66251 4.57948 3.05326 4.57948C3.24864 4.57948 3.43601 4.65709 3.57416 4.79524C3.71232 4.9334 3.78993 5.12077 3.78993 5.31615V8.08601Z"
          stroke="#0D0D0D"
          stroke-width="1.7"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </SvgIcon>
  );
}
