import { Typography } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import * as Sentry from '@sentry/browser';
import firebase from 'firebase';
import { bffGetCatalogByIDs } from 'fitbud/api';
import Dialog from 'fitbud/components/Dialog';
import { ClrdButton } from 'fitbud/components/form-fields';
import ReorderDialog from 'fitbud/components/reorderDialog';
import { FirebaseAuthContext } from 'fitbud/providers/firebase-auth';
import appRdxFns from "fitbud/redux/app";
import { DEFAULT_ERROR ,PUBLISHED, DRAFT,VIEWS_CONSTANTS,EMPTY_SCH_DISCARD_MSG,DISCARD_MSG} from "fitbud/utils/constants";
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';
import MealSelector from '../schedules/mealSelector';
import WorkoutSelector from '../schedules/workoutSelector';
import AlternateWoDialog from './alternateWoDialog';
import { addIdsToDay, getAllIDsToFetch, getDataKey, getWoMlEmptyWeek, getWoMlFirebase, processScheduleMeta,processMlScheduleMeta, updateDayInWeekInData, updateWeekInData, validateWoMlSchedule, generateMetaMap, getWeekTitle,trimScheduleData } from './helper';
import ScheduleSelector from './scheduleSelector';
import { HeaderActions, ScheduleDayContainer, ScheduleWeekCard, EditTitleDialog, TagsDialog, WoTagsDialog } from './subComponents';
import React from 'react';
import Confirmation from 'fitbud/components/confirmationDialog';
import {CustomConfirmation} from "fitbud/components/customConfirmation";
import {Title} from "fitbud/views/workouts/form";
import {PublishedDrawer} from "fitbud/views/workouts/publishdedDrawer";
import {SaveIcon} from "fitbud/icons/saveIcon";
import clsx from "clsx";
import { isFilterEmpty as checkIfEmpty } from "fitbud/utils/helpers";
import update from "immutability-helper";

const WoMlScheduleEditor = (props) => {
  const { open,doc, scheduleType, id, isNew, fetchScheduleDoc, toggleDrawer, 
    fetchedItems, updateFetchedItems, selectedRange, updateSelectedRange,showCustomConfirmation ,
    handlePublish,handleDraft,onClose,draftFeature,isDraftAvailable =false,
    showImportBtn = false, modifySchDuration = true} = props;
  const d = useDispatch();
  const { showLoader, hideLoader } = appRdxFns(d);
  const { cid, comp } = useContext(FirebaseAuthContext);
  const { enqueueSnackbar } = useSnackbar();

  // LOCAL STATE
  const [isDirty, setDirty] = useState(false);
  const [localState, updateLocalState] = useState(_.cloneDeep(doc));
  const [copyState, updateCopyState] = useState(null);
  const [selectorState, setOpenSelector] = useState(false);
  const [itemsMeta, setItemsMeta] = useState(_.cloneDeep(doc.meta || {}));
  // Dialog State
  const [reorderDialog, setReorderDialog] = useState(false);
  const [importScheduleDialog, setImportScheduleDialog] = useState(false);
  const [alternateDialog, setAlternateDialog] = useState(false);
  const [dayTagsDialog, setDayTagsDialog] = useState(false);
  const [woTagsDialog, setWoTagsDialog] = useState(false);
  const [errors ,setErrors] = useState(false);
  const [isCustomConfrmtn,toggleCustomConfrmtn]=useState(false);
  const [mountLock,setMountLock]=useState(false);
  const [viewOrigDoc,toggleOrigDoc]=useState(false);
  const [selectedWTitleIndex, setSelectedWTitleIndex] = useState(null);

  const isWeekTitleEditable = useMemo(()=>{
    if(scheduleType !== "workout") return false;
    if(!["burnfatandfeast", "fitwithmaisa", "abhinavmahajan", "hfggx1nkxm", "paritosh","tasdeekraza123"].includes(cid)) return false;
    return true;
  },[cid,scheduleType])




  const isLocalStateEmpty=useMemo(()=>{
    return checkIfEmpty(_.get(localState,'data',{}))
  },[_.get(localState,'data',{})]);

  const isScheduleEmpty=props.isScheduleEmpty && isLocalStateEmpty;
    
  useEffect(()=>{
    if(doc && !!Object.keys(doc).length && !mountLock){
      updateLocalState(_.cloneDeep({...doc}));
      setMountLock(true);
    }
  },[doc])

  const validate = (docType) => {
    if(!localState.ref_name){
      setErrors({ ref_name: true });
      enqueueSnackbar("Schedule Name is required", { variant: 'error' });
      return false;
    };
    //validateWoMlSchedule tests if any day is empty
    //used in challenges update aswell
    let [errors, finalData] = validateWoMlSchedule(localState, scheduleType);
    if(docType===DRAFT){
      errors={};//draft schedules can be empty
    }
    if(scheduleType === 'workout' && Object.keys(errors).length > 0) {
      setErrors(errors); 
      return false
    };
    // Sanitize meta data here only
    const itemsMeta = generateMetaMap(localState, scheduleType, fetchedItems);
    return scheduleType === 'workout' ?  processScheduleMeta({ ...finalData, meta: { ...itemsMeta }}) 
      : processMlScheduleMeta({ ...finalData, meta: { ...itemsMeta }});
  };
  const handleDone=()=>{
    if(!isDirty && !isScheduleEmpty){
      onClose();
      return;
    }
    if(isNew) handleSave(DRAFT,true);
    else{
      if(draftFeature){
        toggleCustomConfrmtn(true);
      }
      else{
        handleSave(PUBLISHED,true);
      }
    }
  }
  const handleSave = (docType=PUBLISHED,closeOnSaving=false) => {
    docType=draftFeature?docType:PUBLISHED;
    let finalDoc = validate(docType);
    if(!finalDoc) return;
     docType===PUBLISHED?handlePublish(finalDoc):handleDraft(finalDoc,closeOnSaving);
     //hack- avoid UI change of customConfirmation
     if(!closeOnSaving){
      setDirty(false);
     }
  };

  // Dialog triggers
  const toggleSelector = (e, {weekIndex, dayIndex}={}) => {
    setOpenSelector(prev => !!prev  ? false : {type: `${scheduleType}_select`, weekIndex, dayIndex });
  };
  const toggleReorderDialog = (val=true) => {setReorderDialog(val)};
  const toggleScheduleImport = (val=true) => {setImportScheduleDialog(val)};
  const toggleAlternateDialog = (val=true) => {setAlternateDialog(val)};
  const closeEditTitleDialog = (val = null) => setSelectedWTitleIndex(null);

  // Event Handlers
  const addItemsToDay = (ids=[]) => {
    if(!ids.length) return toggleSelector();
    setDirty(true);
    const _items = {};
    const _itemsMeta = {};
    ids.forEach((_data) => {
      const { _id, data } = _data;
      _items[_id] = { ...data, _id };
      if(scheduleType === 'workout') _itemsMeta[_id] = data.duration || 0;
      if(scheduleType === "meal") _itemsMeta[_id] = {calories:_.get(data, "macros.calories", 0)};
    });
    const { weekIndex: wI, dayIndex: dI } = selectorState;
    const dayData = _.get((localState.data || {}), `w${wI}.d${dI}`, {});
    const newDayData = addIdsToDay(ids.map(id => id._id), dayData, scheduleType);
    const updatedState = updateDayInWeekInData(localState, `w${wI}`, `d${dI}`, newDayData);
    updateFetchedItems(prev => ({ ...prev, ..._items }));
    setItemsMeta(prev => ({ ...prev, ..._itemsMeta }));
    updateLocalState(updatedState);
    return toggleSelector();
  };

  const handleTagsChange = (tags=[]) => {
    const {weekIndex, dayIndex} = dayTagsDialog
    const weekKey = `w${weekIndex}`;
    const dayKey = `d${dayIndex}`;
    const dayTags = comp.data().app_config?.tags?.daytags || {};
    const isWorkoutOptional = tags.some(tag => dayTags[tag].w_opt);

    // set w_opt to true if any of the tags having w_opt as true. (For backward compatibility)
    const updatedLocalState = update(localState, {
      data: {
        [weekKey]: {
          [dayKey]: {
            tags: {
              $set: tags
            },
            w_opt: {
              $set: isWorkoutOptional
            }
          }
        }
      }
    });

    setDirty(true);
    updateLocalState(updatedLocalState);
    setDayTagsDialog(false)
  }

  const deleteTag = ({tag, weekIndex, dayIndex}) => {
    const weekKey = `w${weekIndex}`;
    const dayKey = `d${dayIndex}`;
    const dayTagsOptions = comp.data().app_config?.tags?.daytags || {};
    const dayTags = localState.data[weekKey][dayKey].tags || [];
    const updatedTags = dayTags.filter(t => t !== tag);
    const isWorkoutOptional = updatedTags.some(tag => dayTagsOptions[tag].w_opt);
    const isRestInDayData = "rest" in localState.data[weekKey][dayKey];
    const removeRest = isRestInDayData && tag === "rest";

    const updatedLocalState = update(localState, {
      data: {
        [weekKey]: {
          [dayKey]: {
            tags: {
              $set: updatedTags
            },
            w_opt: {
              $set: isWorkoutOptional
            },
            ...(removeRest && {
              $unset: ["rest"]
            })
          }
        }
      }
    })
    setDirty(true)
    updateLocalState(updatedLocalState)
  }


  const handleDayChange = ({ dayData, dayIndex, weekIndex }) => {
    setDirty(true);
    const updatedState = updateDayInWeekInData(localState, `w${weekIndex}`, `d${dayIndex}`, { ...dayData });
    updateLocalState({ ...updatedState });
  };

  const onDragEnd = (dragData) => {
    setDirty(true);
    const { draggableId = '', destination = {} } = dragData || {};
    const { droppableId: destDropId = '', index: destIndex  } = destination || {};
    const [srcWeekKey, srcDayKey, itemId, srcIndex] = draggableId.split('.');
    const [destWeekKey, destDayKey] = destDropId.split('.');
    if(!srcDayKey || !srcWeekKey || !itemId || !destDropId) return;
    const dataKey = getDataKey(scheduleType);
    const { data } = _.cloneDeep(localState);

    if (!destDayKey || !destWeekKey || ((srcWeekKey === destWeekKey) && srcDayKey === destDayKey)){
      // When drop is at the source only
      let srcDayData = _.get(data, `${srcWeekKey}.${srcDayKey}`, { [dataKey]: [] });
      srcDayData[dataKey].splice(srcIndex, 1);
      srcDayData[dataKey].splice(destIndex, 0, itemId);
      let updatedState = updateDayInWeekInData(localState, srcWeekKey, srcDayKey, srcDayData);
      return updateLocalState(updatedState);
    };

    let srcDayData = _.get(data, `${srcWeekKey}.${srcDayKey}`, { [dataKey]: [] });
    let destDayData = _.get(data, `${destWeekKey}.${destDayKey}`, { [dataKey]: [] });
    if(destDayData[dataKey].includes(itemId)){
       // if that id is already in dest day - do nothing
       return enqueueSnackbar(
        `${scheduleType === 'workout' ? 'Workout' : 'Meal'} already present in this day`,
        {variant: 'warning'}
      );
    };
    srcDayData[dataKey].splice(srcIndex, 1);
    if(destDayData[dataKey][0] === 'rest')
      destDayData[dataKey].splice(0, 1, itemId)
    else destDayData[dataKey].splice(destIndex, 0, itemId);
    let updatedState = updateDayInWeekInData(localState, srcWeekKey, srcDayKey, srcDayData);
    updatedState = updateDayInWeekInData(updatedState, destWeekKey, destDayKey, destDayData);
    return updateLocalState(updatedState);
  };
  
  const handleCopy = useCallback(({ type, source, itemId, sourceIndex }) => {
    return (e) => {
      e.stopPropagation();
      const { data } = _.cloneDeep(localState);
      if(type === 'individualItem'){
        return updateCopyState({ type, source, toPaste: itemId, copyMode: true, sourceIndex });
      };
      const [weekKey] = source.split('.');
      const toPaste = _.get(data, type === 'week' ? weekKey : source, {});
      updateCopyState({ type, source, itemId, toPaste });
    };
  }, [localState]);

  const handlePaste = useCallback(({ dest }) => {
    return (e) => {
      setDirty(true)
      e.stopPropagation();
      const { toPaste, type, source } = copyState;
      const [weekKey, dayKey] = dest.split('.');
      let updatedData = _.cloneDeep(localState);
      const dataKey = getDataKey(scheduleType);
      if(type === 'individualItem'){
        const dayData = _.get(localState.data, `${weekKey}.${dayKey}`, {[dataKey]: [] });
        if(dayData[dataKey].includes(toPaste)){
          // if that id is already in dest day - do nothing
          return enqueueSnackbar(
            `${scheduleType === 'workout' ? 'Workout' : 'Meal'} already present in this day`,
            {variant: 'warning'}
          );
        };
        const newDayData = addIdsToDay([toPaste], dayData, scheduleType);
        updatedData = updateDayInWeekInData({...localState}, weekKey, dayKey, newDayData);
      };
      if(type === 'day'){
        updatedData = updateDayInWeekInData({...localState}, weekKey, dayKey, toPaste);
      };
      if(type === 'week'){
        updatedData = updateWeekInData({...localState}, weekKey, toPaste);
        if(isWeekTitleEditable){
          const sourceWeekTitle = _.get(localState, `week_titles.${source}`);
          if(sourceWeekTitle){
            updatedData = update(updatedData, {
              week_titles:{
                [weekKey]:{
                  $set:sourceWeekTitle
                }
              }
            })
          }
        }
      };
      updateLocalState(updatedData);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [copyState, localState, scheduleType]);
  
  const closeCopyMode = (e) => {e.stopPropagation();updateCopyState(false);};

  const handleDeleteItem = useCallback((e, { type, source, sourceIndex, weekIndex }) => {
    if(!!e) e.stopPropagation();
    setDirty(true);
    if(type === 'individualItem'){
      if(!source) return;
      const [weekKey, dayKey] = source.split('.');
      let dayData  = _.get({...(_.cloneDeep(localState).data)}, source, {});
      const dayIds = _.get(dayData, getDataKey(scheduleType), []);
      const itemId = dayIds[sourceIndex];
      if(!dayIds.length) return;
      dayIds.splice(sourceIndex, 1);
      dayData[getDataKey(scheduleType)] = [...dayIds];
      let updatedState = updateDayInWeekInData(_.cloneDeep(localState), weekKey, dayKey, dayData);
      if (_.get(updatedState, [weekKey, dayKey, itemId])) {
        updatedState = update(updatedState, {
          extra: {
            [weekKey]: {
              [dayKey]: {
                $unset: [itemId]
              }
            }
          }
        });
      }
      // Delete from meta if needed
      const _ids = getAllIDsToFetch(updatedState, scheduleType);
        if(!_ids.includes(itemId)){
          let _itemsMeta = _.cloneDeep(itemsMeta);
          _itemsMeta[itemId] = firebase.firestore.FieldValue.delete();
          setItemsMeta(_itemsMeta);
        };
      updateLocalState(updatedState);
    };
    if(type === 'week'){
      let lastWeek = Object.keys(localState.data).length;
      let newData = _.cloneDeep(localState.data);
      let newTitles = _.cloneDeep(localState.week_titles || {});
      Array.from(Array(lastWeek+1).keys()).forEach((i)=> {
        if(i === 0) return;
        if(i > weekIndex){
          newData[`w${i-1}`] = {...localState.data[`w${i}`]};
          if(isWeekTitleEditable){
            newTitles[`w${i-1}`] = _.get(localState.week_titles, `w${i}`, null)
          }
        };
      });
      newData[`w${lastWeek}`] = getWoMlEmptyWeek(scheduleType);
      let updatedState = _.cloneDeep({...localState, data: { ...newData } });
      if(isWeekTitleEditable){
        newTitles[`w${lastWeek}`] = null;
        updatedState.week_titles = newTitles;
      }

      // Delete from meta if needed
      const scheduleKey = scheduleType === "workout" ? "wo" : "ml"; // actual item key of data like in workout it is wo, and in meal schedule it is ml:
      const _ids = getAllIDsToFetch(updatedState, scheduleType);
        const weekData = _.get(localState.data, `w${weekIndex}`, {});
        let _itemsMeta = _.cloneDeep(itemsMeta);
        Object.keys(weekData).forEach((dayKey) => {
          const dayData = _.get(weekData, `${dayKey}.${scheduleKey}`, scheduleType === "workout" ? { wo: []} :{ ml: []});
          dayData.forEach((itemId) => {
            if(itemId === "rest") return;
            if(!_ids.includes(itemId)){
              _itemsMeta[itemId] = firebase.firestore.FieldValue.delete();
            }
          });
        });
        setItemsMeta(_itemsMeta);
      updateLocalState(updatedState);
    };
  }, [itemsMeta, localState, scheduleType]);

  const sortWeeks = (arr=[]) => {
    return (arr||[]).sort((a, b) => {
      a=a.slice(1);
      b=b.slice(1);
      return a - b;
    });
  }

  const addWeek = async() => {
    // Add fake week and not change duration
    setDirty(true);
    const  { data, duration } = localState;
    const _weeksFromDuration = Math.ceil(duration / 7);
    const newDuration = 7 + 7 * _weeksFromDuration;
    if(!!data[`w${_weeksFromDuration+1}`]){
      // Data is already there for the next week only increase the duration and fetch differed wo/ml
      const days = Object.keys(_.get(data,[`w${_weeksFromDuration+1}`],{}));
      let idsToFetch = [];
      days.forEach((dayKey => {
        const ids = _.get(data, [`w${_weeksFromDuration+1}`, dayKey, getDataKey(scheduleType)], []);
        ids
        .forEach(_id => _id.split('/')
        .forEach((__id) => __id !== 'rest' && !fetchedItems[__id] && idsToFetch.push(__id)));
      }));
      if(idsToFetch.length === 0) return updateLocalState(prev => ({...prev, duration: duration + 7 }));
      showLoader();
      const { data: _resData = { } }  = await bffGetCatalogByIDs({cid, [scheduleType+'s']: [ ...idsToFetch ]});
      if(!_resData.success) throw _resData;
      const _items = {};
      _.get(_resData, scheduleType+'s', []).forEach((itemData) => {
          _items[itemData._id] = { ...itemData };
      });
      updateFetchedItems(prev => ({...prev, ..._items }));
      hideLoader();
      return updateLocalState(prev => ({...prev, duration: duration + 7 }));
    };
    const _weeks = sortWeeks(Object.keys(data));
    const lastWeek = Number(_weeks[_weeks.length - 1].replace('w',''));
    const updatedState = updateWeekInData(localState, `w${lastWeek+1}`, getWoMlEmptyWeek(scheduleType));
    const itemsMeta = generateMetaMap(updatedState, scheduleType, fetchedItems);
    setItemsMeta(itemsMeta);
    updateLocalState({...updatedState, duration: newDuration, durationType: 'weeks' });
  };

  const handleReorder = (list) => {
    setDirty(true);
    let newData = _.cloneDeep(localState.data );
    let newTitles = _.cloneDeep(localState.week_titles || {});
    list.forEach((item, i) => {
      const { name } = item;
      const newIndex = Number(name.split(' ').pop());
      const oldIndex = i + 1;
      if(oldIndex === newIndex) return;
      newData[`w${oldIndex}`] = _.get(_.cloneDeep(localState.data), `w${newIndex}` , { [getDataKey(scheduleType)]: {} });
      if(isWeekTitleEditable){
        const oldWeekTitle = _.get(localState, `week_titles.w${newIndex}`, {});
        newTitles[`w${oldIndex}`] = oldWeekTitle;  
      }
    });
    updateLocalState(prev =>{
      const out = { ...prev, data: _.cloneDeep(newData) }
      if(isWeekTitleEditable) out.week_titles= _.cloneDeep(newTitles)
      return out;
    });
  }

  const handleScheduleImport = (doc) => {
    if(!doc || !doc[0] || !doc[0]._id) return;
    showLoader();
    setDirty(true);
    fetchScheduleDoc(doc[0]._id, cid,draftFeature, scheduleType).then(async(_doc) => {
      if(!_doc.exists) return;
        const data = { ..._doc.data() };
        if(data.duration>localState.duration){
          data['data']=trimScheduleData(data.data,localState.duration);
      }
      //-----
      let allIds = getAllIDsToFetch(data, scheduleType);
      allIds = allIds.filter((o) => o !== 'rest' || !fetchedItems[o]);
      if(!allIds.length || !allIds.length)
        return updateLocalState(prev => ({ ...prev, data: _.cloneDeep(data.data) }));
      const items = await getWoMlFirebase(cid, allIds, { type: scheduleType });
      updateFetchedItems(prev => ({ ...prev, ...items }));
      updateLocalState(prev => ({ ...prev, data: data.data }));
      toggleScheduleImport(false);
    })
    .catch((err) => {
      enqueueSnackbar(DEFAULT_ERROR, { variant: "error" });
      Sentry.captureException(err);
      console.error(err);
    }).finally(() => hideLoader());
  };

  const handleAlternateSave = (id) => {
    setDirty(true);
    const { sourceIndex, source } = alternateDialog;
    const updatedState = _.cloneDeep(localState);
    const dataKey = getDataKey(scheduleType);
    let srcDayData = _.get((updatedState.data || {}), source, { [dataKey]: [] });
    let dayIds = srcDayData[dataKey];
    dayIds.splice(sourceIndex, 1 ,id);
    srcDayData[dataKey] = dayIds;
    const data = updateDayInWeekInData(localState, source.split('.')[0], source.split('.')[1], srcDayData);
    updateLocalState(data);
  };

  const handleWOTags = ({id, tag, weekIndex, dayIndex}) => {
    setDirty(true);
    const weekKey = `w${weekIndex}`;
    const dayKey = `d${dayIndex}`;
    const woTagsOptions = comp.data().app_config?.tags?.wotags || {};
    const selectedTag = woTagsOptions[tag];
    const newTags = selectedTag ? [tag] : [];
    
    const newLocalState = update(localState, {
      extra: (e) => update(e || {}, {
        [weekKey]: (wk) => update(wk || {}, {
          [dayKey]: (dk) => update(dk || {}, {
            [id]: (wi) => update(wi || {}, {
              w_opt: {
                $set: selectedTag?.w_opt || false
              },
              tags: {
                $set: newTags
              }
            })
          })
        })
      })
    })

    updateLocalState(newLocalState)
  }
  
  const closeConfirm = () => {setErrors(false);};

  const convertToRest = () => {
    setDirty(true);
    let out = _.cloneDeep(localState || {});
    let weekKeys = Object.keys(errors || {});
    
    weekKeys.forEach((weekKey, i) => {
      const weekData = _.get(errors, weekKey, []);
      Object.keys((weekData || {})).forEach((dayKey) => {
        out = update(out, {
          data: {
            [weekKey]: (weekData) => (
              update(weekData || {}, {
                [dayKey]: (dayData) => (
                  update(dayData || {}, {
                    [getDataKey(scheduleType)]: {
                      $set: ['rest']
                    },
                    tags: {
                      $set: []
                    },
                    w_opt: {
                      $set: false
                    }
                  })
                )
              })
            )
          }
        })
      });
    });
    updateLocalState(out);
    enqueueSnackbar("All empty days have been converted to rest days", { variant: "default" });
    setErrors(false);
  };

  const onTitleEdit = (e, {weekIndex}) =>{
    setSelectedWTitleIndex(weekIndex);
  }

  const setWeekTitle = (weekIndex, data, showWeekIndexInTitle ,cb) =>{
    const out = update(localState, {
      week_titles:(week_titles)=> update(week_titles || {}, {
          [`w${weekIndex}`] : {
            $set:data
          } 
        }),
        wk_show_w_title : {
          $set: showWeekIndexInTitle
        }
    })
    setDirty(true);
    updateLocalState(out);
    cb && cb();
  }

  const renderWeekReorderOption = useCallback((option)=>{
    const { index:wIndex } = option;
    const wKTitle = getWeekTitle(localState.wk_show_w_title, wIndex, _.get(localState, `week_titles.w${wIndex}`));
    return (
      <Typography className="font_16_500 text-0d0d0d">
        {wKTitle.primary}
        {wKTitle.secondary && <span className="font_13_500 text-dark-grey ml-10">{wKTitle.secondary}</span>}
      </Typography>
    );
  },[localState])

  const weeksToRender = useMemo(() => {
    const { data, duration } = localState || {};
    if(!data) return Array.from(Array(1).keys());;
    const _weeksFromDuration = Math.ceil(duration / 7);
    return Array.from(Array(_weeksFromDuration).keys());
  }, [localState]);
  const handleClose=()=>{
    setDirty(false);
    onClose();
  }
  const [start, end] = selectedRange;
  return (
    <Dialog 
      open={open}
      onClose={handleClose}
      withConfirmation
      showCustomConfirmation={showCustomConfirmation && !isScheduleEmpty}
      isDirty={isDirty ||isScheduleEmpty}
      fullScreen
      isLoading
      toolbarClass="height-40"
      appBarColor="bg-white"
      paperClass='bg-light-grey'
      actionText="Save"
      onSave={handleDone}
      handleDraft={()=>handleSave(DRAFT,true)}
      handlePublish={()=>handleSave(PUBLISHED,true)}
      confirmationProps={{
        msg:isScheduleEmpty?EMPTY_SCH_DISCARD_MSG:DISCARD_MSG
      }}
      title={
        <Title
          doc={doc}
          toggleOrigDocPopup={()=>toggleOrigDoc(o=>!o)}
          viewOrigDoc={viewOrigDoc}
          isDraftAvailable={isDraftAvailable}
        />
      }
      additionalActions={
        <ClrdButton
          className={clsx(
            "f-medium  mr-10",
            // !!exExist && "border-primary",
            !draftFeature && 'd-none'
          )}
          onClick={() => handleSave(DRAFT,false)}
          disableElevation
          color={"invert"}
          variant="contained"
        >
          <SaveIcon
            className={"mr-5"}
            color="primary"
            // color={!exExist ? "inherit" : "primary"}
          />
          Save
        </ClrdButton>
      }
    >
      <HeaderActions
        setDirty={setDirty}
        localState={{ ...localState, meta: itemsMeta }} // attach state meta
        updateLocalState={updateLocalState}
        setItemsMeta={setItemsMeta}
        toggleReorderDialog={toggleReorderDialog}
        scheduleType={scheduleType}
        totalWeeks={(weeksToRender || []).length}
        toggleScheduleImport={toggleScheduleImport}
        fetchedItems={fetchedItems}
        selectedRange={selectedRange}
        updateSelectedRange={updateSelectedRange}
        updateFetchedItems={updateFetchedItems}
        modifySchDuration={modifySchDuration}
        showImportBtn={showImportBtn}
    />
      <DragDropContext onDragEnd={onDragEnd}>
        <div className='w-100 bg-light-grey fp-20'>
          {weeksToRender.map((x, i) => {
            if((i+1) < start || (i+1) > end) return null;;
            const weekData = _.get((localState.data || {}), `w${i+1}`, false);
            const weekTitle = _.get(localState, `week_titles.w${i + 1}`, null);
              return (
                <ScheduleWeekCard
                  onCopy={handleCopy({ source: `w${i+1}`, type: "week" })}
                  onPaste={handlePaste({ dest: `w${i+1}`, type: "week" })}
                  closeCopyMode={closeCopyMode}
                  isCopied={!!copyState && (copyState.type === 'week' && copyState.source === `w${i+1}`)}
                  isPasteMode={!!copyState && (copyState.type === 'week' && copyState.source !== `w${i+1}`)}
                  doc={localState}
                  scheduleDuration={localState.duration}
                  onDelete={handleDeleteItem}
                  weekIndex={i+1}
                  key={`w${i+1}`}
                  onTitleEdit={onTitleEdit}
                  weekTitle={weekTitle}
                  isWeekTitleEditable={isWeekTitleEditable}
                  showWeekIndexInTitle = {localState.wk_show_w_title}
                  modifySchDuration={modifySchDuration}
                >
                  <div
                    className='d-flex align-items-stretch fpl-12 fpr-5 fpt-6 h-100 position-relative fpb-15'
                    style={{ overflowY: 'hidden', overflowX:'scroll' }}
                  >
                    {Array.from(Array(7).keys()).map((y, j)=> !(((i * 7) + j+1) > localState.duration) && (
                      <Droppable droppableId={`w${i+1}.d${j+1}`} key={`w${i+1}.d${j+1}`}>
                        {(provided) => {
                          return (
                            <div ref={provided.innerRef} style={{flex:1,minWidth:'calc( 172px + 24px )'}} {...provided.droppableProps}>  
                              <ScheduleDayContainer
                                onCopy={handleCopy}
                                onPaste={handlePaste}
                                copyState={copyState}
                                onTagDelete={deleteTag}
                                closeCopyMode={closeCopyMode}
                                onDeleteItem={handleDeleteItem}
                                doc={localState}
                                fetchedItems={fetchedItems}
                                dayData={weekData[`d${j + 1}`]}
                                dayIndex={j + 1}
                                weekIndex={i + 1}
                                dataKey={getDataKey(scheduleType)}
                                openTagDialog={() =>
                                  setDayTagsDialog({
                                    dayData: weekData[`d${j + 1}`] || {},
                                    weekIndex: i + 1,
                                    dayIndex: j + 1,
                                  })
                                }
                                openWoTagsDialog={({ tags, id }) => setWoTagsDialog({
                                  weekIndex: i + 1,
                                  dayIndex: j + 1,
                                  tags,
                                  id
                                })}
                                onAddClick={(e) => toggleSelector(e, {weekIndex: i+1 , dayIndex: j + 1})}
                                onSwitchChange={handleDayChange}
                                toggleAlternateDialog={toggleAlternateDialog}
                                toggleDrawer={({...params}) => toggleDrawer({...params, updateLocalState, localState, setDirty })}
                                scheduleType={scheduleType}
                              />
                              {provided.placeholder}
                            </div>
                          );
                        }}
                      </Droppable>
                    ))}
                  </div>
                </ScheduleWeekCard>
              );
          })}

          {/* Add Week Long button */}
          {!!modifySchDuration && <ClrdButton color='invert' classes={{root: 'f-xxxlarge' }} className='shadow-none border' onClick={addWeek}>
            <div className='d-flex align-items-center'>
              <span>
                <Add classes={{ root: 'small' }} />
              </span>
              <Typography variant='h6'  className='text-primary'>
                Add Week
              </Typography>
            </div>
          </ClrdButton>}
        </div>
      </DragDropContext>

      {/* Reorder Dialog */}
      {reorderDialog && <ReorderDialog
        open={true}
        listData={localState && Array.from(Array(Math.ceil(localState.duration / 7)).keys()).map((i) => ({ name: `Week ${i+1}`, index: i + 1 }))}
        onClose={() => toggleReorderDialog(false)}
        onSubmit={(list) => handleReorder(list)}
        title="Reorder Weeks"
        noTextFiled={true}
        renderOption={renderWeekReorderOption}
      />}

      {/* Import Schedule Dialog */}
      <ScheduleSelector
        open={importScheduleDialog}
        onClose={() => toggleScheduleImport(false)}
        handleAdd={handleScheduleImport}
        importType={scheduleType === 'workout' ? 'woImport' : 'mlSchedules'}
      />

      {/* Workout Selectors */}
      <WorkoutSelector
        open={!!selectorState && (selectorState.type === 'workout_select')}
        onClose={toggleSelector}
        handleAdd={addItemsToDay}
        selectedWOs={selectorState &&
          [..._.get(localState.data, `w${selectorState.weekIndex}.d${selectorState.dayIndex}.${getDataKey(scheduleType)}`, []
        )]
        .reduce((prev, curr) => curr.includes("/") ? prev= [...prev, ...curr.split("/")] : prev = [...prev, curr], [])
        .map((id) => fetchedItems[id.split('/')[0]])}
        showDraftInd={true}
      />

      {/* Nutrition Selectors */}
      <MealSelector
        open={!!selectorState && (selectorState.type !== 'workout_select')}
        onClose={toggleSelector}
        handleAdd={addItemsToDay}
        selectedMls={selectorState &&
          _.get(localState.data, `w${selectorState.weekIndex}.d${selectorState.dayIndex}.${getDataKey(scheduleType)}`, []
        ).map((id) => fetchedItems[id])}
      />

      {/* Alternate Workout Dialog */}
      {scheduleType === 'workout' &&!!alternateDialog && 
        <AlternateWoDialog 
          open={!!alternateDialog}
          onSave={handleAlternateSave}
          onClose={() => toggleAlternateDialog(false)} 
          ids={_.get(alternateDialog, 'itemId', '')}
          dayIds={_.get(alternateDialog, 'dayIds', [])}
          fetchedItems={fetchedItems}
          updateFetchedItems={updateFetchedItems}
          toggleDrawer={({...params}) =>{
            toggleDrawer({...params, 
              updateLocalState, 
              localState, 
              setDirty,
              dayIndex:alternateDialog.dayIndex,
              weekIndex:alternateDialog.weekIndex,
              woIndex:alternateDialog.woIndex,
              //altIndex- already provided by ...params
             })
          }}
        />
      }
      <Confirmation
        handleClose={closeConfirm}
        open={!!errors}
        title={"Empty Day Alert"}
        msg="Looks like you have added one or more empty days. 
        A workout schedule cannot have empty days. Do you want to mark them as Rest days?"
        confirmOption="Mark as Rest Days"
        handleChange={convertToRest}
        handleCancel={closeConfirm}
      />
      {isCustomConfrmtn && (
        <CustomConfirmation
            handleClose={() => toggleCustomConfrmtn(false)}
            handleDiscard={handleClose}
            handleDraft={()=>handleSave(DRAFT,true)}
            handlePublish={()=>handleSave(PUBLISHED,true)}
            dirty={isDirty}
            disableBtns={!!errors}
            showDiscard={isDirty}
            msg={isScheduleEmpty && EMPTY_SCH_DISCARD_MSG}
        />
        )}
      {viewOrigDoc && (
        <PublishedDrawer onClose={()=>toggleOrigDoc(false)} keyName={VIEWS_CONSTANTS.SCHEDULE} scheduleType={scheduleType}/>
      )}
      {selectedWTitleIndex !== null && 
        <EditTitleDialog
          open={true} 
          weekIndex={selectedWTitleIndex} 
          onClose={closeEditTitleDialog} 
          data={_.get(localState, `week_titles.w${selectedWTitleIndex}`, null)} 
          onSave={setWeekTitle} 
          showWeekIndexInTitle = {localState.wk_show_w_title}
          />
      }
      {Boolean(dayTagsDialog) && (
          <TagsDialog dayData={dayTagsDialog.dayData} onSubmit={handleTagsChange} onClose={() =>setDayTagsDialog(false)} />
      )}
      {Boolean(woTagsDialog) && (
        <WoTagsDialog 
          onSubmit={handleWOTags}
          onClose={() => setWoTagsDialog(false)}
          initialTags={woTagsDialog.tags}
          {...woTagsDialog} 
        />
      )}
    </Dialog>
  )
};
export default WoMlScheduleEditor;
