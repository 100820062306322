import { useCallback, useState, useEffect } from 'react';
import firebase from 'fitbud/firebase';

const NONE = 'none';

const usePreviewLink = (cid) => {
  const [link, setLink] = useState('');
  const [primary, setPrimary] = useState('');
  const [aliasType, setAliasType] = useState(NONE); // none / subdomain / custom

  const reload = useCallback(() => {
    if (!cid) return;
    setLink(cid + '.fitbudd.com');
    setPrimary(cid + '.fitbudd.com');
    setAliasType(NONE);

    firebase.firestore().collection('dns').where('cid', '==', cid).get().then(snapShot => {
      if (!snapShot || snapShot.empty) return;

      let idWithDot = '';
      let idWithoutDot = '';

      snapShot.docs.forEach((doc) => {
        const id = (doc && doc.id) || '';
        if (!!id.includes('.') && !idWithDot) {
          idWithDot = id;
          if (doc.data().override) setPrimary(id);
        } else if (!idWithoutDot) {
          idWithoutDot = id;
          if (doc.data().override) setPrimary(`${id}.fitbudd.com`);
        }
      });

      let _link = '', _type = NONE;
      if (idWithDot) {
        //if any ids preset with . then consider it is link.
        _link = idWithDot;
        _type = 'custom';
      } else if (idWithoutDot) {
        //if ids . is not present then  add fitbudd domain
        _link = `${idWithoutDot}.fitbudd.com`;
        _type = 'subdomain';
      } else {
        _link = `${cid}.fitbudd.com`; //if no any ids found then use cid with fitbudd.com
      }
      setLink(_link);
      setAliasType(_type);
    });
  }, [cid]);

  useEffect(() => {
    reload();
  }, [reload]);

  return [link, primary, aliasType, reload];
}

export default usePreviewLink;
