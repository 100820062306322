import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const DotIcon = (props) => (
  <SvgIcon
    {...props}
    style={{
      width: '5px',
      height: '5px',
      ...props.style
    }}
    viewBox="0 0 5 5"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <svg width="5" height="5" viewBox="0 0 5 5" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="5" height="5" rx="2.5" fill="currentColor" />
    </svg>
  </SvgIcon>
);
