import { SvgIcon } from '@material-ui/core';
import React from 'react';

export default function LinkIndicatorIcon(props) {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 18 18"
      classes={{
        root: 'small',
      }}
    >
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.8667 10.1646C8.19271 10.6423 8.60864 11.0376 9.08627 11.3237C9.56391 11.6097 10.0921 11.7798 10.635 11.8224C11.1778 11.8651 11.7227 11.7792 12.2327 11.5707C12.7426 11.3622 13.2057 11.0359 13.5905 10.6139L15.8679 8.11752C16.5593 7.33281 16.9419 6.28182 16.9332 5.19091C16.9246 4.1 16.5254 3.05645 15.8217 2.28503C15.1179 1.51361 14.1659 1.07604 13.1707 1.06656C12.1755 1.05708 11.2167 1.47645 10.5009 2.23435L9.19517 3.6573"
          stroke="#0D0D0D"
          stroke-width="1.98333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10.1333 7.83648C9.80731 7.35873 9.39138 6.96342 8.91374 6.67737C8.43611 6.39132 7.90793 6.22122 7.36505 6.1786C6.82217 6.13598 6.27727 6.22184 5.76732 6.43036C5.25737 6.63888 4.7943 6.96518 4.40951 7.38713L2.13213 9.88352C1.44072 10.6682 1.05815 11.7192 1.06679 12.8101C1.07544 13.901 1.47462 14.9446 2.17836 15.716C2.88211 16.4874 3.8341 16.925 4.8293 16.9345C5.8245 16.944 6.78329 16.5246 7.49915 15.7667L8.79726 14.3437"
          stroke="#0D0D0D"
          stroke-width="1.98333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </SvgIcon>
  );
}
