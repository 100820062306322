import { Card, IconButton, makeStyles, Menu, MenuItem, Typography } from '@material-ui/core';
import { LayersOutlined, MoreVert } from '@material-ui/icons';
import clsx from 'clsx';
import { ClrdButton } from 'fitbud/components/form-fields';
import EditedCardIcon from 'fitbud/icons/editedCardIcon';
import { FirebaseAuthContext } from 'fitbud/providers/firebase-auth';
import _ from 'lodash';
import React, { useContext, useMemo } from 'react';
import { cardClrCSS, getWorkoutIntensityIcon } from '../users/subComponents';
import { CopyOverlay } from './subComponents';
import {DurationToHMSComp} from "fitbud/views/workouts/helperfn";

export const scheduleCardStyles = makeStyles((theme) => ({
  card: {
    minWidth: ({ minified, minWidth }) => minWidth ? minWidth : minified ? 160 : 170,
    border: 'none',
    borderRadius: '3px',
    boxShadow: 'none',
    maxWidth:400,
    padding:'0px!important',
  },
  wtf: {
    height: ({ minified }) => minified ? 72 : 80,
  },
  typeStyling: ({id, type}) => cardClrCSS(id, type !== 'workout'),
  options: { top: 0, right: 0 },
}));

const MENU_OPTIONS = [
  {
    label: 'Edit',
    value: 'edit',
  },
  {
    label: 'Copy',
    value: 'copy',
  },
  {
    label: 'Delete',
    value: 'delete',
  },
]

function generateWOMenuOptions({isAltWoEnabled, alternateCount, showWoTags, tagsCount = 0}) {
  const options = [...MENU_OPTIONS];

  if(isAltWoEnabled) {
    if(alternateCount > 1) {
      options.push({
        label: 'Manage alternatives',
        value: 'manage_alternatives',
      })
    } else {
      options.push({
        label: 'Add alternatives',
        value: 'add_alternatives',
      })
    }
  }

  if(showWoTags) {
    if(tagsCount > 0) {
      options.push({
        label: 'Manage tag',
        value: 'optional',
      })
    } else {
      options.push({
        label: 'Add tag',
        value: 'optional',
      })
    }
  }

  return options
}

const WorkoutCard = ({ woData, id, itemId, toggleAlternateDialog, minWidth, isPrimary, toggleDrawer,
  editable = true, className, minified, onDelete, onCopy, closeCopyMode, isCopied,
  selectedTags = [], openWoTagsDialog }) => {
  let { ref_name, duration = false, intensity, is_wo_edited } = woData || {};
  const { comp } = useContext(FirebaseAuthContext);
  const isAltWoEnabled = _.get((comp.data() || {}), "app_config.tags.altwo", false);
  const woTags = comp.data().app_config.tags?.wotags || {};
  const showWoTags = !_.isEmpty(woTags);
  const tagLabel = _.get(woTags, [selectedTags[0], 'value'], false);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const alternateCount = itemId.split('/').length;
  const tagsCount = (selectedTags || []).length;
  const open = Boolean(anchorEl);

  const menuOptions = useMemo(() => {
    return generateWOMenuOptions({
      isAltWoEnabled,
      alternateCount,
      showWoTags,
      tagsCount,
    })
  }, [isAltWoEnabled, alternateCount, tagsCount, showWoTags]);

  const handleMoreClick = e => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };
  
  const handleOptionClick = (e, action) => {
    e.preventDefault();
    e.stopPropagation();
    if(action === 'edit'){
      handleClose(e);
      handleClick(e);
    }
    if (action === 'delete') {
      handleClose(e);
      onDelete();
    }
    if (action === 'copy') {
      handleClose(e);
      return onCopy(e);
    }
    if (action.match('alternat')) {
      handleClose(e);
      return toggleAlternateDialog();
    }
    if(action === 'optional') {
      handleClose(e);
      return openWoTagsDialog({tags: selectedTags, id: itemId});
    }
  };

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation()
    if(isAltWoEnabled && editable && alternateCount > 1) toggleAlternateDialog(e);
    else toggleDrawer();
  };

  const classes = scheduleCardStyles({ id: alternateCount > 1 ? itemId.split('/').shift() : itemId, minified, minWidth, type: 'workout' });
  return (
    <Card
      onClick={handleClick}
      className={clsx(classes.card, classes.wtf, classes.typeStyling, 'position-relative shadow-none cursor-pointer flex-1', className)}
      id={id} classes={{ root: "border-none" }}>
      <CopyOverlay isCopied={isCopied} type="individualItem" onClose={closeCopyMode} />
      {editable && (
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{ style: { minWidth: 130, boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.1)", borderRadius: '8px' } }}
        >
          {menuOptions.map(({label, value}, i) => (
            <MenuItem
              key={value}
              onClick={e => handleOptionClick(e, value)}
              className="text-capitalize"
            >
              {label}
            </MenuItem>
          ))}
        </Menu>
      )}
      {is_wo_edited && (
        <EditedCardIcon
          style={{
            position: "absolute",
            left: 0,
            top: 0,
          }}
        />
      )}
      <div className='h-100 d-flex w-100 flex-column fpx-10 fpy-10 '>
        <div className='flex-1'>
          <Typography className='font_13_600' style={{ maxWidth: '90%' }} noWrap>
            {ref_name}
          </Typography>
          {tagLabel && (
            <Typography className={clsx('text-uppercase text-grey mt-4px', editable ? 'font_11_600' : 'font_10_600')}>
              {tagLabel}
            </Typography>
          )}
        </div>
        <div className='d-flex flex-1 w-100 align-items-end'>
          {isAltWoEnabled && alternateCount > 1 ? editable ? (
            <div className='d-flex align-items-end'>
              <Typography className='font_11_500 text-dark-grey'>
                +{alternateCount - 1}&nbsp;Alternative{alternateCount > 2 ? 's' : ''}&nbsp;
              </Typography>
              <LayersOutlined classes={{root: 'x-small'}} />
            </div>
          ) : (
            <ClrdButton onClick={toggleAlternateDialog} className='d-flex align-items-center p-0'>
              <Typography className='font_11_500 text-dark-grey '>
                +{alternateCount - 1}&nbsp;Alternative{alternateCount > 2 ? 's' : ''}&nbsp;
              </Typography>
              <LayersOutlined classes={{root: 'x-small'}} />
            </ClrdButton>
          ) : (
            <div className={'d-flex w-100 h-100 align-items-end'}>
              <Typography className='font_11_500 text-dark-grey '>
                <DurationToHMSComp duration={duration}/>
              </Typography>
              {editable && <div className="fml-10" style={{ marginBottom: '2px' }}>
                {intensity && getWorkoutIntensityIcon(intensity)}
              </div>}
              <div className='flex-grow-1'/>
              {isPrimary && <Typography className='font_11_600 text-uppercase text-grey mr-10'>
                Primary
              </Typography>}
            </div>
          )}
          {!!editable && (
            <div className={clsx('position-absolute', classes.options)}>
              <IconButton onClick={handleMoreClick} size='small'>
                <MoreVert className='text-dark-grey'/>
              </IconButton>
            </div>
          )}
        </div>
      </div>
    </Card>
  );
};

export default WorkoutCard;
