import { Divider, IconButton, makeStyles, Switch, Typography } from '@material-ui/core';
import { KeyboardArrowLeftRounded, KeyboardArrowRightRounded, PlayArrowRounded } from '@material-ui/icons';
import DetailEditIconNew from 'fitbud/icons/detailEdit';
import React, { useEffect, useRef, useState } from 'react';
import firebase from 'fitbud/firebase';
import PinnedMessageDialog, { MAX_DATE, TIME_FORMAT } from './pinnedMessageDialog';
import clsx from 'clsx';
import { DotIcon } from 'fitbud/icons/dotIcon';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import appRdxFns from 'fitbud/redux/app';
import { useSnackbar } from 'notistack';
import { DEFAULT_ERROR } from 'fitbud/utils/constants';
import PinIcon from 'fitbud/icons/pinIcon';
import FileIcon from 'fitbud/icons/fileIcon';
import LinkIndicatorIcon from 'fitbud/icons/linkIndicatorIcon';

const useStyles = makeStyles((theme) => ({
  navIconRoot: {
    height: '15px',
  },
  indicator: {
    position: 'absolute',
    bottom: -10,
    left: '50%',
    transform: 'translateX(-50%)',
    display: 'flex',
    columnGap: 5,
  },
  msgTypeIndicator: {
    borderRadius: 20,
    height: 35,
    width: 35,
    backgroundColor: '#DADCDE',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default function PinnedMessagesContainer({ groupId, isDialogOpen, setIsDialogOpen }) {
  const [pinnedMsgs, setPinnedMsgs] = useState([]);
  const [currentMsgIndex, setCurrentMsgIndex] = useState(0);

  const selectedMsg = useRef();
  const classes = useStyles();

  function openDialog(data) {
    setIsDialogOpen(true);
    selectedMsg.current = data || undefined;
  }

  function closeDialog() {
    setIsDialogOpen(false);
    selectedMsg.current = undefined;
  }

  function nextMessage() {
    // If current message is the last message, then go to the first message
    setCurrentMsgIndex((prev) => (prev + 1) % pinnedMsgs.length);
  }

  function prevMessage() {
    // If current message is the first message, then go to the last message
    setCurrentMsgIndex((prev) => (prev - 1 + pinnedMsgs.length) % pinnedMsgs.length);
  }

  function handleSubmit(pinnedMsgs) {
    setPinnedMsgs(pinnedMsgs);
    setCurrentMsgIndex(pinnedMsgs.length - 1);
  }

  async function handleActiveChange({ id, active }) {
    const groupChatsRef = firebase.firestore().doc(`groupChats/${groupId}`);
    const pinnedMsgRef = firebase.firestore().doc(`groupChats/${groupId}/pins/${id}`);

    const newPinnedMsgs = pinnedMsgs.map((msg) => {
      if (msg.id === id) {
        return {
          ...msg,
          active,
        };
      }
      return msg;
    });

    const batch = firebase.firestore().batch();
    batch.update(pinnedMsgRef, { active });
    batch.update(groupChatsRef, { pinned: newPinnedMsgs, _muat: firebase.firestore.FieldValue.serverTimestamp() });
    await batch.commit();

    setPinnedMsgs(newPinnedMsgs);
  }

  useEffect(() => {
    async function fetchPinnedMessages() {
      try {
        const doc = await firebase.firestore().doc(`groupChats/${groupId}`).get();
        const pinnedMsgs = doc.data().pinned || [];
        const filteredPinnedMsgs = pinnedMsgs.filter((msg) => {
          const till = moment(msg._till, TIME_FORMAT);
          const now = moment();

          return till.isAfter(now);
        });
        setPinnedMsgs(filteredPinnedMsgs);
      } catch (error) {
        console.log('Error in fetchPinnedMessages', error);
      }
    }

    fetchPinnedMessages();
  }, [groupId]);

  return (
    <>
      {pinnedMsgs.length > 0 && (
        <div className="d-flex bg-offWhite py-15 px-20">
          <div className="flex-1 d-flex align-items-center position-relative">
            <div className={clsx('d-flex, flex-1')}>
              <PinnedMessage
                key={pinnedMsgs[currentMsgIndex]?.id || 'empty'}
                className="flex-1"
                data={pinnedMsgs[currentMsgIndex]}
                onEdit={() => openDialog(pinnedMsgs[currentMsgIndex])}
                onActiveChange={handleActiveChange}
              />
            </div>
            {pinnedMsgs.length > 1 && (
              <div className={classes.indicator}>
                {[...Array(pinnedMsgs.length).keys()].map((m, i) => (
                  <DotIcon key={i} style={{ color: currentMsgIndex === i ? '#0D0D0D' : '#C3C5C7' }} />
                ))}
              </div>
            )}
          </div>
          {pinnedMsgs.length > 1 && (
            <>
              <Divider orientation="vertical" className="mx-10" />
              <div className="d-flex align-items-center" style={{ minHeight: 42.44 }}>
                <IconButton className="p-2px" onClick={prevMessage}>
                  <KeyboardArrowLeftRounded style={{ fontSize: 34 }} />
                </IconButton>
                <IconButton className="p-2px" onClick={nextMessage}>
                  <KeyboardArrowRightRounded style={{ fontSize: 34 }} />
                </IconButton>
              </div>
            </>
          )}
        </div>
      )}

      {isDialogOpen && (
        <PinnedMessageDialog
          defaultData={selectedMsg.current}
          isNew={!selectedMsg.current}
          onSubmit={handleSubmit}
          onClose={closeDialog}
          groupId={groupId}
          pinnedMsgs={pinnedMsgs}
        />
      )}
    </>
  );
}

function PinnedMessage({ className, data, onActiveChange, onEdit, groupId }) {
  const [active, setActive] = useState(data.active || false);
  const dispatch = useDispatch();
  const { showLoader, hideLoader } = appRdxFns(dispatch);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const { _from, _till, title, dtype } = data;

  const from = moment(_from, 'YYYY-MM-DD HH:mm:ss').format('DD MMM YYYY, hh:mm a');
  const till = moment(_till, 'YYYY-MM-DD HH:mm:ss').format('DD MMM YYYY, hh:mm a');

  async function handleActiveChange(e) {
    const checked = e.target.checked;
    showLoader();
    try {
      await onActiveChange({ id: data.id, active: checked });

      setActive(checked);
      hideLoader();
    } catch (error) {
      console.log('Error in handleActiveChange', error);
      enqueueSnackbar(DEFAULT_ERROR, { variant: 'error' });
      hideLoader();
    }
  }

  return (
    <div className={clsx(className, 'd-flex align-items-center')}>
      <div className={classes.msgTypeIndicator}>
        {dtype === 'video' && <PlayArrowRounded />}
        {dtype === 'text' && <PinIcon />}
        {dtype === 'document' && <FileIcon />}
        {dtype === 'link' && <LinkIndicatorIcon />}
      </div>
      <div className="mx-15 flex-1">
        <Typography className="font_15_500 mb-8 text-truncate">{title}</Typography>
        <div className="d-flex align-items-center font_13_500">
          <Switch color="primary" className="ml-n8 my-n12" checked={active} onChange={handleActiveChange} />
          <Typography className="font_13_500 text-grey">
            Active • {from} {_till === MAX_DATE ? 'onwards' : ` - ${till}`}
          </Typography>
        </div>
      </div>
      <IconButton onClick={onEdit}>
        <DetailEditIconNew />
      </IconButton>
    </div>
  );
}
