import _set from 'lodash/set';
import React, { useState, useEffect, useContext } from 'react';
import firebase from 'fitbud/firebase';
import { Typography } from '@material-ui/core';
import NoSchedule from 'fitbud/images/no_schedule.svg';
import { FETCH_AGGRS } from './constants';
import { UserSchContext } from './provider';
import WeekView from './week-view';
import UserTracker from './tracker';

const UserSchViewer = ({saveSchAggrs}) => {
  const { aplan, editing } = useContext(UserSchContext);
  const [aggrs, setAggrs] = useState(null);
  
  useEffect(() => {
    if (!aplan || aggrs) return;
    const ref = firebase.firestore().collection(`plans/${aplan}/aggregate`);
    Promise.all(FETCH_AGGRS.map(x => ref.doc(x).get()))
      .then((docs) => {
        const out = {};
        FETCH_AGGRS.forEach((key, n) => {
          const doc = docs[n];
          out[key] = decipherAggrObj(doc.data());
        });
        setAggrs(out);
        if(saveSchAggrs) saveSchAggrs(out);
      }).catch(e => {
        setAggrs({}); // view can render w/o the aggr as well O.o
      });
  }, [aggrs, aplan]);

  if (!aplan) {
    return (
      <div className='d-flex flex-column justify-content-center align-items-center w-100 h-100'>
        <img src={NoSchedule} alt='no schedule' />
        <Typography className='font_20_500 fmt-30'>
          No Schedule Assigned
        </Typography>
      </div>
    )
  }

  if (editing) return null;

  return <>
    <WeekView aggrs={aggrs} />
    <UserTracker aggrs={aggrs}/>
  </>;
};

const decipherAggrObj = (data) => {
  if (!data) return {};
  const keys = Object.keys(data);
  const out = {};
  keys.forEach(key => {
    const parts = key.split(':');
    _set(out, parts, data[key]);
  });
  return out;
};

export default UserSchViewer;
