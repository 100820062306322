import {
  Checkbox,
  FormControlLabel, MenuItem, OutlinedInput, Radio, RadioGroup, Select, TextField, Typography
} from "@material-ui/core";
import DropDownIcon from "@material-ui/icons/ExpandMore";
import { Alert } from "@material-ui/lab";
import clsx from "clsx";
import {CustomConfirmation} from "fitbud/components/customConfirmation";
import {AltWo} from "fitbud/views/workouts/components";
import Dialog from "fitbud/components/Dialog";
import { ClrdButton, FormTextField } from "fitbud/components/form-fields";
import ImageFileUpload from "fitbud/components/imageSelector";
import { FirebaseAuthContext } from "fitbud/providers/firebase-auth";
import _ from "lodash";
import React, { useContext, useEffect, useMemo, useState, useRef } from "react";
import { TagsContext } from 'fitbud/providers/tagsProvider';
import { protectNumberInputs, roundMacros } from "fitbud/utils/helpers";
import MinMaxField from "fitbud/components/minMaxField";
import AutoAwesomeIcon from '@material-ui/icons/Assistant';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';

const targetAreas = [
  { name: 'Full Body', value: 'full_body' },
  { name: 'Upper Body', value: 'upper_body' },
  { name: 'Lower Body', value: 'lower_body' },
  { name: 'Core', value: 'core' },
];

const injuries = [
  { name: 'Knee', value: 'knee' },
  { name: 'Back', value: 'back' },
  { name: 'Shoulder', value: 'shoulder' },
  { name: 'Hip', value: 'hip' },
  { name: 'Ankle', value: 'ankle' },
  { name: 'Wrist', value: 'wrist' },
  { name: 'Neck', value: 'neck' },
  { name: 'Elbow', value: 'elbow' },
  { name: 'Other', value: 'other' },
];

const workout_types = [
  { name: 'AMRAP', value: 'AMRAP' },
  { name: 'Yoga', value: 'Yoga' },
  { name: 'Triset', value: 'Triset' },
  { name: 'Circuit', value: 'Circuit' },
  { name: 'Superset', value: 'Superset' },
  { name: 'Emom', value: 'emom' },
  { name: 'TABATA', value: 'TABATA' },
  { name: 'HIIT', value: 'HIIT' },
];

const WorkoutAIForm = ({ readonly = false, doc = {}, errors = {}, loading, onSubmit = () => {}, handleClose = () => {} }) => {

  const [aiForm, setAiForm] = useState({});

  useEffect(() => {
    setAiForm({...doc});
  }, [doc]);
  
  const handleChangeWrapper = (e, newValue) => {
    let { name, value } = e.target;
    if (e.target.type === 'checkbox') {
      value = newValue;
    }
    if (e.target.role === 'option' ) {
      name = e.target.id.split('-option-')[0];
      value = newValue;
      e.target = { name, value };
    }
    if (name) setAiForm(s => ({...s, [name]: value }));
  };

  const onGenerate = () => {
    console.info('Generate ai form workout', aiForm);
   // call ai service with aiForm
    if (!readonly) onSubmit(aiForm);
  };

  return <Dialog
    toolbarClass="height-60"
    title="Generate Workout with Fitbot" 
    open
    onClose={handleClose}
    buttonColor="primary"
    paperClass={"width-600"}
    actionText={readonly ? "Close" : "Generate"}
    onSave={readonly ? handleClose : onGenerate}
    titleFont="h3"
  >
    {/** Generate code with following inputs and the arrangement
      *    * workout location home/gym      * intensity - single select
      *    * duration - single select       * age group range selection
      *    * target areas/muscles multi-select
      *    * injuries multi-select
      *    * additional notes textbox
     **/}
    <div className="fp-20">

      <Typography variant="h5">Please fill in the following details to generate a workout</Typography>
      <div className={`mt-20 mb-20 d-flex`}>
        <FormTextField
          fullWidth
          label="Workout Location"
          classes={{ control: "fmr-20" }}
        >
          <Select
            fullWidth
            IconComponent={DropDownIcon}
            input={
              <OutlinedInput
                classes={{
                  root: "medium",
                  input: "size_16_500 select-medium",
                }}
                name="workout_location"
              />
            }
            value={aiForm.workout_location}
            onChange={handleChangeWrapper}
            disabled={loading}
            required
          >
            <MenuItem value="home">Home</MenuItem>
            <MenuItem value="gym">Gym</MenuItem>
          </Select>
        </FormTextField>
        <FormTextField
          fullWidth
          label="Intensity"
          classes={{ control: "mb-0" }}
        >
          <Select
            displayEmpty
            fullWidth
            IconComponent={DropDownIcon}
            input={
              <OutlinedInput
                classes={{
                  root: "medium",
                  input: "size_16_500 select-medium",
                }}
                name="intensity"
              />
            }
            value={aiForm.intensity}
            onChange={handleChangeWrapper}
            disabled={loading}
            required
          >
            <MenuItem value="low">Low</MenuItem>
            <MenuItem value="moderate">Moderate</MenuItem>
            <MenuItem value="high">High</MenuItem>
          </Select>
        </FormTextField>
      </div>

      <div className={`mb-20 d-flex`}>
        <FormTextField
          fullWidth
          label="Duration"
          classes={{ control: "fmr-20" }}
        >
          <Select
            fullWidth
            IconComponent={DropDownIcon}
            input={
              <OutlinedInput
                classes={{
                  root: "medium",
                  input: "size_16_500 select-medium",
                }}
                name="duration"
              />
            }
            value={aiForm.duration}
            onChange={handleChangeWrapper}
            disabled={loading}
            required
          >
            <MenuItem value="30">30 min</MenuItem>
            <MenuItem value="45">45 min</MenuItem>
            <MenuItem value="60">60 min</MenuItem>
            <MenuItem value="90">90 min</MenuItem>
            <MenuItem value="120">120 min</MenuItem>
          </Select>
        </FormTextField>
        <FormTextField
          fullWidth
          label="Age Group"
          classes={{ control: "mb-0" }}
        >
          <Select
            fullWidth
            IconComponent={DropDownIcon}
            input={
              <OutlinedInput
                classes={{
                  root: "medium",
                  input: "size_16_500 select-medium",
                }}
                name="age_group"
              />
            }
            value={aiForm.age_group}
            onChange={handleChangeWrapper}
            disabled={loading}
            required
          >
            <MenuItem value="18-30">18-30</MenuItem>
            <MenuItem value="30-45">30-45</MenuItem>
            <MenuItem value="45-60">45-60</MenuItem>
            <MenuItem value="60+">60+</MenuItem>
          </Select>
        </FormTextField>
      </div>

      <FormTextField
        fullWidth
        label="Target Areas/Muscles"
        classes={{ control: "mb-20" }}
      >
        <Autocomplete
          multiple
          id="target_area"
          name="target_area"
          options={targetAreas}
          defaultValue={aiForm.target_area || []}
          getOptionLabel={(option) => option.name}
          onChange={handleChangeWrapper}
          disabled={loading}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip style={{ borderRadius: '50px'}} label={option.name} {...getTagProps({ index })} />
            ))
          }
          renderInput={(params) => (
            <TextField {...params} name="target_area" variant="outlined" label="" placeholder="Target Areas" />
          )}
        />
      </FormTextField>
      <FormTextField
        fullWidth
        label="Workout type"
        classes={{ control: "mb-20" }}
      >
        <Autocomplete
          multiple
          id="mainWorkout"
          name="mainWorkout"
          options={workout_types}
          defaultValue={aiForm.mainWorkout || []}
          getOptionLabel={(option) => option.name}
          onChange={handleChangeWrapper}
          disabled={loading}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip style={{ borderRadius: '50px'}} label={option.name} {...getTagProps({ index })} />
            ))
          }
          renderInput={(params) => (
            <TextField {...params} name="mainWorkout" variant="outlined" label="" placeholder="Workout Type" />
          )}
        />
      </FormTextField>
      <FormTextField
        fullWidth
        label="Injuries"
        classes={{ control: "mb-20" }}
      >
        <Autocomplete
          multiple
          id="injuries"
          name="injuries"
          options={injuries}
          defaultValue={aiForm.injuries || []}
          getOptionLabel={(option) => option.name}
          onChange={handleChangeWrapper}
          disabled={loading}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip style={{ borderRadius: '50px'}} label={option.name} {...getTagProps({ index })} />
            ))
          }
          renderInput={(params) => (
            <TextField {...params} name="injuries" variant="outlined" label="" placeholder="Injuries" />
          )}
        />
      </FormTextField>
      <FormTextField
        fullWidth
        label="Additional Notes"
        classes={{ control: "mb-20" }}
      >
        <TextField
          multiline
          rows="2"
          rowsMax="10"
          id="additional_notes"
          name="note"
          fullWidth
          onChange={handleChangeWrapper}
          value={aiForm.note}
          error={!!errors.additional_notes}
          helperText={errors.additional_notes}
          variant="outlined"
          disabled={loading}
          required
          InputProps={{
            classes: {
              root: "large",
              input: "size_16_500",
            },
          }}
        />
      </FormTextField>
      <FormControlLabel
        className="fmb-0 fmr-30 fpr-20"
        control={
          <Checkbox
            name={'excludeWarmup'}
            color="primary"
            checked={!!aiForm.excludeWarmup}
            onChange={handleChangeWrapper}
            value={!!aiForm.excludeWarmup}
            disabled={false}
          />
        }
        label={<Typography className={`font_14_600`}>Exclude warm-up</Typography>}
      />
      <FormControlLabel
        className="fmb-0 fmr-30 fpr-20"
        control={
          <Checkbox
            name={'excludeCooldown'}
            color="primary"
            checked={!!aiForm.excludeCooldown}
            onChange={handleChangeWrapper}
            value={!!aiForm.excludeCooldown}
            disabled={false}
          />
        }
        label={<Typography className={`font_14_600`}>Exclude Cooldown</Typography>}
      />
    </div>
  </Dialog>;
};


export default WorkoutAIForm;