import React from 'react';
import { Typography, TextField } from '@material-ui/core';
import Dialog from 'fitbud/components/Dialog';
import { FormTextField } from 'fitbud/components/form-fields';

const BroadCastNameDialog = (props) => {
  const { onSave, onClose, meta, loading, onChange, infoText, errors, actionText } = props;
  const { name } = meta || {};
  return (
    <Dialog
      toolbarClass="height-60"
      buttonColor="primary"
      open
      onClose={onClose}
      onSave={onSave}
      titleFont="h3"
      paperClass="width-400"
      title={'Broadcast Name'}
      actionText={actionText}>
      <div className="fp-20">
        <FormTextField fullWidth>
          <TextField
            id="name"
            autoComplete="off"
            placeholder="Enter Group Name"
            value={name}
            onChange={onChange}
            error={errors?.name}
            helperText={errors?.name}
            variant="outlined"
            disabled={loading}
            required
            type="text"
            InputProps={{
              classes: {
                root: 'medium',
                input: 'size_16_500 medium',
              },
            }}
          />
        </FormTextField>
        {!!infoText && <Typography variant="body2">{infoText}</Typography>}
      </div>
    </Dialog>
  );
};

export default BroadCastNameDialog;
