import React,{useState,useRef,useEffect} from 'react';
import {Helmet} from 'react-helmet';
import { makeStyles, Link, TextField, Typography, LinearProgress } from '@material-ui/core';
import GTM from 'fitbud/utils/gtm';
import { FormTextField } from 'fitbud/components/form-fields';
import { useSnackbar } from 'notistack';
import {
  ClrdButton,
  PasswordField,
  isValidEmail,
} from 'fitbud/components/form-fields';
import {removeSpace} from 'fitbud/utils/helpers';
import {bffUpdateHubspot, signup} from 'fitbud/api';
import ReCAPTCHA from 'react-google-recaptcha';
import moment from 'moment-timezone';
import {get} from 'lodash';

const PasswordMinLength=6;

const GTMevent = ({action, category, label}) => {
  const eventDims = {};
  if (label) eventDims.event_label = label;
  if (category) eventDims.event_category = category;
  if (typeof window === 'undefined') return;
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({event: action, ...eventDims});
};

const styles=makeStyles(theme=>({
  container:{
    height:'100vh',
    display:'grid',
    gridTemplateColumns:'1fr 40%',
    '& .grecaptcha-badge':{
      zIndex: 1000,
    },
    '& .logo':{
      height: 60,
      top: 0,
      left: 0,
      width: '60%',
      zIndex: 9,
      paddingLeft: 30,
    },
    '& form':{
      position:'relative',
      left:'50%',
      transform:'translate(-50%)',
      maxWidth:'460px'
    },
    '& .recaptcha-spacing':{
      marginBottom:'60px'
    },
    [theme.breakpoints.down('sm')]:{
      gridTemplateColumns:'1fr',
      '& .logo':{
        width: '100%',
      },
    },
    [theme.breakpoints.down('xs')]:{
      '& .logo':{
        paddingLeft: 20,
      },
      '& .grecaptcha-badge':{
        right: '-250px !important',
        '&:hover': {
          right: '0px !important',
        },
      },
    },
  },
  label:{
    color:'#06132D',
    fontSize: '12px!important',
  },
}));
const Signup=({ authUser })=>{
  const { enqueueSnackbar } = useSnackbar();
  const classes=styles();
  const recaptchaRef=useRef();
  const [state,updateState]=useState({});
  const [errors,updateErrors]=useState({});
  const [loading,toggleLoading]=useState(false);
  const [redir, setRedir]=useState(false);
  useEffect(() => {
    try {
      // https://inbound.fitbudd.com/meeting-confirmed?assigned_to=Jeffrey+Alilam&event_type_uuid=ABIGI5AE5RRC73BI&event_type_name=FitBudd+Demo+Meeting&event_start_time=2024-12-13T03%3A00%3A00%2B05%3A30&event_end_time=2024-12-13T03%3A45%3A00%2B05%3A30&invitee_uuid=13540e35-b1bd-4be8-b1bb-25c4c37ef059&invitee_first_name=&invitee_last_name=&invitee_full_name=test+ashish&invitee_email=ashish.jaju52%40fitbudd.com&text_reminder_number=&answer_1=
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const email = urlParams.get('invitee_email');
      const name = urlParams.get('invitee_full_name');
      if (email) updateState(x => ({...x, email: email.toLowerCase().trim()}));
      if (name) updateState(x => ({...x, fullName: name.trim()}));
    } catch {
      /* nothing to do */
    }
  }, []);
  const labelProps={
    classes:{
      labelTxt:classes.label
    }
  }
  const onBlur=(e)=>{
    let {name,value}=e.target;
    if (name === 'email') callHubspot(false);
    if (value) {
      GTMevent({
        action: 'sign_up_input',
        category: 'sign_up_form',
        label: name,
      });
    }
  }
  const handleChange=(e)=>{
    let {name,value}=e.target;
    const newVal={[name]:value};
    if(name==='password' || name==='instagram_id'){
      newVal[name]=removeSpace(value);
    }
    if(name==='email'){
      newVal['email']=value.toLowerCase();
    }
    updateState(o=>({
      ...o,
      ...newVal
    }));
  }
  const clientValidation=async()=>{
    const errors={};
    //required fields:
    const {fullName,email,password}=state;
    //fullName
    if(!fullName || (fullName && !fullName.trim())){
      errors['fullName']='Name is missing'
    }
    //email
    if(!email || (email && !email.trim())){
      errors['email']='Email is missing'
    }
    else if(!isValidEmail(email.trim())){
      errors['email']='Please provide a valid email';
    }
    //password
    if(!password || (password && !password.trim())){
      errors['password']='Password is missing';
    }
    else if(state.password.length<PasswordMinLength){
      errors['password']='Please select a stronger password'
    }
    updateErrors(errors);
    return !!Object.keys(errors).length;
  }
  const handleSubmit=async(e)=>{
    GTMevent({ action: 'sign_up_submit', category: 'sign_up_form', label: 'click' });
    e.preventDefault();
    const flag=await clientValidation();
    if (flag) {
      GTMevent({ action: 'sign_up_submit', category: 'sign_up_form', label: 'invalid' });
      return
    };
    try{
      const recaptcha = await recaptchaRef.current.executeAsync();
      const email = state.email.trim();
      const isTest = email.endsWith('@appstreet.io') ||
        email.endsWith('@iappstreet.com') ||
        email.endsWith('@fitbudd.com') ||
        email.endsWith('@yopmail.com');
      const name = state.fullName.trim();
      const payload=({
        email, trainer_name: name,
        company_name: name,
        // instagram_id :state.instagram_id ?state.instagram_id.trim():'',
        password:state.password.trim(),
        time_zone:moment.tz.guess(),
        recaptcha
      });
      toggleLoading(true);
      GTMevent({ action: 'sign_up_submit', category: 'sign_up_form', label: 'start' });
      const resp = await signup(payload);
      GTMevent({ action: 'sign_up_submit', category: 'sign_up_form', label: 'success' });
      const {token, cid} = resp.data;
      await callHubspot(true);
      setRedir(`/welcome?ref=${isTest ? 'test_' : ''}${cid}&token=${token}`);
    } catch(err){
      const msg = get(err,'response.data.message');
      GTMevent({ action: 'sign_up_submit', category: 'sign_up_form', label: `fail: ${msg}` });
      toggleLoading(false);
      recaptchaRef.current.reset();
      console.log(err);
      enqueueSnackbar(msg, {variant:'error'})
    }
  }
  const callHubspot = (completed = false) => {
    const { fullName, email, instagram_id } = state;
    if(!email || !email.trim() || !isValidEmail(email.trim())) return;
    let hbProps = { email, source_self_signup: true };
    if(!!fullName && !!fullName.trim()) hbProps['name'] = fullName;
    if(!!instagram_id && !!instagram_id.trim()) hbProps['instagram_handle'] = instagram_id;
    if(!!completed) hbProps.signup_complete = true;
    return bffUpdateHubspot({ ...hbProps,  }, true);
  };

  if (authUser || redir) { // already logged in
    return (
    <div className='d-flex vh-100 align-items-center'>
      <Helmet>
        <title>FitBudd » Sign Up</title>
        <script type='text/javascript' id='hs-script-loader' async defer src='//js-na1.hs-scripts.com/9058640.js'></script>
      </Helmet>
      <GTM/>
      <div className='position-relative overflow-y-auto overflow-x-hidden'>
        <Typography className='mb-20'>
          Yay! Your FitBudd account is ready. Let's go to your FitBudd Dashboard.
        </Typography>
        <Link href={redir || '/'} target='_blank'>
          <ClrdButton color='main' className='f-xxxlarge' classes={{label:'font_16_600 font-weight-700'}}>
            Let's Go
          </ClrdButton>
        </Link>
      </div>
    </div>
    );
  }

  return(
    <div className={`${classes.container}`}>
      <Helmet>
        <title>FitBudd » Sign Up</title>
        <script type='text/javascript' id='hs-script-loader' async defer src='//js-na1.hs-scripts.com/9058640.js'></script>
      </Helmet>
      <GTM/>
      {loading && <LinearProgress className='position-absolute w-100' style={{zIndex:9}}/>}
      <div className='position-relative overflow-y-auto overflow-x-hidden'>
        <form id='portal-signup' className='text-center d-flex flex-column justify-content-center' onSubmit={handleSubmit}>
          <FormTextField fullWidth label='Full Name' required {...labelProps}>
            <TextField
            name='fullName'
            required
            value={state.fullName}
            onChange={handleChange}
            onBlur={onBlur}
            variant='outlined'
            error={errors['fullName']}
            helperText={errors['fullName']}
            InputProps={{
              classes: {
                root: 'medium',
                input: 'size_16_500 h-100 bg-white',
              },
            }}
            disabled={loading}
          />
            </FormTextField>
            <FormTextField fullWidth label='Email ID' required {...labelProps}>
              <TextField
              name='email'
              required
              value={state.email}
              onChange={handleChange}
              variant='outlined'
              error={errors['email']}
              helperText={errors['email']}
              InputProps={{
                classes: {
                  root: 'medium',
                  input: 'size_16_500 h-100 bg-white',
                },
              }}
              onBlur={onBlur}
              disabled={loading}
            />
              </FormTextField>
              <FormTextField fullWidth label='Set Password' required {...labelProps}>
                <PasswordField
                name='password'
                required
                value={state.password}
                onChange={handleChange}
                onBlur={onBlur}
                variant='outlined'
                error={errors['password']}
                helperText={errors['password']}
                disabled={loading}
                autoComplete='new-password'
                rootClassName='bg-white'
                inputClassName='bg-white'
              />
                </FormTextField>
                <ClrdButton color='main' type='submit' className='f-xxxlarge mb-20' disabled={loading}
                classes={{label:'font_16_600 font-weight-700'}}>
                Create Account
              </ClrdButton>
            </form>
            <ReCAPTCHA
            size='invisible' badge='bottomright' theme='dark'
            sitekey={process.env.REACT_APP_RECAPTCHA_CLIENT_KEY}
            ref={recaptchaRef}
          />
            </div>
          </div>
  )
}

export default Signup;
