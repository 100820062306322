const { useMemo } = require('react');
const { useLocation } = require('react-router-dom/cjs/react-router-dom.min');

const useQuery = () => {
  const { search } = useLocation();
  const query = useMemo(() => {
    return new URLSearchParams(search);
  }, [search]);
  return query;
};

export default useQuery;
