import _ from 'lodash';
import React, { useContext, useState, useRef, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { withSnackbar } from 'notistack';
import * as Sentry from '@sentry/browser';
import {
  makeStyles, Button,
} from '@material-ui/core';
import { DEFAULT_ERROR, DEFAULT_VID_UPLOAD_ERROR, FILE_STATUS, COMMON_ANALYTICS_PROPS, AMPLITUDE_PROPS } from 'fitbud/utils/constants';
import { createVidObject } from 'fitbud/api';
import plansRepo from 'fitbud/repo/plans';
import planRdxFns from 'fitbud/redux/plans';
import appRdxFns from 'fitbud/redux/app';
import fileUploadRdxFns from "fitbud/redux/fileUpload";
import Dialog from 'fitbud/components/Dialog';
import Confirmation from 'fitbud/components/confirmationDialog';
import { useToggle } from 'fitbud/hooks/form';
import { parsePack } from '../helper';
import { PlanContext } from '../planProvider';
import Stepper from './stepper';
import BasicEditor from './basic';
import PriceEditor from './pricing';
import VidCallsEditor from './calling';
import PointsEditor from './points';
import firebase from 'fitbud/firebase';
import { AnalyticsContext } from "fitbud/providers/analytics";
import update from 'immutability-helper'
import { AccessContext } from 'fitbud/providers/access-provider';

export const REGULAR_STEPS ={
  BASIC: 0,
  PRICING:1,
  VIDEO_CALL : 2,
  INCLUDES:3,
  HOW_IT_WORKS : 4
};
export const ADD_ON_STEPS = {
  BASIC: 0,
  PRICING:2,
  VIDEO_CALL : 1,
  INCLUDES:3,
  HOW_IT_WORKS : 4
};
export const REGULAR_STEPS_LABELS = ["Description", "Pricing Info", "Appointments Info" , "Plan Includes", "How it Works"];
export const ADD_ON_STEPS_LABELS = ["Description",  "Appointments Info","Pricing Info" ,"Plan Includes", "How it Works"];
export const DIALOG_REGULAR_TITLES = ["Description", "Pricing Info", "Manage Appointments", "Plan Includes", "How it Works"];
export const DIALOG_ADD_ON_TITLES = ["Description","Manage Appointments" ,"Pricing Info", "Plan Includes", "How it Works"];;


const Editor = (props) => {
  const {
    id = 'new', plans,
    data = {},
    mode, price, iap, play,
    onClose, onSave,
    showLoader, hideLoader,
    enqueueSnackbar,
    memoizeGcs,
    updateAccess,
    access,
    videoStatusRefresh,
  } = props;
  const history = useHistory();
  const {saveAccessChanges} = useContext(AccessContext);
  const { cid, packFlags, parseSinglePackForIapIds } = useContext(PlanContext);
  const [dirty, setDirty] = useState(false);
  const [state, setState] = useState(data);
  const [step, setStep] = useState(mode || 0);
  const [saving, setSaving] = useState(false);
  const [confirm, toggleConfirm, showConfirm] = useToggle(false);
  const fnRef = useRef(null);
  const isNew = (id === 'new');
  const wizard = (typeof mode === 'undefined');
  const css = useStyles({ wizard });
  const STEPS = useMemo(()=>{
    if(_.get(state, "type") === "add_on") return ADD_ON_STEPS;
    else return REGULAR_STEPS;
  },[state])

  const STEPS_LABELS = useMemo(()=>{
    if(_.get(state, "type") === "add_on") return ADD_ON_STEPS_LABELS;
    else return REGULAR_STEPS_LABELS;
  },[state])

  const DIALOG_TITLES = useMemo(()=>{
    if(_.get(state, "type") === "add_on") return DIALOG_ADD_ON_TITLES;
    else return DIALOG_REGULAR_TITLES;
  },[state])
  
  const { trackEvent } = useContext(AnalyticsContext);
  
  const close = (warnIfDirty = true) => {
    if (warnIfDirty && dirty) {
      if (confirm) showConfirm(false);
      else return showConfirm(true);
    }
    if (onClose) onClose();
    else {
      if(history.length<=1)
        history.replace("/plans");
      else
      history.goBack();}
  }

  const skip = () => {
    if (step < LAST_STEP) setStep(x => (x + 1));
  }

  const compareDirty = (changes) => {
    if (!changes) return;
    const keys = Object.keys(changes);
    const curr = _.pick(state, keys);

    const currMedia = curr.media;
    const changedMedia = changes.media;

    // Check if media has changed. omit duration as it sets after video loads.
    const hasMediaChanged = !_.isEqualWith(currMedia, changedMedia, (a, b) => {
      if(_.isArray(a) && _.isArray(b)){
        const result = a.every((v, i) => _.isEqual(_.omit(v, ["duration"]), _.omit(b[i], ["duration"])));
        return result
      }
    });

    //Omit null or undefined values from both source and comparisons.
    setDirty(current => {
      const isChanged = !_.isEqual(
        _.chain(curr).omitBy(_.isUndefined).omitBy(_.isNull).omit(["media"]).value(), 
        _.chain(changes).omitBy(_.isUndefined).omitBy(_.isNull).omit(["media"]).value()
      ) || hasMediaChanged;

      if (isChanged) return true;
      return current;
    });
  };

    const uploadVideoToBunnyCDN = async ({ id, file, docName, ...videoObject }) => {
      const { uploadBunnyFile } = props;
      uploadBunnyFile({
        docId: id,
        cid: cid,
        file: file.url,
        collection: 'packs',
        media_bgoff: false,
        path: `/plans/${id}`,
        docName,
        videoObject,
      });
    };

    const createVideoObject = async ({ id, duration, title, resolution }) => {
      try {
        const collection = 'packs';
        const resp = await createVidObject({
          cid,
          docId: id,
          path: `companies/${cid}/${collection}/${id}`,
          collection,
          media_bgoff: false,
          video: {
            title,
            duration,
            resolution,
          },
        });
        return await resp.data;
      } catch (err) {
        enqueueSnackbar(_.get(err, 'response.data.message', DEFAULT_VID_UPLOAD_ERROR), { variant: 'error' });
        console.log('err', err);
        return null;
      }
    };

  const proceed = () => {
    if (!fnRef.current) return;
    if (!fnRef.current(setState)) return;
    if (!wizard || step === LAST_STEP) {
      setSaving(true);
    } else {
      setStep(x => (x + 1));
    }
  };

  const savePlan = async () => {
    if (!saving) return;
    setSaving(false);
    if (!dirty) return close(false);
    showLoader();
    let updatedData = _.omitBy(state, _.isUndefined);
    const isSubscription = (updatedData.price_opts || []).some((opt) => (opt && opt.mode) === 'subscription');

    const { media } = updatedData;
    let newMediaAvailable = !!_.get(media, '0.url.name', null);
    if (newMediaAvailable) {
      const firebaseId = isNew ? firebase.firestore().collection(`companies/${cid}/packs`).doc().id : id;
      //create video obj
      const resp = await createVideoObject({
        id: firebaseId,
        duration: _.get(media, '0.duration'),
        title: _.get(media, '0.url.name', ''),
        resolution: _.get(media, '0.height', ''),
      });
      if (!resp) {
        hideLoader();
        return;
      }
      newMediaAvailable = resp;
      updatedData = update(updatedData, {
        media: {
          $set: [
            {
              type: 'video',
              status: resp ? FILE_STATUS.uploading : FILE_STATUS.error,
              videoId: _.get(resp, 'videoId', ''),
            },
          ],
        },
      });
      if (isNew) {
        updatedData = update(updatedData, {
          docIdx: {
            $set: firebaseId,
          },
        });
      }
    } else {
      if (media && !!media[0] && ['youtube', 'vimeo'].includes(media[0].type) && media[0].url) {
        updatedData = update(updatedData, {
          media: {
            $set: [{ type: media[0].type, url: media[0].url, ...(media[0].error && { error: media[0].error }) }],
          },
        });
      }
    }

    (isNew
      ? plansRepo(cid).create({...updatedData, ...packFlags, index: plans.length}, enqueueSnackbar, !newMediaAvailable)
      : plansRepo(cid).update(id, updatedData, enqueueSnackbar))
      .then(async doc => {
        if(isNew || mode === STEPS.VIDEO_CALL || (mode === STEPS.PRICING && updatedData.add_on_type === 'one_to_one')) {
          await saveAccessChanges({ objId: doc.id });
        }
        hideLoader();
        if (!doc) return;
        if (newMediaAvailable) {
          //upload video in bunnycdn
          uploadVideoToBunnyCDN({
            id: doc.id,
            file: media[0],
            docName: doc.data().ref_name,
            ...newMediaAvailable,
          });
        }
        if (onSave) onSave(doc.data());
        parseSinglePackForIapIds(doc);
        if (isNew) {
          props.planOps.set(_.orderBy([...plans, parsePack(doc)], ['index'], ['asc']), 0, true);
          history.replace(`/plans/${doc.id}`);
          if(isSubscription) {
            trackEvent(COMMON_ANALYTICS_PROPS.PLAN_SUBSCRIPTION_CREATED);
          }
          else {
            trackEvent(COMMON_ANALYTICS_PROPS.PLAN_ONETIME_CREATED);
          }
        } else {
          props.planOps.update(parsePack(doc));
          close(false);
          if(isSubscription) {
            trackEvent({name: AMPLITUDE_PROPS.PLAN_SUBSCRIPTION_EDITED, tools: ["amplitude"]});
          }
          else {
            trackEvent({name: AMPLITUDE_PROPS.PLAN_ONETIME_EDITED, tools: ["amplitude"]});
          }
        } 
      }).catch(err => {
        hideLoader();
        enqueueSnackbar(DEFAULT_ERROR, { variant: 'error' });
        Sentry.captureException(err);
      });
  };

  useEffect(() => {
    savePlan()
  }, [saving]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Dialog open
    toolbarClass='height-60'
    buttonColor='primary'
    onClose={close}
    title={wizard ? (isNew ? 'Add Payment Plan' : 'Edit Payment Plan') : DIALOG_TITLES[mode]}
    titleFont='h3'
    paperClass={wizard ? 'width-780' : 'width-600'}
    actionText={wizard ? (step < LAST_STEP ? 'Next' : 'Save') : 'Save'}
    onSave={proceed}
    additionalActions={wizard && step > 0 && step < LAST_STEP && <Button
      color='primary' className='mr-1' onClick={skip}
    >Skip</Button>}>
      <div className={`d-flex h-100 overflow-auto flex-row ${css.root}`}>
        {wizard && <Stepper stepLabel={STEPS_LABELS} activeStep={step} setStep={setStep}/>}
        {step === STEPS.BASIC && <BasicEditor data={state} fnRef={fnRef} setDirty={compareDirty} isNew={isNew} videoStatusRefresh={videoStatusRefresh} isEditMode={!wizard} />}
        {step === STEPS.PRICING && <PriceEditor id={price || (wizard ? 'new' : 'add')} data={state} iap={iap} play={play} fnRef={fnRef} setDirty={compareDirty} />}
        {step === STEPS.VIDEO_CALL && <VidCallsEditor data={state} fnRef={fnRef} compareDirty={compareDirty} id={id} isNew={isNew} setDirty={setDirty} />}
        {step === STEPS.INCLUDES && <PointsEditor src='plan_includes' data={state} fnRef={fnRef} setDirty={compareDirty} />}
        {step === STEPS.HOW_IT_WORKS && <PointsEditor src='how_it_work' data={state} fnRef={fnRef} setDirty={setDirty} />}
      </div>
      {confirm && <Confirmation open
        handleChange={close}
        handleCancel={toggleConfirm}
      />}
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: props => (props.wizard ? 540 : 'unset'),
  },
}));

export const LAST_STEP = 4;

const mapStateToProps = s => {
  return {plans: _.get(s, 'plans.docs', [])};
};

const mapDispatchToProps = d => {
  const { showLoader, hideLoader } = appRdxFns(d);
  const planOps = planRdxFns(d);
  const { uploadBunnyFile } = fileUploadRdxFns(d);
  
  return {
    planOps, showLoader, hideLoader, uploadBunnyFile
  };
};

export default withSnackbar(
  connect(mapStateToProps, mapDispatchToProps)(Editor)
);
