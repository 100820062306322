import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import clsx from 'clsx';
import {
    InputBase,
    IconButton,
    InputAdornment,
    Typography,
    LinearProgress,
} from "@material-ui/core";
import Clear from "@material-ui/icons/CloseRounded";
import { makeStyles } from "@material-ui/core/styles";
import Paper from '@material-ui/core/Paper';
import Fade from '@material-ui/core/Fade';
import { UserListInner } from 'fitbud/views/users/list';
import useDebounce from 'fitbud/hooks/useDebounce';
import SearchPlcImg from "fitbud/images/searchListPlcholder.svg";
// import { ListInner } from "fitbud/components/listItems";
import { handleError } from "fitbud/utils/helpers";
import searchIcon from 'fitbud/images/searchIcon.svg';
import { NavIcon } from "fitbud/partials/appBar";

export const useStyles = makeStyles((t) => ({
    rootPaper: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 100,
        display: 'flex',
        flexDirection: 'column',
        maxHeight: '100vh',
        '& .header-container':{
            display:"flex",
            flexDirection:"column",
            position:"relative",
            padding:"0px 20px",
            background: '#F2F4F7',
            borderBottom: '2px solid #D8DCE0',
        },
        '& .topContainer':{
            display:"flex",
            alignItems:"center",
            justifyContent:"space-between",
            height:56
        },
        '& .actionBtn':{
             marginRight:"-8px",
            '& svg':{
                fill:"#37404d"//mui icon, using same color as custom icon
            }
        },
        '& .input':{
            borderRadius: '20px',
            height: 40,
            border: '1px solid #D3D9DB',
            background: '#fff',
            padding: '0px 10px',
            marginBottom:15,
            marginTop: ({ onTop }) => onTop  ? 0 : 5,
        }
    },
    contentPaper: {
        height: 'calc(100vh - 114px)'
    },
}));

export const PER_PAGE = 15;

export default function Search(props) {
    const { enqueueSnackbar } = useSnackbar();
    const { placeholder = '', queryFn, closeSearch, onSelect, searchPlaceholderText = '', title,
        selected, directSearchMode = false, keyName, listType, listProps } = props;
    const classes = useStyles({ onTop: !directSearchMode });
    const [docs, setDocs] = useState([]);
    const [query, setQuery] = useState("");
    const [count, setCount] = useState(null);
    const [nextPage, setNextPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [showCount, setShowCount] = useState(false);

    const handleChange = (e) => {
        setShowCount(false);
        setQuery(e.target.value);
        if(queryFn){
            queryFn({q:e.target.value})
        }
    }
    const debouncedSearchTerm = useDebounce(query, 500);

    const fetchPage = async (q = query, page = nextPage) => {
        setLoading(true);
        try {
            const res = await queryFn({ q, page, per_page: PER_PAGE })
            setDocs(docs => (page ? docs : []).concat(res.data))
            setNextPage(res.data.length === PER_PAGE ? page + 1 : null);
            if (!page) setCount(res.count);
            setShowCount(true);
        } catch (error) {
            handleError(error, enqueueSnackbar)
        }
        setLoading(false);
    }

    const handleScroll = _.debounce(e => {
        if (e.target.scrollTop + e.target.clientHeight >= (e.target.scrollHeight - 100)) {
            if (nextPage) fetchPage();
        }
    }, 500);

    useEffect(() => {
        if (query) fetchPage(query, 0)
        else {
            setDocs([])
            setShowCount(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearchTerm])

    const handleRefresh = () => {
        if (query) fetchPage(query, 0);
    }

    const isEmpty = !!query && !!debouncedSearchTerm && (query === debouncedSearchTerm) && !docs.length && !loading;
    return (
        <Paper className={classes.rootPaper} square elevation={0} onClick={e => e.stopPropagation()} onScroll={e => e.stopPropagation()}  >
            <div className="header-container">
                {!directSearchMode && <div className="topContainer">
                    <NavIcon/>
                    <Typography variant="h3" className="flex-grow-1 text-capitalize">
                        Search{" "}
                        {showCount && <span className="font-weight-500">{`(${count})`}</span>}
                    </Typography>
                    <IconButton
                        onClick={closeSearch}
                        className={'actionBtn'}
                    >
                        <Clear/>
                    </IconButton>
                </div>}
                <InputBase fullWidth value={query}
                    className={'input'}
                    onChange={handleChange}
                    startAdornment={
                        <InputAdornment position="start">
                            <img alt="search-icon" src={searchIcon}/>
                        </InputAdornment>
                    }
                    autoFocus
                    placeholder={placeholder}
                />
            </div>
            
            {!!loading && <LinearProgress className="position-absolute w-100 height-2" />}
            <Fade
                in
                {...({ timeout: 400 })}
            >
                <Paper id="searchList" className={clsx(classes.contentPaper, "overflow-auto")} elevation={0} square onScroll={e => { e.persist(); handleScroll(e) }}>
                    {!query && (
                        <div className="h-100 d-flex flex-column align-items-center justify-content-center">
                            <img alt="search-icon" src={searchIcon}/>
                            <Typography className="text-nowrap fmt-20">
                                {placeholder || searchPlaceholderText}
                            </Typography>
                        </div>
                    )}
                    {!!docs.length && query && <InnerList listType={listType} keyName={keyName} query={query} docs={docs} listProps={listProps} handleRefresh={handleRefresh} onSelect={onSelect} selected={selected} />}
                    {isEmpty && <Placeholder title={title} />}
                </Paper>
            </Fade>
        </Paper>
    )
}

const InnerList = ({ listType, keyName = 'browse', listProps, docs, query, handleRefresh, onSelect, selected }) => {
    if (listType === 'clients') return  <UserListInner {...listProps} keyName={keyName} query={query} docs={docs} handleRefresh={handleRefresh} onSelect={onSelect} selected={selected} showTooltip/>
    // return <ListInner {...listProps} keyName={keyName} query={query} docs={docs} handleRefresh={handleRefresh} />

}
export const Placeholder = ({ title }) => {
    return (
        <div className="d-flex flex-column justify-content-center align-items-center h-100 " >
            <img alt="no data" src={SearchPlcImg} className="fmb-20" />
            <Typography className="text-nowrap">No {title} found</Typography>
        </div>
    )
}
