import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const AddIcon = (props) => (
  <SvgIcon
    {...props}
    viewBox="0 0 16 16"
    classes={{
      root: 'small',
    }}
  >
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Frame 48096927" clip-path="url(#clip0_38680_181903)">
        <g id="Group 8" filter="url(#filter0_d_38680_181903)">
          <g id="Add">
            <path
              id="Stroke 1130"
              d="M1.59961 8H14.3996"
              stroke="#0D0D0D"
              stroke-width="1.36"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              id="Stroke 1131"
              d="M8 14.4016V1.60156"
              stroke="#0D0D0D"
              stroke-width="1.36"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_38680_181903"
          x="-6.28057"
          y="-3.87813"
          width="28.5602"
          height="28.5563"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2.4" />
          <feGaussianBlur stdDeviation="3.6" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_38680_181903" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_38680_181903" result="shape" />
        </filter>
        <clipPath id="clip0_38680_181903">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
);
