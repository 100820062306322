import React, { useState, useContext } from 'react';
import { FirebaseAuthContext } from 'fitbud/providers/firebase-auth';
import { useSnackbar } from 'notistack';
import Dialog from 'fitbud/components/Dialog';
import * as Sentry from '@sentry/browser';
import mealRepo from 'fitbud/repo/meals';
import MealInfo from './mealInfo';
import _ from 'lodash';
import { DEFAULT_ERROR, PUBLISHED, DRAFT_STATES } from 'fitbud/utils/constants';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { LinearPfc } from 'fitbud/views/foodRecipes/newUi/pfc';
import SelectorCatalogList from 'fitbud/components/catalog/selectorCatalogList';
import ArrowIcon from '@material-ui/icons/ArrowForward';

const useRowStyles = makeStyles(() => ({
  root: {
    padding: '15px 25px 15px 20px',
  },
  avatar: {
    borderRadius: '40px',
    height: 80,
    width: 80,

    marginRight: 20,
  },
  primary: {
    marginBottom: 8,
  },
}));

const ImportMealDialog = (props) => {
  const { open, handleClose, onSave, docId } = props;
  const [step, setStep] = useState(1);
  const { enqueueSnackbar } = useSnackbar();
  const { cid, comp} = useContext(FirebaseAuthContext);
  const draftFeature= !!comp.data().features.draft;
  const [selectedMealGroups, setSelectedMealGroups] = useState([]);
  const [selectedGroupImages, setSelectedGroupImages] = useState({});
  const [selectedMealId, setSelectedMealId] = useState(null);
  const isGroupImageAllowed = comp.data()?.features?.mlimgs;
  
  const fetchMeal = (mealId) => {
    return mealRepo(cid,draftFeature).doc(mealId);
  };

  const onClose = () => {
    step === 1 ? handleClose() : setStep(1);
    setSelectedMealId(null);
    setSelectedMealGroups([]);
  };

  const onMealSelect = async (doc) => {
    const isDraft=_.get(doc,'data.publish_status',PUBLISHED)===DRAFT_STATES['DRAFT_ONLY'];
    if(isDraft) {
      enqueueSnackbar("Meal Plan is yet to be published", {
        variant: "warning",
      });
      return
    }
    const mealId = doc._id;
    if (docId === mealId) {
      enqueueSnackbar(`Can't import from the current meal plan.`, { variant: 'error' });
      return;
    }
    try {
      const mealRef = await fetchMeal(mealId);
      const data = mealRef.data();
      if (!_.get(data, 'groups.length', 0)) {
        enqueueSnackbar(`Meal groups are empty for this plan.`, { variant: 'error' });
        return;
      }
      setSelectedMealId(mealId);
      setStep(2);
    } catch (err) {
      enqueueSnackbar(DEFAULT_ERROR, { variant: 'error' });
      Sentry.captureException(err);
    }
  };

  const handleSelectImportMealGroups = (meal, image) => {
    const _selectedMeals = [...selectedMealGroups];
    const _type = meal.type;
    const _index = _selectedMeals.findIndex((item) => item.type === _type);
    if (_index === -1) {
      _selectedMeals.push(meal);
    } else {
      _selectedMeals.splice(_index, 1);
    }
    if(isGroupImageAllowed) {
      setSelectedGroupImages({ ...selectedGroupImages, [_type]: image });
    }
    setSelectedMealGroups(_selectedMeals);
  };

  const checkSelectedMealGroup = (meal) => {
    const _type = meal.type;
    const _selectedMeals = [...selectedMealGroups];
    const _index = _selectedMeals.findIndex((item) => item.type === _type);
    return _index === -1 ? false : true;
  };

  const handleSave = () => {
    if(!selectedMealGroups || !selectedMealGroups.length){
      enqueueSnackbar(`Meals are required`, { variant: 'error' });
      return;
    }
    onSave && onSave({ mealGroups: selectedMealGroups, groupImages: selectedGroupImages, cb: handleClose });
  };

  //listSecondLine({ doc: l, keyName })
  const renderSecondLine = ({ doc }) => {
    const data = _.get(doc, 'data', {});
    const macros = _.get(data, 'macros');
    let _secondLine = null;
    if (macros) {
      _secondLine = (
        <LinearPfc
          className="font_13_500"
          data={macros}
        />
      );
    }
    return _secondLine;
  };

  return (
    <Dialog
      titleFont="h3"
      buttonColor="primary"
      fullWidth
      title={step === 1 ? 'Select Meal Plan' : 'Select Meals'}
      showBack={step === 2}
      open={open}
      onSave={step === 1 ? null : handleSave}
      actionText="Import"
      toolbarClass="height-60"
      paperClass="width-800 height-70"
      onClose={onClose}
      dialogContentClassName="d-flex flex-column"
      hideHeaderDivider={step === 1 }
    >
      <div className="position-relative d-flex flex-column h-100">
        {step === 1 ? (
          <SelectorCatalogList
            renderSecondLine={renderSecondLine}
            keyName={'foodMeals'}
            onClick={onMealSelect}
            listProps={{
              ListItemSecondaryAction: () => <span style={{marginRight:8}}><ArrowIcon /></span>,
            }}
            showDraftInd={true}
          />
        ) : null}
        {step === 2 ? (
            <div>
              <MealInfo
                id={selectedMealId}
                displayEditOption={false}
                isImport={true}
                onSelectImportMeals={handleSelectImportMealGroups}
                checkImportedMealGroup={checkSelectedMealGroup}
              />
            </div>
        ) : null}
      </div>
    </Dialog>
  );
};

export default ImportMealDialog;
