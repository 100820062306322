import React from 'react';

export default function TagIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="tag 1" clip-path="url(#clip0_38680_181909)">
        <path
          id="Vector"
          d="M8.39083 1.7266C8.14084 1.47654 7.80176 1.33601 7.44816 1.33594H2.66683C2.31321 1.33594 1.97407 1.47641 1.72402 1.72646C1.47397 1.97651 1.3335 2.31565 1.3335 2.66927V7.4506C1.33357 7.8042 1.4741 8.14328 1.72416 8.39327L7.52683 14.1959C7.82984 14.497 8.23966 14.666 8.66683 14.666C9.094 14.666 9.50382 14.497 9.80683 14.1959L14.1935 9.80927C14.4946 9.50626 14.6636 9.09644 14.6636 8.66927C14.6636 8.2421 14.4946 7.83228 14.1935 7.52927L8.39083 1.7266Z"
          stroke="#0D0D0D"
          stroke-width="1.33333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          id="Vector_2"
          d="M4.99984 5.33073C5.18393 5.33073 5.33317 5.18149 5.33317 4.9974C5.33317 4.8133 5.18393 4.66406 4.99984 4.66406C4.81574 4.66406 4.6665 4.8133 4.6665 4.9974C4.6665 5.18149 4.81574 5.33073 4.99984 5.33073Z"
          fill="#0D0D0D"
          stroke="#0D0D0D"
          stroke-width="1.33333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_38680_181909">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
