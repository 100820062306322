import React from "react";
import {
  CardHeader,
  Typography,
  CardContent,
  IconButton,
  Chip,
} from "@material-ui/core";

import _map from "lodash/map";
import _isArray from "lodash/isArray";

import { ClrdButton } from "fitbud/components/form-fields";
import moment from "moment";
import { useAI } from "fitbud/providers/ai-provider";


import OptionsIcon from "fitbud/icons/verticalOptions";

import { makeStyles } from '@material-ui/core/styles';
import MagicIcon from "fitbud/icons/MagicIcon";

    
const useStyles = makeStyles(() => ({
        chipCustom: {
            borderRadius: '20px', //some style
            '& .MuiChip-label': {
              fontSize: '11px',
              color: '#6F8099',
              fontWeight: '100',
              margin: '0px 8px 0px 8px',
              padding: '0px',
            }, // sub-selector
            background: '#F0F3F5',
            padding: '0px',
            margin: '0 8px 4px 0',
            height: '20px',
        },
        buttonCustom: {
          '& .MuiButton-label': {fontSize: '13px'}, // sub-selector
        }
    }));

const FitbotMessage = ({ message, onClick, ...rest }) => {
  // Need to show the actions depending on type of message.
  return <div className="mb-20">
    <MessageCard message={message} onClick={onClick} {...rest} />
    <div id={message._id} style={{display: "flex", justifyContent: "flex-start", alignItems: "center", marginRight: '1px' }}>
      <Typography variant="body2" style={{ fontSize: '11px', fontWeight: '100' }}>
      <span>{moment(message.t).format('DD MMM YY • HH:mm')}</span>
      <span>{message.generatedRefPath?.length ? ` • Saved ${message.generatedRefPath.length} times.` : ''}</span>
      </Typography>
    </div>
  </div>;
};

const MessageCard = ({ message, onClick, ...rest }) => {
  // render text/description message with arrow cta on right if message.type text and message.meta.type workout
  const { highlighted = null } = useAI();
  const classes = useStyles();

  if (message.type === 'text' && message.meta?.type === 'workout') {
    return <div className="card mb-5" style={{ background: '#317FF514', border: '1px solid #317FF5', borderRadius: '20px', ...(highlighted === message._id ? { background: 'lightgreen' } : {} ) }}>
      <CardHeader className="mb-0 pb-0" title={<h6 style={{ fontSize: '13px', fontWeight: 500 }}>{message.message}</h6>} />
      <CardContent className="pt-0">
        <Typography style={{ fontSize: '11px', fontWeight: 100 }} >
          {message.meta.data?.title}
        </Typography>
        {/** add an arrow icon button with onClick */}
        <IconButton style={{ position: 'absolute', right: '5px', top: '5px', color: '#317FF5' }} onClick={() => { onClick({messageData: message})}}>
          <MagicIcon width="20px" height="20px" />
        </IconButton>
      </CardContent>
    </div>;
  }
  let subheader = null;
  
  if (message.meta?.type === 'form') {
    subheader = <>
      {
        _map(message.meta?.data, (value, key) => key !== 'history' && key !== 'note' && value && <Chip className={classes.chipCustom} key={key} label={_isArray(value) ? value[0]?.value || value[0] : value}/>).slice(0,4)
      }
    </>
  }
  console.log(subheader);

  return <div className="card mb-5" style={{ border: '1px solid #E0E0E0', borderRadius: '20px' }}  onClick={() => { message.meta?.type === 'form' && onClick({messageData: message})}}>
    <CardHeader subheader={subheader} className="mb-0 pb-0" title={<h6 style={{ fontSize: '13px', fontWeight: 500 }}>{message.message}</h6>} />
    <CardContent className="pt-0">
      <Typography style={{ fontSize: '11px', fontWeight: 100 }} >
        {message.message}
      </Typography>
    </CardContent>
  </div>;
};

export default FitbotMessage;
