import React, { useEffect, useContext, useRef } from "react";
import { connect } from "react-redux";
import { FirebaseAuthContext } from "fitbud/providers/firebase-auth";
import chatListRdxFns from "fitbud/redux/chatList";
import checkinsRdxFns from "fitbud/redux/checkinsList";
import groupChatReduxFns from "fitbud/redux/groupChatList";
import { RoleContext } from "fitbud/providers/roleProvider";
import _ from 'lodash';

const defaultWorkerContext = {
  fetchNext: null,
  markRead: null
};

let hasNext = {};

const fetchNext = (worker, payload, actionsObj) => {
  const {
    toggleLoaderChat,
    toggleLoaderCheckins,
    toggleLoaderGroupChats,
  } = actionsObj;
  const keyName = payload.keyName;
  if(!hasNext[keyName]) return;
  switch (keyName) {
    case "chats":
      toggleLoaderChat()
      break;
    case "groupChats":
      toggleLoaderGroupChats()
      break;
    case "checkins":
      toggleLoaderCheckins()
      break;
    default:
      break;
  }
  worker.postMessage({ type: "fetchNext", payload });
};

const markRead = (worker, payload,filterFlags) => {
  worker.postMessage({ type: "markRead", payload,filterFlags });
};

const removeItem = (worker, payload) => {
  worker.postMessage({ type: "removeItem", payload });
}

const handleMessage = (event, actionsObj) => {
  const { type, payload, itemsAvailable, keyName } = event.data;
  const {
    setChatList,
    setCheckins,
    setGroupChats,
    updateChatList,
    updateCheckinsList
  } = actionsObj;
  hasNext = itemsAvailable;
  switch (type) {
    case "chats":{
      setChatList(payload, null, true);
      break;}
    case "groupChats":
      setGroupChats(payload, null, true);
      break;
    case "checkins":{
      setCheckins(payload, null, true);
      break;}
    case "updateFilterList":{
      if(keyName==='chats') updateChatList(payload);
      else if(keyName==='checkins') updateCheckinsList(payload);
      break;
    }
    default:
      return;
  }
};
export const WorkerContext = React.createContext(defaultWorkerContext);

const WorkerContextProvider = ({ children, ...rest }) => {
  const workerRef = useRef(null);
  const { allClientAccess } = useContext(RoleContext);
  const { authUser, cid, comp  } = useContext(FirebaseAuthContext);
  useEffect(() => {
    if (authUser) {
      workerRef.current = new Worker("../utils/listView.worker.js", {
        type: "module"
      });
      const { features } = comp.data() || {}
      init(workerRef.current, { cid, trainer_id: !allClientAccess && authUser.uid, gcEnabled: features.group_chat }, rest);
    }
    return () => {
      if (!!workerRef.current) {
        workerRef.current.postMessage({ type: "unsub" });
        workerRef.current.terminate();
      }
    };
  }, [cid, comp, rest, authUser, allClientAccess]);
  const {isChatFilterApplied,isCheckinsFilterApplied}=rest;
  return (
    <WorkerContext.Provider
      value={{
        fetchNext: payload => fetchNext(workerRef.current, payload, rest),
        markRead: payload => {
          markRead(workerRef.current, payload,{isChatFilterApplied,isCheckinsFilterApplied})
        },
        removeItem: payload => removeItem(workerRef.current, payload),
        hasMore: keyName => (!!hasNext[keyName]),
      }}
    >
      {children}
    </WorkerContext.Provider>
  );
};

const init = (worker, payload, actionsObj) => {
  const {
    toggleLoaderChat,
    toggleLoaderCheckins,
    toggleLoaderGroupChats,
  } = actionsObj;
  toggleLoaderChat();
  toggleLoaderCheckins();
  toggleLoaderGroupChats();
  worker.postMessage({ type: "init", payload });
  worker.onmessage = event => handleMessage(event, actionsObj);
};

const mapDispatchToProps = d => {
  const { set: setChatList, toggleLoader: toggleLoaderChat, update: updateChatList } = chatListRdxFns(d);
  const { set: setCheckins, toggleLoader: toggleLoaderCheckins, update: updateCheckinsList } = checkinsRdxFns(d);
  const { set: setGroupChats, toggleLoader: toggleLoaderGroupChats } = groupChatReduxFns(d);

  return {
    setChatList,
    toggleLoaderChat,
    updateChatList,
    setCheckins,
    toggleLoaderCheckins,
    updateCheckinsList,
    setGroupChats,
    toggleLoaderGroupChats,
  };
};

const mapStateToProps = s =>{
  const isChatFilterApplied=!_.isEmpty(_.get(s,['chatList','filter'],{}));
  const isCheckinsFilterApplied=!_.isEmpty(_.get(s,['checkinsList','filter'],{}));
  return {isChatFilterApplied,isCheckinsFilterApplied};
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkerContextProvider);
