import React, { useState, useEffect, useContext, useCallback } from 'react';
import { FormControlLabel, Radio, RadioGroup, TextField, Divider, Typography } from '@material-ui/core';
import { useInput, usePicker, useValidators } from 'fitbud/hooks/form';
import { FormTextField } from 'fitbud/components/form-fields';
import clsx from 'clsx';
import { FirebaseAuthContext } from 'fitbud/providers/firebase-auth';
import _ from "lodash";
import { MediaUploader } from 'fitbud/components/media-uploader/mediaUploader';
import update from "immutability-helper";
import * as Sentry from "@sentry/browser";

const INFO={
  regular:"Use Membership plans to offer your regular services with subscription or one-time pricing options. A client can have only one Membership plan active at a time.",
  gift:"Gift plans are regular 1-time Membership plans that can be purcahsed for someone else.",
  add_on:"Use session packs to sell bundled 1:1 sessions or group classes. A client can purchase multiple session packs.",
  add_on_membership:"Use Add-ons to offer additional services to your clients. A client can purchase multiple Add-ons."
}

const getType = ({gift,type,add_on_type}) => {
  if(!!type && type === 'add_on' && add_on_type==='membership')
    return 'add_on_membership';
  else if (gift) return 'gift';
  else return type || 'regular';

}

const BasicEditor = (props) => {
  const { fnRef, setDirty, data = {}, isNew, videoStatusRefresh, isEditMode } = props;
  const {comp, allowsDropIns, useNewPurchase} = useContext(FirebaseAuthContext);
  const {parsed: title, props: titleProps, isValid: tValid} = useInput(data.title, 'text *', { trim: true });
  const {parsed: ref_name, props: refProps, isValid: rValid} = useInput(data.ref_name, 'text *', { trim: true });
  const {parsed: description, props: descProps, isValid: dValid} = useInput(data.description, 'text *', { trim: true });
  const [type, setType] = usePicker(getType(data));
  const [image, setImage] = useState(data.image);
  const [media, setMedia] = useState(data.media || [{}]);
  
  const isValid = useValidators(tValid, rValid, dValid);
  const company = comp ? comp.data() : {};
  const isGroupClass = !!Boolean(_.get(company, "features.group_class.enabled", false));
  const supportsGift = allowsDropIns && !!Boolean(_.get(company, "features.gift", false));

  const updateMediaMeta = (args = {}) => {
    if (media && !!_.isMatch(media[0], args)) return;
    
    setMedia((prev) => (
      update(prev, {
        0: {
          $merge: {
            ...args,
          },
        },
      })
    ));
  };

  const setDuration = (secs) => {
    const value = Math.round(secs);
    updateMediaMeta({ duration: value });
  };

  const whenPlayerReady = useCallback(
    (player) => {
      try {
        const videoHeight = player.getInternalPlayer().videoHeight;
        if (!!videoHeight) {
          updateMediaMeta({ height: `${videoHeight}p` });
        }
      } catch (err) {
        console.log('onReady err', err);
        Sentry.captureException(err);
      }
    },
    [data.media]
  );
  
  const handleMedia = async (media) => {
    let { type, url } = media;

    if (!!_.get(media, 'vimeo', '')) type = 'vimeo'; //vimeo video hack ....
    
    if (['youtube', 'vimeo'].includes(type)) {
      setMedia([media]);
    } else {
      setMedia([{ type: 'video', url }]);
    }
  };

  const removeMedia = (type) => {
    if (['youtube', 'vimeo'].includes(type)) {
      setMedia([{ type, url: '' }]);
    } else if (type === 'video') {
      setMedia([{ type: 'video', url: '' }]);
    } else {
      setImage(null);
    }
  };

  const handleMediaError = (err) => {
    console.log('onError', err);
    Sentry.captureException(err);
  };

  useEffect(() => {
    if (setDirty) setDirty({ title, ref_name, image, description, type, media });
    fnRef.current = (setState) => {
      if (!setState) return false;
      if (!isValid()) return false;
      setState((curr) => ({ ...curr, title, ref_name, image: image || null, description, type, media }));
      const _state={...data, gift: false};
      if(type==='regular' || type==='gift'){
        _state['type']='regular';
        delete _state.add_on_type;
        if (type === 'gift') _state.gift = true;
      }
      else if(type==='add_on_membership'){
        _state['type']='add_on';
        _state['add_on_type']='membership';
      }
      else if(type==='add_on' && !!isNew){
        _state['type']='add_on';
        _state['add_on_type']='one_to_one';
      }
      setState({
        ..._state,
        title,
        ref_name, 
        image: image || null, 
        description, 
        media
      });
      return true;
    };
  }, [isNew, fnRef, isValid, setDirty, title, ref_name, image, description, type, media]);

  return (
    <div className='p-20 flex-1 overflow-y-auto'>
     {isNew && useNewPurchase && <div>
      <RadioGroup value={type} style={{marginTop:"-4px", marginBottom:"11px"}} className={clsx('d-flex flex-row')} onChange={setType}>
          <FormControlLabel value="regular" control={<Radio color="primary" />} classes={{label:"font_16_500"}} className='mr-30 mb-0' label="Membership" />
          <FormControlLabel value="add_on_membership" control={<Radio color="primary" />} classes={{label:"font_16_500"}} className='mr-30 mb-0' label="Add-On" />
          {isGroupClass && <FormControlLabel value="add_on" control={<Radio color="primary" />} classes={{label:"font_16_500"}} className='mb-0' label="Sessions Pack" />}
          {supportsGift && <FormControlLabel value="gift" control={<Radio color="primary" />} classes={{label:"font_16_500"}} className='mb-0' label="Gift" />}
        </RadioGroup>
        <div className='info-box mb-20'>
          <Typography className='font_14_500 text-0d0d0d'>
            {INFO[type]}
          </Typography>
        </div> 
       <Divider className='ml-n20 mr-n20 mb-25' />  
      </div>}
      <div className='d-flex fmb-20'>
        <div className="flex-1 d-flex flex-column justify-content-between">
          <FormTextField required fullWidth label='Display Name'>
            <TextField {...titleProps}
              autoComplete='off'
              variant='outlined'
              required
              type='text'
              InputProps={{ classes: { root: 'medium', input: 'size_16_500 medium' } }}
            />
          </FormTextField>
          <FormTextField required fullWidth label='Reference Name' classes={{control:"mb-0"}}>
            <TextField {...refProps}
              autoComplete='off'
              variant='outlined'
              required
              type='text'
              InputProps={{ classes: { root: 'medium', input: 'size_16_500 medium' } }}
            />
          </FormTextField>
        </div>
      </div>
      <FormTextField required fullWidth label='Description' classes={{control:"mb-0"}}>
        <TextField {...descProps}
          multiline
          minRows='4'
          maxRows='15'
          id='description'
          fullWidth
          variant='outlined'
          required
          InputProps={{ classes: { root: 'large', input: 'size_16_500' } }}
        />
      </FormTextField>
      <Divider className='ml-n20 mr-n20 my-25' />  
      <MediaUploader
        label="Media"
        media={media}
        handleMedia={handleMedia}
        removeMedia={removeMedia}
        featureType='paymentPlan'
        showFeature={false}
        videoStatusRefresh={videoStatusRefresh}
        playerProps={{
          onDuration: setDuration,
          onReady: whenPlayerReady,
          onError: handleMediaError,
        }}
        thumbaspect={3/2}
        withThumbnail
        videoUploaderWidth={isEditMode ? "350" : "330"}
        thumbnail={image}
        handleChange={(e) => {
          setImage(e.target.value);
        }}
        isNew={isNew}
      />
    </div>
  );
};


export default BasicEditor;
