import {
  FormControl,
  FormHelperText,
  FormLabel,
  Typography,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { FirebaseAuthContext } from 'fitbud/providers/firebase-auth';
import { RoleContext } from 'fitbud/providers/roleProvider';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { default as React, useContext, useEffect, useState, useMemo } from 'react';
import { FormTextField } from 'fitbud/components/form-fields';
import UserData from './userData';
import { ServicePicker, TimePick, LocationPicker } from 'fitbud/views/liveSessions/masterSettings/SlotPicker';
import TrainerSelector from 'fitbud/views/liveSessions/booking-dialog/trainerSelector';
import _ from 'lodash';
import { usePicker } from 'fitbud/hooks/form';
import { useServices } from 'fitbud/providers/services-provider';
import clsx from 'clsx';
import { DEFAULT_LEGACY_SERVICE_ID, LEGACY_SERVICE_BASE, LEGACY_SERVICE } from 'fitbud/providers/access-provider';
import { SERVICE_STATUS } from 'fitbud/views/services/helper';
import { useLocations } from 'fitbud/providers/gcLocationsProvider';

export const DatePickerField = (props) => {
  const InputProps = {
    classes: {
      root: 'medium',
      input: 'size_16_500 medium',
    },
    ...props.InputProps,
  };
  return (
    <FormTextField fullWidth label={!props.hideLabel && 'Date'}>
      <TextField {...props} InputProps={InputProps} />
    </FormTextField>
  );
};

const skipTo15Mins = (time) => {
  // Returns time with minutes as 15 multiples
  const t = moment(time);
  const remainder = 15 - (t.minute() % 15);
  const newTime = moment(t).add(remainder, 'minutes');
  return newTime.toDate();
};

const BookingInfo = (props) => {
  const {
    rescheduleMode,
    data,
    user,
    onChange,
    setErrors,
    errors = {},
    goBack,
    userBookingsCount,
    setUserBookCount,
    directBooking,
  } = props;
  const { cid, authUser: { uid } = {} } = useContext(FirebaseAuthContext);
  const { isOwner, tEnabled, gcEnabled } = useContext(RoleContext);
  const { getServiceById, services } = useServices();
  const [date, setDate] = useState(rescheduleMode ? new Date(data.startDate) : moment().toDate());
  const [time, setTime] = useState(rescheduleMode ? new Date(data.startDate) : skipTo15Mins(moment()));
  const [duration, setDuration] = useState(rescheduleMode ? data.duration : 15);
  const [isTrainerBooking, setTrainerBooking] = useState(rescheduleMode ? data.isTrainerBooking : true);
  const [isInstant, setIsInstant] = useState(false);
  const [selectedTrainer, setSelectedTrainer] = useState(props.defaultTrainer || _.get(user, 'trainer_id', uid));
  const [service, setService] = usePicker(data?.service || DEFAULT_LEGACY_SERVICE_ID); //@TODO_V2 what will happen when service disable now
  const [location, setLocation] = usePicker('');
  const { legacy } = useSelector((s) => s.services);
  const { offline_locations } = useLocations();

  const [servicesOptions] = useMemo(() => {
    let out = [];
    legacy.forEach((x) => {
      const { duration, status, _id } = x;
      if (status !== 'active') return;
      out.push({ value: _id, label: `${LEGACY_SERVICE_BASE.ref_name} • ${duration} mins` });
    });
    if (gcEnabled) {
      const _services = _.chain(services || [])
        .filter((service) => service?.data?.status !== SERVICE_STATUS.INACTIVE)
        .map((service) => ({ value: service?._id, label: `${service?.data?.title} • ${service?.data?.duration} mins` }))
        .value();
      out = _.concat(out, _services);
    }
    return [out];
  }, [legacy, gcEnabled, services]);

  const [locationOptions, serviceDetail] = useMemo(() => {
    if (!service) return [[], {}];
    if (service.match(/^vidcall_(\d+)$/)) {
      //ie it is legacy service of call 15,20,30 min.
      const legacy_detail = LEGACY_SERVICE(service);
      return [legacy_detail?.locations, legacy_detail];
    }
    //if actual services ...
    const serviceInfo = _.get(getServiceById(service), 'data', {});
    let service_locations = serviceInfo?.locations || [];
    if (_.includes(service_locations, 'all')) {
      // only offline services will have all, in that case load all offline locations
      service_locations = _.map(offline_locations, (l) => l._id);
    }
    return [service_locations, serviceInfo];
  }, [service, offline_locations, getServiceById]);

  useEffect(() => {
    setLocation(locationOptions?.[0] || 'app');
    setDuration(serviceDetail?.duration);
  }, [serviceDetail, locationOptions]);

  async function handleDateChange(date) {
    setErrors({});
    let newDate = moment(date, 'MMMM Do YYYY');
    setDate(newDate);
  }

  function handleSlotChange(time) {
    setErrors({});
    setTime(moment(time, 'hh:mm a').toDate());
  }

  function toggleQuota() {
    setTrainerBooking(!isTrainerBooking);
  }

  function mergeTime(date, time) {
    // Merge date & time variable to make utc slot
    date = moment(date);
    time = moment(time);
    let newTime = moment(date.format('YYYYMMDD'), 'YYYYMMDD').set({
      hours: time.hours(),
      minutes: time.minutes(),
      seconds: 0,
      milliseconds: 0,
    });
    return newTime.toDate();
  }
  const handleInstantChange = (e) => {
    if (e.target.value === 'instant') {
      setIsInstant(true);
      setDate(moment().toDate());
      setTime(moment().add(1, 'minutes'));
    } else {
      setIsInstant(false);
      setDate(moment().toDate());
      setTime(skipTo15Mins(moment()));
    }
  };

  const handleSelectedTrainer = (value) => {
    setSelectedTrainer(value);
  };

  useEffect(() => {
    // Pass the change up to parent each time anything changes
    onChange({
      time: mergeTime(date, time).toISOString(),
      duration,
      isTrainerBooking,
      selectedTrainer,
      service,
      location,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time, date, duration, isTrainerBooking, selectedTrainer, service, location]);

  return (
    <div>
      <div className="">
        <FormControl component="fieldset">
          <RadioGroup value={isInstant ? 'instant' : 'later'} onChange={handleInstantChange}>
            <div className="d-flex">
              <FormControlLabel
                value="instant"
                control={<Radio color="primary" />}
                label="Schedule Now"
                className="fmr-30 mb-16"
                classes={{ label: 'font_16_600' }}
              />
              <FormControlLabel
                classes={{ label: 'font_16_600' }}
                value="later"
                className="mb-16"
                control={<Radio color="primary" />}
                label="Schedule Later"
              />
            </div>
          </RadioGroup>
        </FormControl>
        {!!isOwner && !!tEnabled && !rescheduleMode && (<TrainerSelector value={selectedTrainer} onChange={handleSelectedTrainer} />)}
        {/* Selector Location Picker */}
        {servicesOptions && !!servicesOptions.length && !rescheduleMode && (
          <div className="d-flex">
            <ServicePicker
              allowEmpty={false}
              fullWidth
              value={service}
              options={servicesOptions}
              onChange={setService}
              // inputClass="font_15_600"
              classes={{ control: clsx('mb-20', 'mr-20') }}
            />
            <LocationPicker
              allowEmpty={false}
              fullWidth
              disabled={!service}
              value={location}
              options={locationOptions}
              mode={_.get(getServiceById(service), 'data.mode')}
              onChange={setLocation}
              classes={{ control: 'mb-20' }}
              // inputClass="font_15_600"
            />
          </div>
        )}
        <div className="d-flex">
          {/* DATE AND TIME INPUT */}
          {!isInstant && (
            <FormControl fullWidth>
              <DatePicker
                allowKeyboardControl
                animateYearScrolling={false}
                disablePast
                onChange={handleDateChange}
                value={date}
                name="date"
                format="MMM D, YYYY"
                inputVariant="outlined"
                TextFieldComponent={DatePickerField}
                // inputProps={{className:"font_15_600"}}
                KeyboardButtonProps={{
                  'aria-label': 'date',
                }}
              />
            </FormControl>
          )}
          {/* SLOT SELECTOR */}
          {!isInstant && (
            <FormControl fullWidth className="ml-20" error={!!errors.time}>
              <FormLabel>
                <Typography variant={'body2'} component="label" color="textSecondary">
                  Time{' '}
                </Typography>
              </FormLabel>
              <TimePick
                error={Boolean(errors.time)}
                value={time}
                format="hh:mm a"
                onChange={handleSlotChange}
                name="slot"
                // inputProps={{className:"font_15_600"}}
                minTime={moment().toDate()}
              />
              {!!errors.time ? <FormHelperText>{errors.time}</FormHelperText> : null}
            </FormControl>
          )}
        </div>
      </div>
      <Divider className="mx-n20" />
      <div>
        <Typography className="font_16_600 my-20">Client Info</Typography>
        <UserData
          cid={cid}
          date={date}
          user={rescheduleMode ? data.user : user}
          rescheduleMode={rescheduleMode}
          onNameBoxClick={directBooking ? null : goBack}
          disableEdit={directBooking || rescheduleMode}
          isTrainerBooking={isTrainerBooking}
          toggleQuota={toggleQuota}
          userBookingsCount={userBookingsCount}
          setUserBookCount={setUserBookCount}
          isInstant={isInstant}
          handleInstantChange={handleInstantChange}
          service={service}
        />
      </div>
    </div>
  );

};

export default BookingInfo;
