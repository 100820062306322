import { Card, CircularProgress, makeStyles, Typography, IconButton, Hidden } from "@material-ui/core";
import clsx from "clsx";
import NoBilling from "fitbud/images/no_billing_icon.svg";
import { AMPLITUDE_PROPS, oprtnlDateFormat } from "fitbud/utils/constants";
import _ from "lodash";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useToggle } from 'fitbud/hooks/form';
import EditIcon from 'fitbud/icons/detailEdit';
import Dialog from 'fitbud/components/Dialog';
import { useUserContext } from "../detail";
import { useProgressTrendsContext } from "../progTrendsProvider";
import { findComplianceMark, findPlanPeriod } from "./helpers";
import StatsView from "./statView";
import TimeLineNav from "./timeLineNav";
import { AnalyticsContext } from "fitbud/providers/analytics";

const styles = makeStyles((theme) => ({
  root: {
    flex: 1,
    overflow:'auto',
  },
  leftPanel:{
    width:'100%',
    [theme.breakpoints.up('md')]:{
      width: '40%',
    },
    overflow:'auto',
  },
  rightPanel:{
    width:'100%',
    [theme.breakpoints.up('md')]:{
      width:'60%', 
    },
    overflow:'auto',
    borderLeft:'1px solid #eee'
  },
  complianceBadge: {
    backgroundColor: ({result}) => !!result && `#${result.color}08`, // 5% opacity in HEX
    padding: ({size}) => size === 'sm' ? '3px 6px' : '3px 8px',
    fontSize: ({size}) => size === 'sm' ? '10px' : '13px',
  },
  badgeText:{
    color: ({result}) => !!result && `#${result.color}`,
    fontSize: ({size}) => size === 'md' ? '13px' : '10px',
    fontWeight: 600,
  }
}));
const Progress = props => {
  const classes = styles();
  const { planStartDate, planTotalDuration, aplan, docId } = useUserContext();
  const { loadProgressData, calculateProgress } = useProgressTrendsContext();
  const { trackEvent } = useContext(AnalyticsContext);
  const [isLoading, setLoading] = useState(false);
  const [opts, toggleOpts, setOpts] = useToggle();
  const [title, setTitle] = useState('Workout');
  const [view, setView] = useState('workout');
  const [viewLimit, setViewLimit] = useState('weekly');
  const [selectedPeriod, setSelectedPeriod] = useState(false);
  const [progressDataSum, setProgressDataSum] = useState({});

  useEffect(()=> { 
    let unMounted = false;
    if(unMounted) return;
    setSelectedPeriod(findPlanPeriod(moment().toDate(), planStartDate, planTotalDuration , viewLimit));
    setLoading(true);
    loadProgressData().then((res => {
      if(unMounted) return;
      
      setLoading(false);
    }));
    return () => unMounted = true;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[planTotalDuration, aplan, loadProgressData, planStartDate]);

  useEffect(()=>{
    if(!selectedPeriod) return;
    setProgressDataSum(calculateProgress(selectedPeriod));
  }, [calculateProgress, planStartDate, planTotalDuration, selectedPeriod]);

  useEffect(() => {
    trackEvent({name: AMPLITUDE_PROPS.PROGRESSION_VIEWED, properties: { id: docId }, tools: ['amplitude']})
  }, [])

  const handleViewSelect = (title) => () => {
    if (!title) return;
    setTitle(title);
    setView(title.toLowerCase());
    setOpts(false);
  };
  const handleViewLimitSelect = (_viewLimit) =>{
    setSelectedPeriod(findPlanPeriod(moment().toDate(), planStartDate, planTotalDuration , _viewLimit));
    setViewLimit(_viewLimit);
  };

  const navigateDates = (control) => {
    return () => {
      setSelectedPeriod((prev) => {
        let nextStart = moment(prev.endDate);
        if(control === 'prev') nextStart.subtract(viewLimit === 'weekly' ? 7 : 30, 'days');
        else nextStart.add(viewLimit === 'weekly' ? 7 : 30, 'days');
        return findPlanPeriod(nextStart.toDate(), planStartDate, planTotalDuration, viewLimit);
      })
    }
  }

  if(isLoading || !selectedPeriod) return (
    <div className={`${classes.root} d-flex h-100 position-relative align-items-center justify-content-center`}>
      <CircularProgress />
    </div>
  );

  if(!!!aplan || aplan === null || moment(planStartDate, oprtnlDateFormat).isAfter(moment(), 'day')){
    return (
      <div className='d-flex h-100 justify-content-center align-items-center'>
        <ProgressNotFound />
      </div>
    )
  };

  return (
    <div className='h-100 d-flex flex-column overflow-hidden'>
      <Hidden mdUp>
        <div className='d-flex flex-row fpy-15 fpx-20 border-bottom align-items-center'>
          <Typography variant="h3" className="text-capitalize">
            {title}
          </Typography>
          <div className='flex-grow-1'/>
          <Hidden mdUp>
            <IconButton onClick={toggleOpts} className='m-n8'><EditIcon/></IconButton>
          </Hidden>
        </div>
      </Hidden>
      <TimeLineNav 
        navigateDates={navigateDates} 
        handleViewLimitSelect={handleViewLimitSelect} 
        planDuration={planTotalDuration} 
        planStartDate={planStartDate}
        selectedPeriod={selectedPeriod}
        viewLimit={viewLimit}
      />
      <div className={`${classes.root} d-flex flex-column flex-md-row `} >
        <Hidden smDown>
          <Options className={classes.leftPanel}  handleViewSelect={handleViewSelect}
          progressDataSum={progressDataSum} view={view}/>
        </Hidden>
        <div className={`${classes.rightPanel} bg-white`}>
          <StatsView progressDataSum={progressDataSum} view={view} />
        </div>
      </div>
      {opts && <Dialog open title='Options' onClose={toggleOpts}>
        <Options className={classes.leftPanel}  handleViewSelect={handleViewSelect}
        progressDataSum={progressDataSum} view={view}/>
      </Dialog>}
    </div>
  );
};

const Options = ({asSelect = false, className, view, handleViewSelect, progressDataSum}) => {
  return (
    <div className={`${className || ''} fpy-15 fpx-20 bg-light`}>
      {['Workout', 'Nutrition', 'Water'].map((title, index) => 
        <CardText
          key={title+index} 
          title={title} 
          className={index > 0 && 'fmt-15'} 
          handleViewSelect={handleViewSelect} 
          view={view}
          progressDataSum={progressDataSum}
        />
      )}
      <Typography className='fmt-25 fmb-15'>
        Activity
      </Typography>
      {['Steps', 'Flights', 'Distance', 'Energy'].map((title, index) => 
        <CardText 
          key={title+index} 
          title={title} 
          className={index > 0 && 'fmt-15'} 
          handleViewSelect={handleViewSelect} 
          view={view}
          progressDataSum={progressDataSum}
        />
      )}
    </div>
  );
};

const CardText = ({ title='', className, view, handleViewSelect,progressDataSum, ...rest }) => {
  const { complianceConfig } = useProgressTrendsContext();
  const data = progressDataSum[title.toLowerCase()] || {};
  const { compliance = false } = _.get(data, `${data.primary}`, {});

  return (
    <Card elevation={1} onClick={handleViewSelect(title)} style={{maxWidth: '380px'}}
      className={clsx('fp-20 d-flex justify-content-between align-items-center cursor-pointer w-100', 
        (view === title.toLowerCase()) && 'border border-dark', className)} {...rest}>
        <Typography variant='h6'>
          {title}
        </Typography>
        <div className='d-flex align-items-center h-100'>
          {!!compliance && (
            <ComplianceBadge compliance={compliance} size='sm' type={title.toLowerCase()} complianceConfig={complianceConfig} />
          )}
          <Typography variant='h4' noWrap>
            {/* compliance can bee 200 in case of nutrition only- check helpers/calcCompliance() */}
            {!!compliance ? `${compliance >= 200 ? 0 : compliance} %`: '-' }
          </Typography>
        </div>
    </Card>
  );
}

export const ProgressNotFound = () => {
  return (
    <div className="h-100 text-center d-flex align-items-center justify-content-center flex-column">
      <img alt="no progress" src={NoBilling} className="fmb-20" />
      <Typography variant="h2" className={`fmb-10`}>
        No Trends Available
      </Typography>
      <Typography variant="body2" className={`fmb-10 w-50`} color='textSecondary'>
        Workout, nutrition, water and activity trends show up here when user is on a active plan
      </Typography>
    </div>
  )
}

export const ComplianceBadge = ({ className, compliance, size='md', type, format='text', complianceConfig={}, ...rest }) => {
  let config = (type === 'steps' || type === 'flights' ||type === 'distance' || type === 'energy' ) ? complianceConfig['activity']  
    : complianceConfig[type];
  const result = findComplianceMark(config, compliance >= 200 ? 0 : compliance);
  if(!config || !result) return null;
  const { complianceBadge, badgeText } = styles({result, size});
  return (
    <div className={clsx('fmr-15 rounded m-0', complianceBadge, className)} {...rest}>
      <Typography className={clsx('text-capitalize', badgeText)}>
        {/* compliance can bee 200 in case of nutrition only- check helpers/calcCompliance() */}
        {format === 'text' ? result.label : `${!!compliance ? compliance >=200 ? 0 : compliance : ''}%` }
      </Typography>
    </div>
  );
}

export default Progress;
