import React, { useMemo, useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { IconButton, Tooltip } from '@material-ui/core';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import { useDispatch } from 'react-redux';
import appRdxFns from 'fitbud/redux/app';
import firebase from 'fitbud/firebase';
import { FirebaseAuthContext } from 'fitbud/providers/firebase-auth';

const MarkUnread = () => {
  const { cid } = useContext(FirebaseAuthContext);
  const { location, replace } = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const d = useDispatch();
  const { showLoader, hideLoader } = appRdxFns(d);

  const {isGroup, isChlng, target} = useMemo(() => {
    const { pathname } = location;
    if (!pathname) return {};
    const parts = pathname.split('/');
    const target = parts.pop();
    const dir = parts.pop();
    const isGroup = dir === 'group-chats';
    const isChlng = dir === 'challenges';
    return {isGroup, isChlng, target};
  }, [location]);

  const popView = useCallback(() => {
    const { pathname } = location;
    if (!pathname) return replace('/');
    const parts = pathname.split('/');
    parts.pop();
    const newLocation = parts.join('/');
    replace(newLocation);
  }, [location, replace]);

  const doUnread = useCallback(() => {
    showLoader();
    let path = `user_profiles/${target}`, grp = null;
    if (isGroup) grp = target;
    else if (isChlng) grp = `${cid}:chlng-${target}`;
    if (grp) path = `groupChats/${grp}`;
    const db = firebase.firestore();
    db.runTransaction(txn => txn.get(db.doc(path)).then(doc => {
      const { [cid]: self, last_message_authorId } = doc.data();
      if (!self || !self.read_count || self.read_count < 0)
        return;
      const update = {
        [`${cid}.read_count`]: firebase.firestore.FieldValue.increment(-1),
        _muat: firebase.firestore.FieldValue.serverTimestamp(),
        _uat: firebase.firestore.FieldValue.serverTimestamp(),
      };
      const counterUpdate = grp ? txn.update(db.doc(`companies/${cid}/misc/counters`), { [`groupChats.${grp}`]: true }) : Promise.resolve(true);
      if (last_message_authorId === cid) update.last_message_authorId = null;
      return Promise.all([txn.update(doc.ref, update), counterUpdate]);
    })).then(result => {
      hideLoader();
      popView();
    }).catch(err => {
      if (err.code === 'invalid-argument') {
        enqueueSnackbar('No messages present to mark as unread', { variant: 'info' });
      } else {
        console.error(err);
        enqueueSnackbar('Unable to mark as unread', { variant: 'error' });
      }
      hideLoader();
    });
  }, [cid, isGroup, isChlng, target, popView, enqueueSnackbar, showLoader, hideLoader]);

  return (
    <Tooltip title='Mark as Unread'>
      <IconButton onClick={doUnread}>
        <VisibilityOffOutlinedIcon/>
      </IconButton>
    </Tooltip>
  );
};

export default MarkUnread;
