import {
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { DatePicker, TimePicker } from '@material-ui/pickers';
import Dialog from 'fitbud/components/Dialog';
import { FormTextField } from 'fitbud/components/form-fields';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import firebase from 'fitbud/firebase';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import appRdxFns from 'fitbud/redux/app';
import { DialogPdfUploader } from 'fitbud/components/pdfUploader';
import { MediaUploader } from 'fitbud/components/media-uploader/mediaUploader';
import update from 'immutability-helper';
import { createVidObject } from 'fitbud/api';
import { FirebaseAuthContext } from 'fitbud/providers/firebase-auth';
import { DEFAULT_ERROR, DEFAULT_VID_UPLOAD_ERROR, FILE_STATUS } from 'fitbud/utils/constants';
import { useSnackbar } from 'notistack';
import { uploadPdf } from 'fitbud/utils/aws';
import fileUploadRdxFns from 'fitbud/redux/fileUpload';
import { CloseRounded } from '@material-ui/icons';

const MESSAGE_TYPE = [
  {
    value: 'text',
    label: 'Text',
  },
  {
    value: 'document',
    label: 'Document',
  },
  {
    value: 'link',
    label: 'Link',
  },
  /* {
    value: 'video',
    label: 'Video',
  },*/
];

const TYPE_TITLE_MAP = {
  text: 'Details',
  document: 'Document',
  link: 'Link',
  video: 'Media',
};

export const MAX_DATE = '9999-12-31 23:59:59';
export const TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

const DEFAULT_DATA = {
  dtype: 'text',
  title: '',
  description: '',
  link: '',
  document: [],
  media: [],
};

const generateMsgData = (data, isNew) => {
  const defaultFrom = moment().format(TIME_FORMAT);
  const defaultTill = moment().add(1, 'days').format(TIME_FORMAT);
  const { _from = defaultFrom, _till = defaultTill, ...rest } = data;

  return {
    ...rest,
    startDate: moment(_from, TIME_FORMAT),
    startTime: moment(_from, TIME_FORMAT),
    endDate: _till === MAX_DATE ? 'never' : moment(_till, TIME_FORMAT),
    endTime: _till === MAX_DATE ? 'never' : moment(_till, TIME_FORMAT),
  };
}

export default function PinnedMessageDialog({
  defaultData = DEFAULT_DATA,
  isNew = true,
  onClose,
  onSubmit,
  groupId,
  pinnedMsgs,
}) {
  const { cid } = useContext(FirebaseAuthContext);
  const [data, setData] = useState(generateMsgData(defaultData, isNew));
  const [errors, setErrors] = useState({});

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { showLoader, hideLoader } = appRdxFns(dispatch);
  const { uploadBunnyFile } = fileUploadRdxFns(dispatch);

  const { dtype, title, startDate, startTime, endDate, endTime, description, link, document, media } = data;

  function handleChange(e) {
    const { name, value } = e.target;
    const updatedData = { ...data, [name]: value };
    let updatedErrors = _.omit(errors, name);

    if (name === 'startDate' && value.isSameOrAfter(endDate)) {
      updatedData.endDate = moment(value).add(1, 'days');
    }

    if (name === 'endDate' || name === 'endTime') {
      updatedErrors = _.omit(updatedErrors, ['endDate', 'endTime']);
    }

    setData(updatedData);
    setErrors(updatedErrors);
  }

  function handleResetClick(e) {
    e.stopPropagation();

    setData((prev) => ({
      ...prev,
      endDate: 'never',
      endTime: 'never',
    }));
  }

  function handleDocument(type, file, meta = false) {
    const _docName = _.get(data, 'document.0.name', '');
    let _document = [];
    if (type === 'file') {
      //incase of file
      _document = [{ type, name: _docName }];
      if (!!file) {
        if (meta) _document = [{ type, ...(file || {}) }];
        //if meta is true ie only meta fields changes like name
        else
          _document = [
            { url: file, file_original_name: file.name, size: file.size, type, isLocal: true, name: _docName },
          ];
      }
    } else if (type === 'link' || type === 'externalLink') {
      //incase iof link
      _document = [{ type, name: _docName, ...(file || {}) }];
    }
    setData((prev) => ({
      ...prev,
      document: _document,
    }));
    setErrors((prev) => _.omit(prev, 'document'));
  }

  const handleMedia = (media) => {
    const { type } = media;

    if (['vimeo', 'video'].includes(type)) {
      setData((prev) => ({
        ...prev,
        media: [media],
      }));

      setErrors((prev) => _.omit(prev, 'media'));
    }
  };

  const removeMedia = (type) => {
    let media = [{ type, url: '' }];
    setData((prev) => ({
      ...prev,
      media,
    }));
  };

  function updateMediaMeta(args = {}) {
    const { media } = data;
    if (media && !!_.isMatch(media[0], args)) return;
    const newData = update(data, {
      media: {
        0: {
          $merge: {
            ...args,
          },
        },
      },
    });
    setData(newData);
  }

  function setDuration(secs) {
    const value = Math.round(secs);
    updateMediaMeta({ duration: value });
  }

  const whenPlayerReady = useCallback(
    (player) => {
      const videoHeight = player.getInternalPlayer().videoHeight;
      if (!!videoHeight) {
        updateMediaMeta({ height: `${videoHeight}p` });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [data.media]
  );

  const createVideoObject = async ({ id, duration, title, resolution }) => {
    try {
      const collection = 'pins';
      const resp = await createVidObject({
        cid: cid,
        docId: id,
        path: `groupChats/${groupId}/${collection}/${id}`,
        collection,
        media_bgoff: false,
        video: {
          title,
          duration,
          resolution,
        },
      });
      return await resp.data;
    } catch (err) {
      enqueueSnackbar(_.get(err, 'response.data.message', DEFAULT_VID_UPLOAD_ERROR), { variant: 'error' });
      console.log('err', err);
      return null;
    }
  };

  const uploadVideoToBunnyCDN = async ({ id, file, docName, ...videoObject }) => {
    uploadBunnyFile({
      docId: id,
      cid: cid,
      file: file.url,
      collection: 'pins',
      media_bgoff: false,
      path: `/messages/group-chats/${id}`,
      docName,
      videoObject,
    });
  };

  function validateForm() {
    let isValid = true;
    const errors = {};

    if (!title) {
      isValid = false;
      errors.title = 'Title is required';
    }
    const fromStr = startDate.format('YYYY-MM-DD') + startTime.format('HH:mm:ss');
    const tillStr = endDate === 'never' ? MAX_DATE : endDate.format('YYYY-MM-DD') + endTime.format('HH:mm:ss');
    const from = moment(fromStr, TIME_FORMAT);
    const till = moment(tillStr, TIME_FORMAT);

    const diff = till.diff(from, 'days');

    if (diff < 0 && endDate !== 'never') {
      isValid = false;
      errors.endDate = 'End date should be greater than start date';
    } else if (diff === 0) {
      const timeDiff = till.diff(from, 'minutes');
      if (timeDiff <= 0) {
        isValid = false;
        errors.endTime = 'End time should be greater than start time';
      }
    }

    switch (dtype) {
      case 'text':
        if (!description) {
          isValid = false;
          errors.description = 'Description is required';
        }
        break;
      case 'document':
        if (!document.length) {
          isValid = false;
          errors.document = 'Document is required';
        }
        break;
      case 'link':
        if (!link) {
          isValid = false;
          errors.link = 'Link is required';
        }
        break;
      case 'video':
        if (!media.length) {
          isValid = false;
          errors.media = 'Video is required';
        }
        break;
      default:
        break;
    }

    setErrors(errors);

    return isValid;
  }

  async function handleSave() {
    const isValid = validateForm();
    if (!isValid) return;
    
    showLoader();
    const groupChatsRef = firebase.firestore().doc(`groupChats/${groupId}`);
    const pinsRef = groupChatsRef.collection('pins');
    const pinnedMsgDocRef = isNew ? pinsRef.doc() : pinsRef.doc(data.id);

    const pinsMsgPayload = {
      dtype,
      title,
      _from: `${startDate.format('YYYY-MM-DD')} ${startTime.format('HH:mm:ss')}`,
      _till: endDate === 'never' ? MAX_DATE : `${endDate.format('YYYY-MM-DD')} ${endTime.format('HH:mm:ss')}`,
      active: false,
    };

    if (dtype === 'text') pinsMsgPayload.description = description;
    if (dtype === 'document') pinsMsgPayload.document = document;
    if (dtype === 'link') pinsMsgPayload.link = link;
    if (dtype === 'video') pinsMsgPayload.media = media;

    // Video related task
    let firebaseId;
    let newMediaAvailable = !!_.get(media, '0.url.name', null);
    if (media && media.length && media[0].url && media[0].url.name) {
      // It'a manual file upload
      if (newMediaAvailable) {
        firebaseId = isNew ? pinnedMsgDocRef.id : data.id;
        //create video obj
        const resp = await createVideoObject({
          id: firebaseId,
          duration: _.get(media, '0.duration'),
          title: _.get(media, '0.url.name', ''),
          resolution: _.get(media, '0.height', ''),
        });
        if (!resp) {
          hideLoader();
          return;
        }
        newMediaAvailable = resp;
        pinsMsgPayload.media = [
          {
            type: 'video',
            status: resp ? FILE_STATUS.uploading : FILE_STATUS.error,
            videoId: _.get(resp, 'videoId', ''),
          },
        ];
      }
    } else if (media && media[0] && ['youtube', 'vimeo'].includes(media[0].type) && media[0].url) {
      pinsMsgPayload.media = [
        { type: media[0].type, url: media[0].url, ...(media[0].error && { error: media[0].error }) },
      ];
    }
    /* video related task end */

    /* document/Pdf related task  */
    const _document = document && document[0];
    const _document_type = _.get(_document, 'type', '');
    const _isLocalFile = _.get(_document, 'isLocal', false);
    if (_document_type === 'file' && _isLocalFile && _document.url) {
      try {
        const _docResponse = await uploadPdf({ cid, file: _document.url });
        pinsMsgPayload.document = [_docResponse];
      } catch (err) {
        hideLoader();
        enqueueSnackbar(DEFAULT_ERROR, { variant: 'error' });
        // Sentry.captureException(err);
        return;
      }
    }
    
    let pinnedMsgPayload = { ...pinsMsgPayload, id: pinnedMsgDocRef.id };

    if (dtype === 'video') {
      pinnedMsgPayload = _.omit(pinnedMsgPayload, ['media']);
    }

    const newPinnedMsgs = []
    pinnedMsgs.forEach((msg) => {
      const till = moment(msg._till, TIME_FORMAT);
      const now = moment();
      if(!isNew && msg.id === pinnedMsgPayload.id) {
        newPinnedMsgs.push(pinnedMsgPayload);
      } else if(now.isBefore(till)) {
        newPinnedMsgs.push(msg);
      }
    });

    if(isNew) {
      newPinnedMsgs.push(pinnedMsgPayload);
    } 

    try {
      const batch = firebase.firestore().batch();

      batch.set(pinnedMsgDocRef, { ...pinsMsgPayload }, { merge: true });
      batch.update(groupChatsRef, {
        pinned: [...newPinnedMsgs],
        _muat: firebase.firestore.FieldValue.serverTimestamp(),
      });

      await batch.commit();

      const doc = await pinnedMsgDocRef.get();
      const docData = doc.data();
      if (newMediaAvailable) {
        uploadVideoToBunnyCDN({
          id: doc.id,
          file: media[0],
          docName: docData.title,
          ...newMediaAvailable,
        });
      }

      onSubmit([...newPinnedMsgs]);
      hideLoader();
    } catch (error) {
      hideLoader();
      console.log('Error adding pinned message: ', error);
    }

    onClose();
  }

  useEffect(() => {
    async function fetchMsgData() {
      if (dtype === 'video') {
        showLoader();
        try {
          const doc = await firebase.firestore().doc(`groupChats/${groupId}/pins/${data.id}`).get();
          const msgData = doc.data();
  
          setData((prev) => ({ ...prev, ...msgData }));
          hideLoader();
        } catch (error) {
          console.log('Error fetching pinned message data: ', error);
          hideLoader();
        }
      }
    }

    fetchMsgData();
  }, []);

  return (
    <Dialog
      open
      title={isNew ? 'Add Pinned Message' : 'Edit Pinned Message'}
      paperClass="width-600"
      onSave={handleSave}
      onClose={onClose}
      actionText="Save"
      dialogContentClassName="px-20 pb-25 pt-15"
    >
      <Grid container>
        <Grid item xs={12}>
          <RadioGroup name="dtype" value={dtype} onChange={handleChange} row>
            {MESSAGE_TYPE.map(({ value, label }) => (
              <FormControlLabel
                key={value}
                value={value}
                disabled={!isNew}
                control={<Radio color="primary" />}
                label={label}
              />
            ))}
          </RadioGroup>
        </Grid>
        <Grid item xs={12}>
          <FormTextField fullWidth label="Title" required>
            <TextField
              name="title"
              value={title}
              onChange={handleChange}
              error={!!errors.title}
              helperText={errors.title}
              variant="outlined"
              fullWidth
              placeholder="Enter message title here"
              InputProps={{
                classes: {
                  root: 'medium',
                  input: 'size_16_500 medium',
                },
              }}
            />
          </FormTextField>
        </Grid>
        <Grid item xs={6} className="pr-10">
          <FormTextField fullWidth label="Start Date">
            <DatePicker
              name="startDate"
              value={startDate}
              onChange={(d) => handleChange({ target: { name: 'startDate', value: d } })}
              allowKeyboardControl
              disablePast={isNew}
              minDate={!isNew && startDate}
              autoOk
              format="DD MMM YYYY"
              inputVariant="outlined"
              InputProps={{
                classes: {
                  root: 'medium',
                  input: 'size_16_500',
                },
              }}
            />
          </FormTextField>
        </Grid>
        <Grid item xs={6} className="pl-10">
          <FormTextField fullWidth label="Start Time">
            <TimePicker
              name="startTime"
              value={startTime}
              onChange={(d) => handleChange({ target: { name: 'startTime', value: d } })}
              autoOk
              ampm
              inputVariant="outlined"
              InputProps={{
                classes: {
                  root: 'medium',
                  input: 'size_16_500',
                },
              }}
            />
          </FormTextField>
        </Grid>
        <Grid item xs={6} className="pr-10">
          <FormTextField fullWidth label="End Date">
            <DatePicker
              name="endDate"
              value={endDate}
              onChange={(d) => handleChange({ target: { name: 'endDate', value: d } })}
              error={!!errors.endDate}
              helperText={errors.endDate}
              minDate={startDate}
              allowKeyboardControl
              autoOk
              format="DD MMM YYYY"
              inputVariant="outlined"
              TextFieldComponent={(props) => {
                const isNever = endDate === 'never';
                return <TextField {...props} value={isNever ? 'Never' : props.value} />;
              }}
              InputProps={{
                classes: {
                  root: 'medium',
                  input: 'size_16_500',
                },
                endAdornment: 
                  endDate === 'never' ? null : (
                    <Tooltip title="reset">
                      <IconButton size="small" onClick={handleResetClick}>
                        <CloseRounded />
                      </IconButton>
                    </Tooltip>
                  ),
              }}
            />
          </FormTextField>
        </Grid>
        <Grid item xs={6} className="pl-10">
          <FormTextField fullWidth label="End Time">
            <TimePicker
              name="endTime"
              value={endTime}
              onChange={(d) => handleChange({ target: { name: 'endTime', value: d } })}
              error={!!errors.endTime}
              helperText={errors.endTime}
              disabled={endDate === 'never'}
              autoOk
              ampm
              inputVariant="outlined"
              TextFieldComponent={(props) => {
                const isNever = endDate === 'never';
                return <TextField {...props} value={isNever ? '-' : props.value} />;
              }}
              InputProps={{
                classes: {
                  root: 'medium',
                  input: 'size_16_500',
                },
              }}
            />
          </FormTextField>
        </Grid>
      </Grid>
      <Divider className="mx-n20" />
      <div className="mt-20">
        <InputLabel className="font_18_600 mb-20 text-0d0d0d" required>
          {TYPE_TITLE_MAP[dtype]}
        </InputLabel>
        {dtype === 'text' && (
          <TextField
            name="description"
            value={description}
            onChange={handleChange}
            error={!!errors.description}
            helperText={errors.description}
            variant="outlined"
            fullWidth
            multiline
            placeholder="Detail will go here..."
            InputProps={{
              classes: {
                input: 'size_16_500',
              },
            }}
            rows={4}
          />
        )}

        {dtype === 'document' && (
          <>
            <DialogPdfUploader
              classes={{ root: 'w-100' }}
              document={document}
              handleChange={handleDocument}
              errors={{ name: errors.document }}
              isName={false}
            />
            <FormHelperText error={!!errors.document}>{errors.document}</FormHelperText>
          </>
        )}

        {dtype === 'link' && (
          <TextField
            name="link"
            value={link}
            onChange={handleChange}
            fullWidth
            error={!!errors.link}
            helperText={errors.link}
            variant="outlined"
            type="text"
            InputProps={{
              classes: {
                root: 'medium',
                input: 'size_16_500 medium',
              },
            }}
          />
        )}

        {dtype === 'video' && (
          <>
            <MediaUploader
              media={media}
              handleChange={handleChange}
              handleMedia={handleMedia}
              removeMedia={removeMedia}
              doc={data}
              hideYoutube
              is_type_specific={true}
              thumbaspect={2 / 3}
              isNew={isNew}
              playerProps={{
                onDuration: setDuration,
                onReady: whenPlayerReady,
              }}
            />
            <FormHelperText error={!!errors.media}>{errors.media}</FormHelperText>
          </>
        )}
      </div>
    </Dialog>
  );
}
