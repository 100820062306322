import { useState, useCallback, useEffect, useContext, useMemo } from "react";
import _ from "lodash";
import firebase from "fitbud/firebase";
import { useSelector, useDispatch } from 'react-redux'
import trainerRdxFns from "fitbud/redux/trainer";
import { snapshotDocsArrayToDataArray } from "fitbud/utils/helpers";
import { FirebaseAuthContext } from "fitbud/providers/firebase-auth";

export const parseTrainer = (d, cid) => {
  const meta = _.get(d, `data.meta.${cid}`, {});
  if(!meta.role) meta.role = 'owner'
  if (!meta.name) meta.name = d.data.name || d.data.email.replace(/@.*/, '');
  return { data: { ...meta, email: d.data.email }, _id: d._id };
}

const RGX = /appstreet.io|iappstreet.com|fitbudd.com|pranav.iitd05@gmail.com/i;
const filter = cid => data => {
  if (!data || !data.cids || !data.cids.length || !data.email) return false;
  if (process.env.REACT_APP_ENV === 'production') {
    if (!cid.startsWith('ftbd') && cid !== 'ashish') // not one of the prod test companies
      if (data.email.match(RGX)) return false;
  }
  if (data.cids.includes('fitbud')) return false;
  // show everyone else
  return true;
};

const useTrainers = (_cid) => {
  const { cid, comp, primary_owner_id, userProfile } = useContext(FirebaseAuthContext);
  const { uid } = userProfile || {};
  const __cid = cid || _cid;
  const [loading, setLoading] = useState(false);
  const staffs = useSelector((state) => _.get(state, 'trainer.docs', []));

  const dispatch = useDispatch();
  const { set: setTrainer } = trainerRdxFns(dispatch);
  const _filter = useCallback(filter(__cid), [__cid]);

  const trainersAndOwners = useMemo(() => {
    const out = staffs.filter(({data, _id}) => {
      const role = data.role?.toLowerCase() || 'owner';
      // use roles for filter and role as fallback for backward compatibility
      const roles = data.roles?.length > 0 ? data.roles : [role];

      return _id === primary_owner_id || roles.includes("trainer") || roles.includes('owner');
    });


    return out;
  },[primary_owner_id, staffs])

  useEffect(() => {
    if (staffs && staffs.length) return;
    setLoading(true)
    firebase.firestore().collection("/staff").where('cids', 'array-contains', __cid).get()
      .then((resp) => {
        const data = snapshotDocsArrayToDataArray(resp.docs, true, _filter);
        const out = data.map(x => parseTrainer(x, __cid));
        setTrainer(out, 0, true)
        setLoading(false)
      }).catch(err => {
        console.log(err)
        setLoading(false)
      });
  }, [__cid, _filter, setTrainer, staffs]);

  const getTrainer = useCallback((id) => {
    const f = _.find(staffs, (t)=>t._id === id);
    return f || {};        
  }, [staffs]);

  const getAuthor = useCallback((id, { dm = false, reply = false } = {}) => {
    if (!id || !comp || !comp.exists) return {name: ''};
    const authorIsCurrentUser = uid === id;
    const { app_config, profile, features } = comp.data();
    const hasTeamFeature = features && features.trainers;
    let { staff_authors, staff_authors_internal, spcl_authors } = app_config || {};
    const { name: profileName, image: profileImage } = profile || {};

    if (!hasTeamFeature) { // without the teams feature
      if (reply) return { name: 'You' }; // Replies are YOU
      if (dm) return { name: '' }; // DMs don't need a name
      // for group chat
      return { name: profileName || '', image: profileImage || ''};
    }

    if (spcl_authors && !staff_authors_internal) {
      const {label: name, ...rest} = spcl_authors[id] || spcl_authors.anystaff || {}; 
      if (name) return {name, ...rest};
    }

    if (staff_authors || staff_authors_internal) {
      if (authorIsCurrentUser) return {name: 'You'};
      const doc = _.find(staffs, x => (x._id === id));
      if (doc) return {name: doc.data.name};
      else return {name: 'Team Member'};
    }

    return { name: profileName || '', image: profileImage || ''};
  }, [staffs, comp, uid]);

  return { loading, data: trainersAndOwners, staffs, getTrainer, getAuthor };
}

export default useTrainers;
