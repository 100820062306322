import React, { useContext, useMemo } from 'react';
import { Dialog, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { ACL_STATUS, useAcl } from 'fitbud/providers/acl-provider';
import Logo from 'fitbud/components/logo';
import _ from 'lodash';
import { openIntercom } from 'fitbud/utils/intercomm';
import { OverlayLogout } from '../overlay-logout';
import { ClrdButton } from '../form-fields';
import { FirebaseAuthContext } from 'fitbud/providers/firebase-auth';
import { useBillingContext } from 'fitbud/providers/billing-provider';
import { useSnackbar } from 'notistack';
import { HUBSPOT_PROPS } from 'fitbud/utils/constants';
import { ContractContext } from 'fitbud/providers/contract';
import { AnalyticsContext } from 'fitbud/providers/analytics';

const useBlockerStyles = makeStyles(() => ({
  root: {
    width: 400,
    borderRadius: '10px',
  },
  header: {
    height: 60,
    backgroundColor: '#000000',
  },
  content: {
    paddingTop: 40,
    paddingBottom: 40,
    backgroundColor: 'white',
  },
  btn: {
    minWidth: '200px !important',
  },
  contained_btn_link: {
    color: '#fff !important',
    '&:hover': {
      color: '#fff !important',
    },
    '&:active': {
      color: '#fff !important',
    },
  },
}));

export const FitbuddEmailSupport = (props) => {
  return (
    <div className="text-center">
      <span className="text-dark-grey font_15_500">Have questions? Email us at </span>&nbsp;
      <a className="font_15_600" href="mailto:support@fitbudd.com">
        support@fitbudd.com{' '}
      </a>
    </div>
  );
};

const AclOverlayBlocker = (props) => {
  const classes = useBlockerStyles();
  const { cid } = useContext(FirebaseAuthContext);
  const { manageSubscriptionUrl, status } = useAcl();
  const { openPricingOptions } = useBillingContext();
  const { enqueueSnackbar } = useSnackbar();
  const { contract } = useContext(ContractContext);
  const { overdue, limited } = contract || {};
  const { trackEvent } = useContext(AnalyticsContext);

  const onActivateMyAccount = () => {
    trackEvent({ name: HUBSPOT_PROPS.ACCOUNT_REACTIVATION_REQUEST, tools: ["hubspot"] });
    enqueueSnackbar('Awesome! Our team will reach out to you shortly to reactivate your account.', {
      variant: 'success',
    });
  };

  const info = useMemo(() => {
    if (status === ACL_STATUS.TRIAL_LIMITED) {
      return {
        text: 'Trial Expired',
        subText: 'Your FitBudd trial has ended. You can upgrade now to restore your access.',
      };
    } else if (status === ACL_STATUS.CANCELLED) {
      return {
        text: 'Oops! Your FitBudd Subscription has Expired.',
        subText:
          'Reactivate now to regain full access to all your favorite tools and features. Click the button below and our team will reach out to you shortly.',
      };
    } else if (status === ACL_STATUS.PLAN_LIMITED) {
      if (!overdue && limited) {
        return {
          text: 'Renew Your FitBudd Subscription!',
          subText:
            'Your FitBudd subscription has expired. Reactivate now to regain full access to all your favorite tools and features.',
        };
      } else {
        return {
          text: 'Account Suspended: Immediate Action Required',
          subText:
            'Your FitBudd account is currently suspended due to unpaid bills. Please clear your dues soon to regain access to your dashboard.',
        };
      }
    } else {
      return {
        text: 'Oops! Your FitBudd Subscription has Expired.',
        subText:
          'Reactivate now to regain full access to all your favorite tools and features. Click the button below and our team will reach out to you shortly.',
      };
    }
  }, [status]);

  const actions = useMemo(() => {
    if (status === ACL_STATUS.TRIAL_LIMITED) {
      return (
        <div className="align-items-center d-flex flex-column justify-content-center">
          <ClrdButton
            title="Proceed to Upgrade"
            variant="contained"
            className={clsx(classes.btn, 'font_15_700 f-medium mb-20')}
            color="primary"
            onClick={openPricingOptions}
          />
          <FitbuddEmailSupport />
        </div>
      );
    } else if (status === ACL_STATUS.CANCELLED) {
      return (
        <div className="align-items-center d-flex flex-column justify-content-center">
          <ClrdButton
            title="Yes, Reactivate My Account"
            variant="contained"
            className={clsx(classes.btn, 'font_15_700 f-medium mb-20')}
            color="primary"
            onClick={onActivateMyAccount}
          />
          <FitbuddEmailSupport />
        </div>
      );
    } else if (status === ACL_STATUS.PLAN_LIMITED) {
      return (
        <div className="align-items-center d-flex flex-column justify-content-center">
          {!manageSubscriptionUrl ? (
            <ClrdButton
              title="Contact Us"
              variant="contained"
              className={clsx(classes.btn, 'font_15_700 f-medium mb-20')}
              color="primary"
              onClick={openIntercom}
            />
          ) : (
            <ClrdButton
              component="a"
              href={manageSubscriptionUrl}
              title="Pay Now"
              variant="contained"
              className={clsx(classes.btn, 'font_15_700 f-medium mb-20')}
              color="primary"
            />
          )}
          <FitbuddEmailSupport />
        </div>
      );
    } else {
      return (
        <div className="align-items-center d-flex flex-column justify-content-center">
          <ClrdButton
            title="Yes, Reactivate My Account"
            variant="contained"
            className={clsx(classes.btn, 'font_15_700 f-medium mb-20')}
            color="primary"
            onClick={onActivateMyAccount}
          />
          <FitbuddEmailSupport />
        </div>
      );
    }
  }, [status, manageSubscriptionUrl, openIntercom]);
  return (
    <Dialog
      open disableEnforceFocus
      maxWidth="md"
      paperWidthMd
      style={{
        width: '100vw',
        height: '100vh',
        background: '#aaaaaaaa',
        backdropFilter: 'saturate(180%) blur(10px)',
      }}>
      <div className={clsx(classes.root)}>
        <div className={clsx(classes.header, 'd-flex justify-content-center')}>
          <Logo height={32} color="fancy-white" />
        </div>
        <div className={clsx(classes.content, 'd-flex flex-column justify-content-center align-items-center px-20')}>
          <Typography className="font_24_700 text-center ">{info.text}</Typography>
          <Typography className="text-15-500 text-dark-grey mt-20 text-center lineht_1_5 ">{info.subText}</Typography>
          {info.infoText && (
            <Typography className="font_15_500 text_dark_grey text-center mt-20">{info.infoText}</Typography>
          )}
          <div className="d-flex justify-content-center align-items-center mt-20">{actions}</div>
        </div>
        <OverlayLogout />
      </div>
    </Dialog>
  );
};

export default AclOverlayBlocker;
