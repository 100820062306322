import _ from 'lodash';
import React, { createContext, useMemo, useState, useEffect, useContext, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { FirebaseAuthContext } from 'fitbud/providers/firebase-auth';
import { ContractContext } from 'fitbud/providers/contract';
import GTM from 'fitbud/utils/gtm';
import TeeZed from 'fitbud/providers/tz';
import { initAmplitude, setProperty } from "fitbud/utils/amplitude";
import { bffUpdateHubspotProp } from 'fitbud/api';
import {trackEvent as trackAmplitudeEvent} from 'fitbud/utils/amplitude';
import { ANALYTICS_MAPPING } from 'fitbud/utils/constants';

const XXX = 'Unknown';
const YYY = 'Staff';
const CTYPE = { TRIAL: 'Trial', SILVER: 'Silver', GOLD: 'Gold', GTENT: 'GTent', PRO: 'PRO', SUPERPRO: 'SUPERPRO', PLATINUM: 'PLATINUM' };
const portal = process.env.REACT_APP_INTERCOM_DOMAIN || 'Development';
const TRACKING_TOOLS = ['amplitude', 'hubspot', 'appcues', 'ga'];

const AnalyticsContext = createContext({});
AnalyticsContext.displayName = 'AnalyticsContext';

function AnalyticsProvider({ children }) {
  const { cid, authUser: { uid, email } = {}, userProfile: { name }, isFBStaff, comp } = useContext(FirebaseAuthContext);
  const stats = useSelector((state) => state.home);
  const { contract } = useContext(ContractContext);
  const [dimensions, setDimensions] = useState(null);
  const { app, contract: compContract } = comp.exists ? comp.data() : {};
  const isStaff = useMemo(() => _isStaff(isFBStaff, email), [isFBStaff, email]);
  const ctype = useMemo(() => (isStaff ? YYY : _cType(app, contract, compContract)), [isStaff, app, contract, compContract]);
  const cycle = useMemo(() => _billingCycle(contract), [contract]);
  const clients = useMemo(() => _clients(stats), [stats])
  const event = useCallback((name, event_label = '', dims = {}) => {
    const eventDims = _.mapKeys(dimensions, (v, k) => (`event_${k}`));
    if (event_label) eventDims.event_label = event_label;
    if (typeof window === 'undefined') return;
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({event: name, ...eventDims, ...dims});
  }, [dimensions])

  /**
   * Tracks an event using specified tracking tools.
   *
   * @param {Object|string} params - The event parameters or event name.
   * @param {string} params.name - The name of the event.
   * @param {*} [params.value] - The value associated with the event.
   * @param {Object} [params.properties] - Additional properties of the event.
   * @param {Array} [params.tools=TRACKING_TOOLS] - The tracking tools to use.
   */
  const trackEvent = useCallback((params) => {
    
    const { name, value, properties = {}, tools = TRACKING_TOOLS } = (function() {
      if(typeof params === 'string') return { name: params };
      return params;
    })()
    let mname = ANALYTICS_MAPPING[name]?.event || name;
    let mproperties = { ...(ANALYTICS_MAPPING[name]?.params || {}), ...properties };
    if(tools.length === 0) return;

    if(tools.includes('amplitude') || tools.includes('ga') || tools.includes('appcues')) {
      trackAmplitudeEvent(mname, mproperties);

      if (window.Appcues) {
        // flattened event for appcues
        const { module, entity, field, type, action, channel_type} = mproperties;
        const amname = [mname, module, entity, field, channel_type, type, action].filter(Boolean).join('_');
        // console.log('------- appcues event -------', amname);
        window.Appcues.track(amname, mproperties);
      }
    
      event(mname, mproperties);
    }
  
    if(tools.includes('hubspot')){
      bffUpdateHubspotProp(name, value).catch(console.error);
    }
  }, [event]);

  useEffect(() => {
    if (typeof window === 'undefined') return;
    window.dataLayer = window.dataLayer || [];
    const dims = { portal, cid, uid, ctype, cycle, clients };
    window.dataLayer.push(dims);
    setDimensions(dims);
  }, [cid, uid, email, isStaff, ctype, clients, cycle]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // Initialize Amplitude and set cid property
    initAmplitude(uid, email);
    setProperty("cid", cid);
    setProperty("ctype", ctype);
  },[uid, cid, email]);

  return (
    <AnalyticsContext.Provider value={{ cid, uid, email, name, comp, dimensions, event, trackEvent }}>
      <GTM defer={!dimensions}/>
      {children}
      <TeeZed/>
    </AnalyticsContext.Provider>
  );
}

export { AnalyticsProvider, AnalyticsContext };

/* const path2title = path => {
  if (typeof path !== 'string') return 'unknown-page';
  if (path === '/') return 'home';
  return path.replace(/^\//, '').split('/').map(_.capitalize).join(' » ')
};*/

const _isStaff = (isStaff, email) => {
  if (isStaff) return true;
  if (email) {
    return email.endsWith('@appstreet.io') ||
      email.endsWith('@iappstreet.com') ||
      email.endsWith('@fitbudd.com') ||
      email.endsWith('@yopmail.com');
  }
  return false;
};

const _cType = (app, contract, compContract) => {
  if (contract?.meta?.current && contract.status === 'activated' && contract.meta?.current.productName === 'Gold') return CTYPE.GOLD;
  if (contract?.meta?.current && contract.status === 'activated' && contract.meta?.current.productName === 'Super Pro') return CTYPE.SUPERPRO;
  if (contract?.meta?.current && contract.status === 'activated' && contract.meta?.current.productName === 'Pro') return CTYPE.PRO;
  if (contract?.meta?.current && contract.status === 'activated' && contract.meta?.current.productName === 'Silver') return CTYPE.SILVER;
  if (contract?.meta?.current && contract.status === 'activated' && contract.meta?.current.productName === 'Initiate') return CTYPE.SILVER;
  if (contract?.meta?.current && contract.status === 'activated' && contract.meta?.current.productName === 'Platinum') return CTYPE.SILVER;
  if (contract?.meta?.current && contract.status === 'activated' && contract.meta?.current.productName === 'Elite') return CTYPE.PLATINUM;
  if (contract?.meta?.future && contract.status === 'activated') return CTYPE.GTENT;
  if (contract && contract.status === 'trial') return CTYPE.TRIAL;
  if (!contract) return XXX;
  return CTYPE.SILVER;
}

const _billingCycle = (contract) => _.get(contract, 'meta.recurring_interval', XXX);

const _clients = (stats) => {
  if (!stats) return 0;
  const { activeUsers, plansToAssign } = stats;
  return (activeUsers || 0) + (plansToAssign || 0);
};