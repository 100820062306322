import { useAI, AI_FORMS } from "fitbud/providers/ai-provider";
import React, { useEffect, useState, useCallback } from "react";
import moment from "moment";
import WorkoutAIForm from "./forms/workoutForm";
import send_icon from "fitbud/images/send_icon.svg";
import {
  IconButton,
  Drawer,
} from "@material-ui/core";
import WoDetails from "fitbud/views/workouts/form";
import Dialog from "fitbud/components/Dialog";
import CloseIcon from "@material-ui/icons/CloseRounded";
import _ from 'lodash';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import CircularLoader from "fitbud/components/CircularLoader";
import { ClrdButton } from "fitbud/components/form-fields";
import FitbotMessage from "./message";
import { makeStyles } from '@material-ui/core/styles';
import MagicIcon from "fitbud/icons/MagicIcon";

const useStyles = makeStyles(() => ({
        buttonCustom: {
          '& .MuiButton-label': {fontSize: '13px', fontWeight: '100'}, // sub-selector
        }
    }));

const Fitbot = (props) => {
  // create a floating chat window for the bot using react and bootstrap 4 classes

  // create the floating container for the chat window
  // create the chat window with a header, body and footer

  // create the chat window header with a close button
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [ showHistory, setShowHistory ] = useState(false);
  const [ generated, setGenerated ] = useState(null);
  const [ inputText, setInputText ] = useState(null);
  // check in the AIContext if the chat is open or not
  const { openChat, toggleChat, messages, formData = {}, messageData = {}, editMessageData = {}, updateContext, saveMessage, updateMessageGeneratedContentRef } = useAI();
  const recordSave = async (doc) => {
    console.log('Recording save in message if messageData', messageData, doc);
    await updateMessageGeneratedContentRef(doc.data().sourceAiSessionId, doc.data().sourceAiMessageId, doc.ref.path);
    updateContext({messageData: null});
    // use router to redirect to `/fitness/workouts/{doc.id}
    if (messageData.meta?.type === 'workout') {
      return history.push(`/fitness/workouts/${doc.id}`);
    }
    if (messageData.meta?.type === 'woschedule') {
      return history.push(`/fitness/schedules/${doc.id}`);
    }
  };
  useEffect(() => {
    const streamData = generated;
    if (!generated) return;
    streamData.sourceMessageId = messages[messages?.length - 1]._id;
    if (streamData?.status === 'info_required') {
      console.log('info required');
      saveMessage({ meta: streamData, message: streamData.message, type: 'text' }, true);
      updateContext({ messageData: null });
    } else {
      console.log("received schedule");
      saveMessage({message: streamData.message, type: 'text',  meta: streamData }, true).then((saved) => 
        updateContext({ messageData: { _id: saved._id, _session: saved._session, meta: streamData, message: streamData.message, type: 'text', edit: true, t: new Date().getTime() }, formData: null })
      );
    }
    setGenerated(null);
    setLoading(false);
  }, [generated, saveMessage, updateContext, messages]);

  const handleResult = useCallback(async res => {
    const streamData = JSON.parse(res.data.split('\n').slice(-2)[0]);
    setGenerated(streamData);
  
  }, [setGenerated]);

  const formSubmit = useCallback(async (form) => {
    // save message to conversations and call ai service
    saveMessage({ message: form.note, type: 'text', meta: { type: 'form', data: form}}, false).then(async () => {
      let i = 0;
      setLoading(true);
      const { note, history = null, ...formData } = form;
      return axios({ 
        url: `${process.env.REACT_APP_AI_SERVICE_URL}/fitbot/stream`, 
        method: 'POST',
        data: {
          prompt: { 
            body: note,
            type: 'workout', // TODO take from form.
            history: history || null,
          },
          // userInfo: getUserDetails(props),
          userInfo: formData,
        },
        responseType: 'stream', 
        onDownloadProgress: async progressEvent => {
          i++
          console.log('chunks count', i);
        //; // await Promise.resolve({data: _.cloneDeep(sampleAIRes)}); 
          // console.log(progressEvent.currentTarget.response);
          const chunk = progressEvent.currentTarget.response.split('\n').slice(-2)[0];
          console.log(chunk);
          try {
            const streamData = JSON.parse(chunk.toString().trim());
            if (streamData.status === 'info_required') {
              console.log('info required stream', streamData);
              setLoading(false);
              return;
            }
            console.log('download progress', streamData);
            const m = { message: 'Generating workout', type: 'text', meta: streamData, t: new Date().getTime(), edit: true };
            console.log('download progress messageData', m);
            const workout = getWorkout(streamData, {}, []);

            updateContext({ formData: null, messageData: m });

            console.log(workout);
            return workout;
          } catch(e) { console.error(e); }
        },
      }).then(handleResult);
    });

  }, [saveMessage, updateContext, handleResult]);

  const closeAll = useCallback(async () => {
    toggleChat();
    updateContext({ messageData: null, formData: null });
  }, [ toggleChat, updateContext ]);

  const onEditOpen = useCallback(async () => {
    // change messageData to null and copy messageData to editMessageData also highlight messageData and editMessageData in chat window.
    updateContext({ messageData: {...messageData, edit: true}});
  }, [updateContext, messageData]);

  // if chat is not open then show floating AI icon by using magic icon from bootstrap
  if (!openChat) return <div className="position-fixed" style={{ bottom: '90px', right: '0px', zIndex: 10000, }}>
    <button className="btn btn-primary rounded-circle larg color-white p-10" onClick={toggleChat}>
      <MagicIcon fill="white" width="24px" height="24px" />
    </button>
  </div>;
  
  return <div className="position-fixed" style={{ overflow: 'hidden', width: 'min(360px, 100vw)', bottom: 0, right: (messageData?.meta?.type === AI_FORMS.WORKOUT && !messageData?.edit ? 'min(490px, 100vw)' : '0px'), zIndex: 10000, maxHeight: "70vh" }}>
    {/** header **/}
    <FitbotHeader close={closeAll} toggleShowHistory={() => setShowHistory((s) => !s)} />
    {/** body **/}
    { showHistory
      ? <FitbotSessionHistory toggleShowHistory={() => setShowHistory((s) => !s)} setText={setInputText} />
      : <FitbotBody toggleShowHistory={() => setShowHistory((s) => !s)} messages={messages} setText={setInputText} />
    }

    
    {/** footer **/}
    { loading && <Dialog fullScreen open><CircularLoader /></Dialog>}
    <FitbotModal form={formData} messageData={messageData} editMessageData={editMessageData} onEditOpen={onEditOpen} recordSave={recordSave} onSubmit={formSubmit} /> {/** TO OPEN Forms and overlay based on selected message or CTA **/}
    {!showHistory && 
      <FitbotFooter 
        sourcem={messages?.length && messages[messages.length - 1]} form={formData} onSubmit={formSubmit} saveMessage={saveMessage} text={inputText}  
        setText={setInputText} showScaleUpDown={messages.slice(-1)?.[0]?.meta?.type === AI_FORMS.WORKOUT}
      />
    }
  </div>;
};

const FitbotHeader = (props) => {
  return <div className="border-radius-25 d-flex flex-row justify-content-between p-10" style={{ background: '#317FF5', color: 'white', borderRadius: '20px 20px 0 0' }}>
    <h5 className="m-10"><MagicIcon widht="24px" height="24px" fill="white" />Fitbot</h5>
    <div>
      <IconButton type="button" onClick={props.close} className="close mt-8" aria-label="Close">
        <CloseIcon className="text-white" />
      </IconButton>
      {/** History icon */}
      <IconButton type="button" onClick={props.toggleShowHistory} className="ml-auto" aria-label="history">
        <i className="fa fa-history fa-sm text-white"></i>
      </IconButton>
    </div>
  </div>;
};
const FitbotSessionHistory = (props) => {

  const { sessionHistory = [], loadSessionHistory = () => {}, switchSession, updateContext } = useAI();
  useEffect(() => {
    loadSessionHistory();
  }, []);
  return <div className="card-body border h-90 bg-white" style={{maxHeight: 'calc(70vh - 160px)', overflow: 'scroll' }}>
    <div>
      <ClrdButton className="border rounded-20" variant="outline" onClick={() => { updateContext( { formData: { type: AI_FORMS.WORKOUT }, currentSession: null }); props.toggleShowHistory();} } >Create New Workout</ClrdButton>
    </div>
    {!sessionHistory?.length && <div>No Past sessions</div>}
    {sessionHistory.map(session => {
      return (
      <div className="border m-10 p-10 user-select-all cursor-pointer" onClick={() => { switchSession(session.id); props.toggleShowHistory(); props.setText("") } }>
        {'AI Session on'} {moment.unix(session.data().context?._cat?.seconds).format('h:mm A dd MMM YY')}
      </div>
      );
    })}
  </div>

};

const FitbotModal = (props) => {
  // load the respective form based on form.type if set
  // load the respective generated content viewer based on messageData.type it can have link to saved content or draft generated data.  
  const { updateContext, updateMessageGeneratedContentRef } = useAI();
  if (props.form?.type === AI_FORMS.WORKOUT) {
    return <WorkoutAIForm doc={props.form.data || {}} onSubmit={props.onSubmit} handleClose={() => updateContext({ formData: {}})} />;
  }
  console.info('messageData', props.messageData);
  const close = () => { updateContext({ messageData: null }); };

  if (props.messageData?.meta?.type === AI_FORMS.WORKOUT || props.editMessageData?.meta?.type === AI_FORMS.WORKOUT) {
    const items = {};
    const nw = [];
    const workout = getWorkout(props.messageData.meta.data, items, nw);
    const Container = props.messageData.edit ? Dialog : Drawer;
    const containerProps = {};
    if (!props.messageData.edit) containerProps.anchor = "right";
    return <Container open onClose={close} {...containerProps}>
      {
        <WoDetails
          scheduleDoc={workout}
          grpData={null}
          id={"" + new Date().getTime()}
          edit={props.messageData.edit}
          onEditOpen={props.onEditOpen}
          grpIndex={0}
          aiDraft
          onCancel={close}
          aiMessageId={props.messageData._id}
          aiSessionId={props.messageData._session}
          onSave={props.recordSave}
        />
      }
    </Container>;
  } else if (props.messageData?.meta?.type === 'form'){
    return <WorkoutAIForm doc={props.messageData.meta.data || {}} onSubmit={props.onSubmit} handleClose={close} />;
  }
  return <div></div>;
};

const FitbotBody = ({ messages, ...rest }) => {
  const { updateContext, currentSession } = useAI();
  {/* create the chat window body with messages and add border to the body */}

  return <div id={currentSession?.path} className="card-body border pb-100 overflow-scroll h-90 bg-white" style={{maxHeight: 'calc(70vh - 160px)', overflow: 'scroll'}}>
    {!messages?.length && <div>
      <div>Select a previous session from session history or create a new workout</div>
      <div>
        <ClrdButton className="rounded-20 border m-5" variant="outline" onClick={() => updateContext({ formData: { type: AI_FORMS.WORKOUT } })}>Create workout session</ClrdButton>
        <ClrdButton className="rounded-20 border m-5" variant="outline" onClick={rest.toggleShowHistory}>View History</ClrdButton>
      </div>
    </div>}
    {messages?.map((msg, i) => <FitbotMessage key={i} message={msg} onClick={updateContext} />)}
  </div>;
};

const FitbotFooter = (props) => {
  const [ message, setMessage ] = useState("");
  const { sourcem, text, onSubmit, ...rest } = props;
  const classes = useStyles();
  const formData = sourcem && message && {
    note: message,
    history: [
      { role: "user", content: sourcem.meta.promptMessage || null },
      { role: "assistant", content: sourcem.meta.rawResponse || null }
    ]
  }
  useEffect(() => {
    setMessage(text);
  }, [text]);
  return <div className="d-flex flex-column bg-light p-10 border absolute">
    <div className="mb-10">
      {rest.showScaleUpDown && <>
        <ClrdButton className={`rounded-20 border mr-10 bg-white ${classes.buttonCustom}`} variant="outline" onClick={() => rest.setText("Scale up the workout by increasing sets")}>Scale up</ClrdButton>
        <ClrdButton className={`rounded-20 border bg-white ${classes.buttonCustom}`} variant="outline" onClick={() => rest.setText("Scale down the workout by decreasing sets")}>Scale down</ClrdButton>
        <ClrdButton className={`rounded-20 border bg-white ${classes.buttonCustom}`} variant="outline" onClick={() => rest.setText("Add one more segment")}>Add segments</ClrdButton>
      </>}
    </div>
    <div className="d-flex flex-row align-items-center justify-content-between">
      <input type="text" className="form-control p-13 border-none w-80" style={{ borderRadius: '30px', fontSize: '13px', height: '40px' }} placeholder="eg. Focus on improving balance.." value={message} onChange={rest.showScaleUpDown ? (e) => rest.setText(e.target.value) : null} />
      <IconButton  
        color="primary"
        className={"fml-10 p-11 border"}
        classes={{root: "bg-primary"}}
        aria-label="send"
        disabled={!sourcem && !message } 
        onClick={() => {onSubmit(formData); setMessage("")} }
      >
        <img style={{height: '16px', width: '16px' }} src={send_icon} alt="sendIcon" />
      </IconButton>
    </div>
  </div>;
};

const getWorkout = (w, items = {}, nw = []) => {
  let l = Object.keys(items).length + "";
  l = ( w.title || w.name)?.toLowerCase().startsWith('rest') ? 'rest': l;
  items[l] = w;
  w.id = 1;
  w.type = "workout";
  w.ref_name = w.title || w.name;
  w.temporary = true;
  
  nw.push(l);
  return w;
};

const getSchedule = (data) => {
  const weeks = _.filter(data, (v, k) => k?.startsWith('week') && v);
  const finalTitle = data.title;
  const items = {};
  console.log(weeks.length);
  const weeksN = {};
  weeks.forEach((week, i) => {
    console.log(week, i);
    const weekN = {};
    Object.keys(week).forEach(d => {
      console.log(d);
      let nw = [];
      week[d].wo.forEach(getWorkout);
      weekN[getDaysText(d)] = { wo: nw };
    });
    weeksN[`w${i+1}`] = weekN;
  });
  const sched = {
    title: finalTitle,
    "duration": 7 * weeks.length,
    "durationType": "weeks",
    "ref_name": "Starter Schedule - Sample",
    "data": weeksN,
  };
  return { sched, items };
}
const getDaysText = (d) => {
  const mapping = {
    "monday": "d1",
    "tuesday": "d2",
    "wednesday": "d3",
    "thursday": "d4",
    "friday": "d5",
    "saturday": "d6",
    "sunday": "d7",
    "1": "d1",
    "2": "d2",
    "3": "d3",
    "4": "d4",
    "5": "d5",
    "6": "d6",
    "7": "d7"
  }
  return mapping[d.toLowerCase()] || d;
}


export default Fitbot;
