/**
 * 
 * <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14 0L14.5812 3.52863C15.4173 8.60444 19.3956 12.5827 24.4714 13.4188L28 14L24.4714 14.5812C19.3956 15.4173 15.4173 19.3956 14.5812 24.4714L14 28L13.4188 24.4714C12.5827 19.3956 8.60444 15.4173 3.52864 14.5812L0 14L3.52863 13.4188C8.60444 12.5827 12.5827 8.60444 13.4188 3.52864L14 0Z" fill="#317FF5"/>
</svg>

 */
import React from 'react';

const MagicIcon = props => {
  const allProps = {
    width: 28,
    height: 28,
    viewBox: '0 0 28 28',
    xmlns: 'http://www.w3.org/2000/svg',
    ...props,
  }
  return (
    <svg {...allProps}>
      <path 
        d="M14 0L14.5812 3.52863C15.4173 8.60444 19.3956 12.5827 24.4714 13.4188L28 14L24.4714 14.5812C19.3956 15.4173 15.4173 19.3956 14.5812 24.4714L14 28L13.4188 24.4714C12.5827 19.3956 8.60444 15.4173 3.52864 14.5812L0 14L3.52863 13.4188C8.60444 12.5827 12.5827 8.60444 13.4188 3.52864L14 0Z"
        fill={ props.fill || "#317FF5" }
      />
      <path 
        d="M22.5 20L22.6038 20.6301C22.7531 21.5365 23.4635 22.2469 24.3699 22.3962L25 22.5L24.3699 22.6038C23.4635 22.7531 22.7531 23.4635 22.6038 24.3699L22.5 25L22.3962 24.3699C22.2469 23.4635 21.5365 22.7531 20.6301 22.6038L20 22.5L20.6301 22.3962C21.5365 22.2469 22.2469 21.5365 22.3962 20.6301L22.5 20Z"
        fill={ props.fill || "#317FF5" }
      />
    </svg>
  );
};

export default MagicIcon;
