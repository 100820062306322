import React, { useState, useMemo, useContext, createContext } from "react";
import {
  Popover,
  Typography,
  makeStyles,
  Select,
  OutlinedInput,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Divider,
  Switch,
  Collapse,
  Tooltip,
} from "@material-ui/core";
import { ClrdButton, FormTextField } from "fitbud/components/form-fields";
import DropDownIcon from "@material-ui/icons/ExpandMore";
import NumberInput from "fitbud/components/numUnitInput";
import { FirebaseAuthContext } from 'fitbud/providers/firebase-auth';
import clsx from "clsx";
import _ from "lodash";
import { Tempo } from "./tempo";
import { EditorContext } from "fitbud/views/workouts/exerciseEditor";
import { useSnackbar } from "notistack";
import { DurationWrapper } from "fitbud/views/workouts/components/amrapDurationInput";
import {MAX_REP_VALUE} from "fitbud/utils/constants";

// const DURATION_VALID_MSG="Please enter a valid duration of the exercise";
// const REPS_VALID_MSG="Please enter valid repetitions of the exercise";
const TEMPO_ERROR = "Please enter valid Tempo value";
const AMRAP_NO_PER_SET=10;

const PRIMARY_TRACKING_OPTIONS = [
  { label: "Repetitions", value: "reps" },
  { label: "Duration", value: "duration" },
  { label: "Distance", value: "distance" },
  { label: "Meters", value: "meters" },
  { label: "Calories", value: "cals" },
];
const TRACKING_VARIATIONS = [
  { label: "Fixed", value: "fixed" },
  { label: "Range", value: "range" },
  { label: "Per Set", value: "perset" },
];
const TRACKING_VARIATIONS_AMRAP = [
  { label: "Fixed", value: "fixed" },
  { label: "Range", value: "range" },
  { label: `Per Set (assuming a max of ${AMRAP_NO_PER_SET} sets)`, value: "perset" },
];

const SECONDARY_TRACKING_NAMES = { weight: 'Weight', rm: '1RM', speed: 'Speed', pace: 'Pace'};
const SECONDARY_TRACKING_TARGET = { weight: 'Weight', rm: '1RM percentage', speed: 'Speed', pace: 'Pace'};

export const SECONDARY_TRACKING_OPTIONS = {
  repsOld: ['weight'].map(value => ({value, label: SECONDARY_TRACKING_NAMES[value]})),
  reps: ['weight', 'rm'].map(value => ({value, label: SECONDARY_TRACKING_NAMES[value]})),
  duration: ['weight', 'speed', 'pace'].map(value => ({value, label: SECONDARY_TRACKING_NAMES[value]})),
  distance: ['weight', 'speed', 'pace'].map(value => ({value, label: SECONDARY_TRACKING_NAMES[value]})),
  meters: ['weight', 'speed', 'pace'].map(value => ({value, label: SECONDARY_TRACKING_NAMES[value]})),
  cals: [],
};

export const OMIT_SECONDARY_TRACKING_FIELDS = ["type2", "male2", "female2"];

const styles = makeStyles((props) => ({
  paper: {
    maxHeight: "70vh",
    minHeight: "250px",
    padding: "25px 20px",
  },
  valueInput: {
    width: (props) =>
      props.numFields >= 3 ? "calc(33.33% - 10px)" : "calc(50% - 7.5px)",
  },
}));

const trimArrayEnd = vals => {
  let out = [];
  if (!vals || !vals.length) return [0];
  let done = false;
  for (let i = vals.length - 1; i >= 0; i--) {
    const val = vals[i];
    if (done) out = [val || 0, ...out];
    else if (!val) continue;
    else {
      out = [val, ...out];
      done = true;
    }
  }
  if (!out.length) out.push(0);
  return out;
};

const PopoverAttr = (classes) => {
  return {
    classes: {
      paper: `vlarge ${classes.paper}`,
    },
    anchorOrigin: {
      vertical: "top",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "right",
    },
  };
};

export const createTrackFromExr = (exercise) => {
  const array = exercise.ref_type.split("_");
  if (array.length > 1) array.splice(1, 1);
  return [...array];
};

// initial state
export const createNewExStateFromOld = ({exercise, exData={},isEdit=false}) => {
  const initialValues = {
    //REQUIRED FIELDS
    ref_type: exercise.ref_type || "",
    type: exercise.type || "",
    values: _.cloneDeep(exercise.values) || [],
    side:
      !!exercise.side || _.isNull(exercise.side)
        ? exercise.side
        : exData.side || (isEdit ? "none" : ""),
    track: exercise.track || createTrackFromExr(exercise),
    gender: exercise.gender || false,
    male1: exercise.male1 || _.cloneDeep(exercise.values),
    tempo: exercise.tempo ?
      (isEdit ? exercise.tempo.split("") : exercise.tempo)
      :(isEdit ? ["", "", "", ""]: null)
  };
  //SECONDARY FIELDS
  if (exercise.type2) {
    initialValues["type2"] = exercise.type2;
    initialValues["male2"] = exercise.male2;
  }
  if(!exercise.type2 && !!_.get(exercise,"weights",[]).length){
    initialValues["type2"] = exercise.weight_type;
    initialValues["male2"] = exercise.weights;
  }
  //GENDER FIELDS
  if (!!exercise.gender) {
    initialValues["female1"] = exercise.female1;
    if (exercise.type2) {
      initialValues["female2"] = exercise.female2;
    }
  }
  return { ...initialValues };
};

const getTypeValues = (value, numOfSets) => {
  switch (value) {
    case "fixed":
      return [0];
    case "range":
      return [0, 0];
    case "perset":
      return Array(numOfSets).fill(0);
    default:
      throw new Error(value);
  }
};
const isTempoValid = (tempo) => {
  const validTempo = tempo.filter((i) => !!i);
  if (validTempo.length === 0) return "empty";
  if (validTempo.length > 0 && validTempo.length < 4) return false;
  return true;
};
export const getRefTypeFromTrack = (track,grpType) => {
  const [primary, secondary] = track;
  let ref_type = "";
  if(grpType==="tabata"){
    ref_type="duration";
  }
  else{
    switch (primary) {
      case "reps": {
        ref_type = "reps";
        break;
      }
      case "duration":
      case "distance":
      case "meters":
      case "cals": {
        ref_type = "duration";
        break;
      }
      default:
        throw new Error(primary);
    }
  }
  if (ref_type === "reps" && (secondary === "weight" || secondary === "rm")) {
    ref_type = "reps_w_weight";
  }
  return ref_type;
};
const getValuesFromMale1 = (male1, male2 = [], track, grpType) => {
  const [primary, secondary] = track;
  switch (primary) {
    case "reps":
    case "duration":
      return [...male1];
    case "meters":
      //---exception:`values` for new Tracking fields in armap will be arrays of 10.
      if(grpType==="amrap"||grpType==="emom"){
        return [...male1].map(i=>10);
      }
      if (!secondary || !male2.length)
        return [...male1].map((i) => _.round((i * 3.6) / 5));
      if (secondary === "speed")
        return [...male1].map((i) => _.round((i * 3.6) / male2[0]));
      if (secondary === "pace")
        return [...male1].map((i) => {
          return _.round((i * male2[0]) / 1000)
        });
      break;
    case "distance":
      //---exception:`values` for new Tracking fields in armap will be arrays of 10.
      if(grpType==="amrap"||grpType==="emom"){
        return [...male1].map(i=>10);
      }
      if (!secondary || !male2.length)
        return [...male1].map((i) => _.round((i * 3600) / 5));
      if (secondary === "speed")
        return [...male1].map((i) => _.round((i * 3600) / male2[0]));
      if (secondary === "pace")
        return [...male1].map((i) => {
          return _.round(i * male2[0])
        });
      break;
    case "cals":
      //---exception:`values` for new Tracking fields in armap will be arrays of 10.
      if(grpType==="amrap"||grpType==="emom"){
        return [...male1].map(i=>10);
      }
      return [...male1].map((i) =>_.round(i * 60));
    default:
      throw new Error(primary);
  }
};
const ValueContext = createContext({});
//exData used as side backup (if exercise.side===""->use backup side)
export const ValuePopup = ({
  anchor,
  handleClose,
  onSave,
  loading,
  disabled,
  props,
  exercise = {},
  exData = {},
  ...rest
}) => {
  const classes = styles();
  const { enqueueSnackbar } = useSnackbar();
  const [state, updateState] = useState(createNewExStateFromOld({exercise, exData,isEdit:true}));
  const [errors, updateError] = useState({});
  const { group = {} } = props;
  const numOfSets = group.sets;
  const grpType = group.type;
  const updateFn = (e) => {
    const { name, value, id } = e.target;
    const v = name || id;
    switch (v) {
      case "track-1":
      case "track-2": {
        let currentState = _.cloneDeep(state);
        const track = [...state.track];
        if (v === "track-1") {
          currentState.male1=state.male1.map(i=>0);
          if(state.gender){
            currentState.female1=state.female1.map(i=>0);
          }
          track[0] = value;
          if (!!track[1]) {
            const newSecTrkOptions = [...SECONDARY_TRACKING_OPTIONS[value]];
            const isCurrentSecTrkValid = newSecTrkOptions.find(
              (i) => i.value === track[1]
            );
            if (!isCurrentSecTrkValid) {
              track.pop();
              //delete other secondary fields
              currentState["track"] = [...track];
              currentState = _.omit(
                currentState,
                OMIT_SECONDARY_TRACKING_FIELDS
              );
            } else {//sec tracking valid
              currentState["track"] = [...track];
            }
          } else {
            currentState["track"] = [...track];
          }
        } else if (v === "track-2") {
          track[1] = value;
          currentState["track"] = [...track];
          if(state.type2 && !!_.get(state,"male2",[]).length){
            currentState.male2=state.male2.map(i=>0);
            if(state.gender){
              currentState.female2=state.female2.map(i=>0);
            }
          }
          if (value === 'rm') {
            currentState.male2 = [0];
            currentState.type2 = "fixed";
            if (state.gender)
              currentState.female2 = [0];
          }
        }
        updateState(currentState);
        break;
      }
      case "type":
      case "type2": {
        const chng = {
          [v]: value,
        };
        if (v === "type") {
          chng["male1"] = [...getTypeValues(value, numOfSets)];
          if (state.gender)
            chng["female1"] = [...getTypeValues(value, numOfSets)];
        } else if (v === "type2") {
          chng["male2"] = [...getTypeValues(value, numOfSets)];
          if (state.gender)
            chng["female2"] = [...getTypeValues(value, numOfSets)];
        }
        updateState((o) => ({
          ...o,
          ...chng,
        }));
        break;
      }
      case "secondary-tracking": {
        const value = e.target.checked;
        if (!!value) {
          const [primTrk] = state.track;
          const secTrk = _.get(
            SECONDARY_TRACKING_OPTIONS[primTrk],
            [0, "value"],
            null
          );
          if (!!secTrk) {
            const newTrack = [primTrk, secTrk];
            updateState((o) => ({
              ...o,
              track: [...newTrack],
            }));
          }
        } else {
          const currentState = _.omit(state, OMIT_SECONDARY_TRACKING_FIELDS);
          currentState["track"].pop();
          updateState({ ...currentState });
        }
        break;
      }
      case "specify-target": {
        const value = e.target.checked;
        if (!!value) {
          const chng = {
            male2: [0],
            type2: "fixed",
          };
          if (state.gender) {
            chng["female2"] = [0];
          }
          updateState((o) => ({
            ...o,
            ...chng,
          }));
        } else {
          const currentState = _.omit(state, ["type2", "male2", "female2"]);
          updateState({ ...currentState });
        }
        break;
      }
      case "side":
      case "tempo": {
        updateState((o) => ({
          ...o,
          [v]: value,
        }));
        break;
      }
      case "gender": {
        const value = e.target.checked;
        let currentState = _.cloneDeep(state);
        currentState["gender"] = value;
        if (value) {
          currentState["female1"] = [...currentState["male1"]];
          if (state.type2) {
            currentState["female2"] = [...currentState["male2"]];
          }
        } else {
          currentState = _.omit(currentState, ["female1", "female2"]);
        }
        updateState(currentState);
        break;
      }
      default: {
        const split = v.split("_");
        const name = split[0];
        const newValues = [...state[name]] || [];
        const valueIndex = split[2];
        newValues[valueIndex] = Number(value);
        updateState((o) => {
          const _n={
            ...o,
            [name]: (grpType === 'amrap' && ['male1', 'female1', 'male2', 'female2'].includes(name) && o.type === 'perset') ? trimArrayEnd(newValues) : [...newValues],
          }
          _n['values']=getValuesFromMale1(_n['male1'],_n['male2'],_n.track,grpType);
          return ({..._n});
        });
      }
    }
  };
  const valid = () => { /* eslint-disable default-case */
    let out = true;
    let errors = {};
    const {
      male1,
      female1,
      male2,
      female2,
      type,
      type2,
      gender,
      tempo,
    } = state;
    const [primTrkField, secTrkField] = state.track;
    switch (type) {
      case "fixed": {
        if (!male1[0]) {
          _.set(errors, ["male1", 0], true);
          out = false;
        }
        if (!!gender && !female1[0]) {
          _.set(errors, ["female1", 0], true);
          out = false;
        }
        break;
      }
      case "range": {
        if (!male1[0]) {
          _.set(errors, ["male1", 0], true);
          out = false;
        }
        if (!male1[1]) {
          _.set(errors, ["male1", 1], true);
          out = false;
        }
        if (male1[0] && male1[1] && male1[0] > male1[1]) {
          _.set(errors, ["male1", 0], true);
          out = false;
        }
        if (!!gender) {
          if (!female1[0]) {
            _.set(errors, ["female1", 0], true);
            out = false;
          }
          if (!female1[1]) {
            _.set(errors, ["female1", 1], true);
            out = false;
          }
          if (female1[0] && female1[1] && female1[0] > female1[1]) {
            _.set(errors, ["female1", 0], true);
            out = false;
          }
        }
        break;
      }
      case "perset": {
        const NM = grpType === 'amrap' ? Math.max(3, male1.length) : numOfSets;
        const NF = gender ? (grpType === 'amrap' ? Math.max(3, female1.length) : numOfSets) : 0;
        for (let i = 0; i < NM; i++) {
          if (!male1[i]) {
            _.set(errors, ["male1", i], true);
            out = false;
          }
        }
        if(!!gender){
          for (let i = 0; i < NF; i++) {
            if (!female1[i]) {
              _.set(errors, ["female1", i], true);
              out = false;
            }
          }
        }
        break;
      }
    }
    if (secTrkField && type2) {
      switch (type2) {
        case "fixed": {
          if (!male2[0] || (secTrkField === 'rm' && (male2[0] < 0 || male2[0] > 100))) {
            _.set(errors, ["male2", 0], true);
            out = false;
          }
          if (!!gender && (!female2[0] || (secTrkField === 'rm' && (female2[0] < 0 || female2[0] > 100)))) {
            _.set(errors, ["female2", 0], true);
            out = false;
          }
          break;
        }
        case "range": {
          if (!male2[0] || (secTrkField === 'rm' && (male2[0] < 0 || male2[0] > 100))) {
            _.set(errors, ["male2", 0], true);
            out = false;
          }
          if (!male2[1] || (secTrkField === 'rm' && (male2[1] < 0 || male2[1] > 100))) {
            _.set(errors, ["male2", 1], true);
            out = false;
          }
          if (male2[0] && male2[1] && male2[0] > male2[1]) {
            _.set(errors, ["male2", 0], true);
            out = false;
          }
          if (gender) {
            if (!female2[0] || (secTrkField === 'rm' && (female2[0] < 0 || female2[0] > 100))) {
              _.set(errors, ["female2", 0], true);
              out = false;
            }
            if (!female2[1] || (secTrkField === 'rm' && (female2[1] < 0 || female2[1] > 100))) {
              _.set(errors, ["female1", 1], true);
              out = false;
            }
            if (female2[0] && female2[1] && female2[0] > female2[1]) {
              _.set(errors, ["female2", 0], true);
              out = false;
            }
          }
          break;
        }
        case "perset": {
          const NM = grpType === 'amrap' ? Math.max(3, male2.length) : numOfSets;
          const NF = gender ? (grpType === 'amrap' ? Math.max(3, female2.length) : numOfSets) : 0;
          for (let i = 0; i < NM; i++) {
            if (!male2[i] || (secTrkField === 'rm' && (male2[i] < 0 || male2[i] > 100))) {
              _.set(errors, ["male2", i], true);
              out = false;
            }
          }
          if(!!gender){
            for (let i = 0; i < NF; i++) {
              if (!female2[i] || (secTrkField === 'rm' && (female2[i] < 0 || female2[i] > 100))) {
                _.set(errors, ["female2", i], true);
                out = false;
              }
            }
          }
          break;
        }
      }
    }
    //---tempo
    if (!isTempoValid(tempo)) {
      errors.tempo = true;
      out = false;
    }
    updateError(errors);
    if (!out) {
      const errorKey = Object.keys(errors)[0];
      if (errorKey === "tempo") {
        enqueueSnackbar(TEMPO_ERROR, {
          variant: "error",
        });
      } else {
        if (errorKey.includes("2")) {
          enqueueSnackbar(`Please enter valid ${SECONDARY_TRACKING_TARGET[secTrkField]} for exercise`, {
            variant: "error",
          });
        } else {
          enqueueSnackbar(`Please enter valid ${primTrkField} for exercise`, {
            variant: "error",
          });
        }
      }
    }
    return out;
  };
  const onSubmit = (e) => {
    if (!valid()) return;
    const updatedExercise = {
      ...state,
    };
    //tempo
    const _isTempoValid = isTempoValid(updatedExercise.tempo);
    if (_isTempoValid) {
      if (_isTempoValid === "empty") delete updatedExercise.tempo;
      else updatedExercise["tempo"] = updatedExercise["tempo"].join("");
    }
    //backward compatible
    updatedExercise["values"] = getValuesFromMale1(
      updatedExercise["male1"],
      updatedExercise["male2"],
      updatedExercise.track,
      grpType
    );
    updatedExercise["ref_type"] = getRefTypeFromTrack(updatedExercise.track,grpType);
    if (
      updatedExercise.ref_type === "reps_w_weight" &&
      !!_.get(updatedExercise, ["male2"], []).length
    ) {
      updatedExercise["weights"] = [...updatedExercise.male2];
      updatedExercise["weight_type"] = state.type2;
    }
    onSave(e, updatedExercise);
  };
  return (
    <Popover
      id="value-popover"
      open
      anchorEl={anchor}
      onClose={handleClose}
      {...PopoverAttr(classes)}
    >
      <ValueContext.Provider
        value={{
          numOfSets,
          grpType,
          state,
          updateFn,
          errors,
        }}
      >
        <PrimaryTracking {...state} />
        <SecondaryTracking {...state} />
        <AdditionalTracking {...state} />
        <ClrdButton
          variant="contained"
          color="primary"
          fullWidth
          className=" f-xlarge mt-25"
          onClick={onSubmit}
          disabled={disabled}
          data-group={props["data-group"]}
          data-exercise={props.index}
        >
          Save
        </ClrdButton>
      </ValueContext.Provider>
    </Popover>
  );
};

const PrimaryTracking = ({ track, type, male1 = [], female1 = [], gender }) => {
  const { updateFn, errors, grpType } = useContext(ValueContext);
  const primTrackingField = track[0];
  const secTrackingField = track[1];
  const primaryTrackingOptions = useMemo(() => {
    if (grpType === "tabata") {
      return PRIMARY_TRACKING_OPTIONS.filter((i) => i.value === "duration");
    }
    return [...PRIMARY_TRACKING_OPTIONS];
  }, [grpType]);
  const primaryTrackingVariations=useMemo(()=>getTrackingVariations(grpType),[grpType]);
  return (
    <>
      <Typography className="font_16_700 mb-25 d-flex align-item-center">
        Manage Tracking
        {primTrackingField === "reps" && (
          <Tooltip title="Type 'X' to assign Max Reps">
            <span className="ml-5 font_14_500">ⓘ</span>
          </Tooltip>
        )}
      </Typography>
      <div className="d-flex" style={{ gap: 15 }}>
        <FormTextField fullWidth label="Tracking Field">
          <Select
            displayEmpty
            fullWidth
            IconComponent={({className}) =>{
              return  <DropDownIcon className={clsx("text-grey",className)}/>
            }}
            input={
              <OutlinedInput
                classes={{
                  root: "height-40",
                  input: "font_15_600 select-height-40",
                }}
              />
            }
            name="track-1"
            value={primTrackingField}
            onChange={updateFn}
            required
          >
            {primaryTrackingOptions.map((i) => (
              <Item key={i.value} value={i.value}>
                {i.label}
              </Item>
            ))}
          </Select>
        </FormTextField>
        <FormTextField fullWidth label="Setwise Variation">
          <Select
            displayEmpty
            fullWidth
            IconComponent={({className}) =>{
              return  <DropDownIcon className={clsx("text-grey",className)}/>
            }}
            input={
              <OutlinedInput
                classes={{
                  root: "height-40",
                  input: "font_15_600 select-height-40",
                }}
              />
            }
            name="type"
            value={type}
            onChange={updateFn}
            required
          >
            {primaryTrackingVariations.map((i) => (
              <Item key={i.value} value={i.value}>
                {i.label}
              </Item>
            ))}
          </Select>
        </FormTextField>
      </div>
      <TypeForm
        name="male1"
        type={type}
        values={male1}
        trackingField={primTrackingField}
        onChange={updateFn}
        className={clsx(!!gender && "mb-20")}
        errors={errors["male1"]}
        grpType={grpType}
      />
      {!!gender && (
        <TypeForm
          name="female1"
          type={type}
          values={female1}
          trackingField={primTrackingField}
          onChange={updateFn}
          errors={errors["female1"]}
          grpType={grpType}
        />
      )}
      <FormControlLabel
        className="mb-0 mt-16 mb-11"
        control={
          <Checkbox
            color="primary"
            checked={gender}
            onChange={updateFn}
            name={"gender"}
          />
        }
        label="Specify different for male & female"
      />
      <Divider />
    </>
  );
};

const SecondaryTracking = ({
  track,
  type2,
  male2 = [],
  female2 = [],
  gender,
}) => {
  const classes = styles();
  const { cid } = useContext(FirebaseAuthContext);
  const { updateFn, errors,grpType } = useContext(ValueContext);
  const primTrackingField = track[0];
  const secTrackingField = track[1];
  const secondaryTrackingOptions = useMemo(() => {
    if (primTrackingField === 'reps' && !['tasdeekraza321', 'ohearnenterprisesinc'].includes(cid))
      return SECONDARY_TRACKING_OPTIONS.repsOld;
    return SECONDARY_TRACKING_OPTIONS[primTrackingField];
  }, [cid, primTrackingField]);
  const isTargetSpecified = !!male2.length;
  const secondaryTrackingVariations=useMemo(()=>getTrackingVariations(grpType),[grpType]);
  return (
    <>
      <div className="d-flex align-items-center justify-content-between mt-12 mb-8">
        <Typography className="font_15_700">Secondary Tracking</Typography>
        <Switch
          name="secondary-tracking"
          color="primary"
          className="mr-n12"
          checked={!!secTrackingField}
          onChange={updateFn}
          disabled={!secondaryTrackingOptions.length}
        />
      </div>
      <Collapse in={!!secTrackingField} unmountOnExit={true}>
        <div className="d-flex mt-8 mb-16" style={{ gap: 15 }}>
          <FormTextField
            fullWidth
            label="Tracking Field"
            classes={{ control: clsx("mb-0", classes.valueInput) }}
          >
            <Select
              fullWidth
              IconComponent={({className}) =>{
                return  <DropDownIcon className={clsx("text-grey",className)}/>
              }}
              input={
                <OutlinedInput
                  classes={{
                    root: "height-40",
                    input: "font_15_600 select-height-40",
                  }}
                />
              }
              name="track-2"
              value={secTrackingField}
              onChange={updateFn}
              required
            >
              {secondaryTrackingOptions.map((i) => (
                <Item key={i.value} value={i.value}>
                  {i.label}
                </Item>
              ))}
            </Select>
          </FormTextField>
          {isTargetSpecified && (
            <FormTextField
              fullWidth
              label="Setwise Variation"
              classes={{ control: clsx("mb-0", classes.valueInput) }}
            >
              <Select
                displayEmpty
                fullWidth
                IconComponent={({className}) =>{
                  return  <DropDownIcon className={clsx("text-grey",className)}/>
                }}
                input={
                  <OutlinedInput
                    classes={{
                      root: "height-40",
                      input: "font_15_600 select-height-40",
                    }}
                  />
                }
                value={type2}
                name="type2"
                onChange={updateFn}
                required
              >
                {secondaryTrackingVariations.map((i) => (
                  <Item key={i.value} value={i.value}>
                    {i.label}
                  </Item>
                ))}
              </Select>
            </FormTextField>
          )}
        </div>
        {secTrackingField !== 'rm' && <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={isTargetSpecified}
              onChange={updateFn}
              name={"specify-target"}
            />
          }
          label={`Specify Target ${SECONDARY_TRACKING_TARGET[secTrackingField]}`}
        />}
        {isTargetSpecified && (
          <TypeForm
            name="male2"
            type={type2}
            values={male2}
            trackingField={secTrackingField}
            prim={primTrackingField}
            onChange={updateFn}
            className={"mb-20"}
            errors={errors["male2"]}
            grpType={grpType}
          />
        )}
        {isTargetSpecified && !!gender && (
          <TypeForm
            name="female2"
            type={type2}
            values={female2}
            trackingField={secTrackingField}
            primField={primTrackingField}
            onChange={updateFn}
            className={"mb-20"}
            errors={errors["female2"]}
            grpType={grpType}
          />
        )}
      </Collapse>
      <Divider />
    </>
  );
};

const AdditionalTracking = ({ tempo, side }) => {
  const { updateFn, errors } = useContext(ValueContext);
  const { sidesData } = useContext(EditorContext);
  return (
    <div className="mt-20">
      <Typography className="font_15_700 mb-20">Additional Info</Typography>
      <div className="d-flex" style={{ gap: 15 }}>
        <Tempo value={tempo} onChange={updateFn} error={errors.tempo} label="Tempo"/>
        <FormTextField
          fullWidth
          label="Side Info"
          classes={{ control: "mb-0" }}
        >
          <Select
            displayEmpty
            fullWidth
            IconComponent={({className}) =>{
              return  <DropDownIcon className={clsx("text-grey",className)}/>
            }}
            input={
              <OutlinedInput
                classes={{
                  root: "height-40",
                  input: "font_15_600 select-height-40",
                }}
              />
            }
            name="side"
            value={side}
            onChange={updateFn}
            required
          >
            <Item key={"none"} value={"none"}>
              None
            </Item>
            {sidesData.map((i) => (
              <Item key={i[0]} value={i[0]}>
                {i[1].value}
              </Item>
            ))}
          </Select>
        </FormTextField>
      </div>
    </div>
  );
};

const TypeForm = ({
  name,
  type,
  values = [],
  trackingField,
  primaryTracking,
  onChange,
  className,
  errors = [],
  grpType
}) => {
  const {
    numOfSets,
    state: { gender },
  } = useContext(ValueContext);
  const [numFields, heading] = useMemo(() => {
    let _numFields = 0,
      _heading = [];
    switch (type) {
      case "fixed": {
        _numFields = 1;
        _heading = [SECONDARY_TRACKING_TARGET[trackingField]];
        break;
      }
      case "range": {
        _numFields = 2;
        _heading = ["Min", "Max"];
        break;
      }
      default: {
        _numFields = grpType==="amrap"?AMRAP_NO_PER_SET:numOfSets;
        _heading = [...Array(_numFields)].map((i, index) => `Set ${index + 1}`);
        break;
      }
    }
    return [_numFields, _heading, values];
  }, [type, trackingField]);
  const classes = styles({ numFields });
  return (
    <div className={className}>
      {!!gender && (
        <Typography className="font_11_600 text-primary mb-10">
          FOR {_.upperCase(name.slice(0, -1))}
        </Typography>
      )}
      <div className={clsx("d-flex flex-wrap")} style={{ gap: 15 }}>
        {[...Array(numFields)].map((i, index) => {
          const maxCase=values[index] && values[index]===MAX_REP_VALUE;
          const supportsMaxInput=trackingField==="reps" && (type==="range"?index===1:true);
          const numFieldType=(supportsMaxInput && (!values[index] || maxCase))?"text":"number";
          return (
            <>
              {["duration","pace"].includes(trackingField) ? (
                <DurationWrapper
                  name={`${name}_values_${index}`}
                  value={values[index]}
                  handleChange={onChange}
                  label={heading[index]}
                  hideHr={true}
                  minuteLimit={120}
                  amrap={false}
                  classes={{
                    root: "height-40 d-flex justify-content-center",
                    input: "height-40 font_15_600",
                  }}
                  type={trackingField==="duration"?"displayValue":"pace"}
                  error={_.get(errors, [index])}
                  formTextFieldProps={{
                    classes: {
                      control: classes.valueInput,
                    },
                  }}
                  unit={trackingField}
                />
              ) : (
                <NumberInput
                  ignoreInitial={values[index]===MAX_REP_VALUE}
                  key={`${trackingField}_${type}_set_${index}`}
                  label={heading[index]}
                  variant="outlined"
                  required
                  value={values[index]}
                  InputProps={{
                    classes: {
                      root: clsx("height-40"),
                      input: clsx("height-40 font_15_600"),
                    },
                  }}
                  LabelProps={{
                    classes: {
                      control: clsx("fp-0", classes.valueInput),
                      label: "fmb-5",
                    },
                  }}
                  onChange={onChange}
                  name={`${name}_values_${index}`}
                  error={_.get(errors, [index])}
                  unit={trackingField}
                  type={numFieldType}
                />
              )}
            </>
          );
        })}
      </div>
    </div>
  );
};
const getTrackingVariations=(grpType)=>{
    if(grpType==="amrap"){
      return [...TRACKING_VARIATIONS_AMRAP];
    }
    return [...TRACKING_VARIATIONS];
  }
const Item = ({ children, ...rest }) => (
  <MenuItem dense={true} {...rest}>
    {children}
  </MenuItem>
);
