import * as amplitude from '@amplitude/analytics-browser';


function initAmplitude(userId, email){
  amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY, userId)
  if (email) setProperty('email', email);
} 


/**
 * Tracks an event with the given name and properties using Amplitude analytics.
 *
 * @param {string} eventName - The name of the event to track.
 * @param {Object} eventProperties - The properties of the event to track.
 */
function trackEvent(eventName, eventProperties = {}){
  amplitude.track(eventName, eventProperties);
}

function setProperty(key, value){
  const identifyEvent = new amplitude.Identify();

  identifyEvent.set(key, value);
  amplitude.identify(identifyEvent);
}

function setOnceProperty(key, value){
  const identifyUser = new amplitude.Identify();

  identifyUser.setOnce(key, value);

  amplitude.identify(identifyUser);
}

export { initAmplitude, trackEvent, setProperty, setOnceProperty };